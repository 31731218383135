import React, { useState, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import SubmitButton from "../../forms/SubmitButton";
import { AuthContext } from "../../../services/Authentication";
import Input from "../../forms/Input";
import { ClickAwayListener } from "@mui/material";
import ComboBox from "../../forms/ComboBox";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import {
  unitedStates,
  canadianProvinces,
  countries,
  customerStatuses,
  customerTypes,
  terms,
} from "../../constants";
import usePermissions from "../../../hooks/usePermissions";

const CustomerAdvancedSearch = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { dispatch, state: authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const permissions = usePermissions();

  // Set the search to the existing parameters, so they can adjust their previous search without putting everything in again
  const previousSearch = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const country =
    countries.find(
      ({ abbreviation }) => abbreviation === previousSearch?.country
    ) || null;
  const state =
    country?.id === 1 || !country
      ? unitedStates.find(
          ({ abbreviation }) => abbreviation === previousSearch?.state
        )
      : canadianProvinces.find(
          ({ abbreviation }) => abbreviation === previousSearch?.state
        ) || null;
  const isperson =
    customerTypes.find(
      ({ isperson }) => isperson === previousSearch?.isperson
    ) || null;

  const [search, setSearch] = useState({
    name: previousSearch?.name || "",
    phone: previousSearch?.phone || "",
    email: previousSearch?.email || "",
    country: previousSearch?.country || "",
    country_id: country?.id || "",
    state: previousSearch?.state || "",
    state_id: state?.id || "",
    status: previousSearch?.status || "",
    isperson: previousSearch?.isperson || "",
    isperson_id: isperson?.id || "",
    overduebalance: previousSearch?.overduebalance === "true" || false,
    taxable: previousSearch?.taxable === "true" || false,
    exemptax: previousSearch?.exemptax === "true" || false,
    salesrep: previousSearch?.salesrep || "",
    supervisor: previousSearch?.supervisor || "",
    costcorep: previousSearch?.costcorep || "",
    partner: previousSearch?.partner || "",
    csr: previousSearch?.csr || "",
    terms: previousSearch?.terms || "",
  });

  const numFilters = Object.keys(previousSearch).length || 0;

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.value });

  const handleCheckedChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.checked });

  const handleSelectChange = (name) => (event, value) => {
    if (name === "state") {
      setSearch({
        ...search,
        state: value?.abbreviation || "",
        state_id: value?.id || "",
      });
    } else if (name === "country") {
      setSearch({
        ...search,
        country: value?.abbreviation || "",
        country_id: value?.id || "",
        state: "",
        state_id: "",
      });
    } else if (name === "isperson") {
      setSearch({
        ...search,
        isperson: value?.isperson || "",
        isperson_id: value?.id || "",
      });
    } else {
      setSearch({ ...search, [name]: value?.abbreviation || value?.id || "" });
    }
  };

  // Clear an individual input
  const handleClear = (name) => (e) => setSearch({ ...search, [name]: "" });

  // Clear entire search
  const handleClearAll = () => {
    setIsSearching(true);
    navigate("/customers");
    setIsSearching(false);
    setOpen(false);
  };

  const handleSearch = () => {
    setIsSearching(true);
    const searchCopy = JSON.parse(JSON.stringify(search));

    // Remove any blank parameters
    Object.keys(searchCopy).forEach((key, index) => {
      if (!searchCopy[key]) delete searchCopy[key];
    });

    // Remove things not needed for the api call
    delete searchCopy.country_id;
    delete searchCopy.state_id;
    delete searchCopy.isperson_id;

    // Update the url with the new search
    const urlParams = new URLSearchParams(searchCopy).toString();
    navigate({ pathname: "/customers/", search: `?${urlParams}` });
    setIsSearching(false);
    setOpen(false);
  };

  // Get metadata
  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };
  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/login" replace />;
  }

  let osrManager = search.salesrep || authState.user.netsuite_id;

  const filteredPartners =
    (!permissions.isAdmin && !permissions.canViewAllCustomers) ||
    search.salesrep
      ? metadata.partners.data.filter(
          (partner) => partner.manager == osrManager
        )
      : metadata.partners.data;

  const filteredSalesReps =
    !permissions.isAdmin && !permissions.canViewAllCustomers
      ? metadata.salesreps.data.filter(
          (salesrep) =>
            salesrep.manager == authState.user.netsuite_id ||
            salesrep.id == authState.user.netsuite_id
        )
      : metadata.salesreps?.data;

  return (
    <Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="p-2 border-2">
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Button
                    endIcon={<ClearIcon />}
                    onClick={handleClearAll}
                    className="float-right"
                  >
                    Clear All
                  </Button>
                  {filteredSalesReps.length > 0 && (
                    <ComboBox
                      options={filteredSalesReps}
                      value={search.salesrep}
                      name="salesrep"
                      onChange={handleSelectChange("salesrep")}
                      disableClearable={false}
                    >
                      Inside Sales Rep
                    </ComboBox>
                  )}
                  {permissions.canViewAllCustomers && (
                    <ComboBox
                      options={metadata.salesreps?.data}
                      value={search.supervisor}
                      name="supervisor"
                      onChange={handleSelectChange("supervisor")}
                      disableClearable={false}
                    >
                      ISR Supervisor
                    </ComboBox>
                  )}
                  {permissions.canViewAllCustomers && (
                    <ComboBox
                      options={metadata.costcoreps?.data}
                      value={search.costcorep}
                      name="costcorep"
                      onChange={handleSelectChange("costcorep")}
                      disableClearable={false}
                    >
                      Costco Sales Rep
                    </ComboBox>
                  )}
                  {filteredPartners > 0 && (
                    <ComboBox
                      options={filteredPartners}
                      value={search.partner}
                      name="partner"
                      onChange={handleSelectChange("partner")}
                      disableClearable={false}
                    >
                      Outside Sales Rep
                    </ComboBox>
                  )}
                  {permissions.canViewAllCustomers && (
                    <ComboBox
                      options={metadata.customerServiceReps?.data}
                      value={search.csr}
                      name="csr"
                      onChange={handleSelectChange("csr")}
                      disableClearable={false}
                    >
                      Customer Service Rep
                    </ComboBox>
                  )}
                  <Input
                    name={"name"}
                    placeholder={"Name"}
                    value={search?.name || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={handleClear("name")}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"phone"}
                    placeholder={"Phone "}
                    value={search.phone || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={handleClear("phone")}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"email"}
                    placeholder={"Email"}
                    value={search.email || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={handleClear("email")}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <ComboBox
                    options={countries}
                    value={search.country_id}
                    name="country"
                    onChange={handleSelectChange("country")}
                    disableClearable={false}
                  >
                    Country
                  </ComboBox>
                  <ComboBox
                    options={
                      search.country_id === 2 ? canadianProvinces : unitedStates
                    }
                    value={search.state_id}
                    name="state"
                    onChange={handleSelectChange("state")}
                    disableClearable={false}
                  >
                    State
                  </ComboBox>
                  <ComboBox
                    options={customerStatuses}
                    value={search.status}
                    name="status"
                    onChange={handleSelectChange("status")}
                    disableClearable={false}
                  >
                    Status
                  </ComboBox>
                  <ComboBox
                    options={customerTypes}
                    value={search.isperson_id}
                    name="isperson"
                    onChange={handleSelectChange("isperson")}
                    disableClearable={false}
                  >
                    Type
                  </ComboBox>
                  <ComboBox
                    options={terms}
                    value={search.terms}
                    name="terms"
                    onChange={handleSelectChange("terms")}
                    disableClearable={false}
                  >
                    Terms
                  </ComboBox>
                  <FormGroup className="px-1">
                    <FormControlLabel
                      label="Has an overdue balance"
                      control={
                        <Checkbox
                          checked={search.overduebalance}
                          name="overduebalance"
                          onChange={handleCheckedChange}
                        />
                      }
                    />
                  </FormGroup>
                  <FormGroup className="px-1">
                    <FormControlLabel
                      label="Taxable"
                      control={
                        <Checkbox
                          checked={search.taxable}
                          name="taxable"
                          onChange={handleCheckedChange}
                        />
                      }
                    />
                  </FormGroup>
                  <FormGroup className="px-1">
                    <FormControlLabel
                      label="Active in Exemptax"
                      control={
                        <Checkbox
                          checked={search.exemptax}
                          name="exemptax"
                          onChange={handleCheckedChange}
                        />
                      }
                    />
                  </FormGroup>
                  <SubmitButton onClick={handleSearch} loading={isSearching}>
                    Apply
                  </SubmitButton>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        startIcon={<FilterListIcon />}
        onClick={handleClick("bottom-end")}
      >
        Filters ({numFilters})
      </Button>
    </Box>
  );
};
export default CustomerAdvancedSearch;
