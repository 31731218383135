import React, { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

import { Box, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailIcon from "@material-ui/icons/Email";
import AddIcon from "@mui/icons-material/Add";

import { AuthContext } from "../../../services/Authentication";

import Header from "../../layout/Header";
import Card from "../../layout/Card";
import DataCard from "../../layout/DataCard";
import SubmitButton from "../../forms/SubmitButton";
import EclipseSpinner from "../../EclipseSpinner";
import MainContainer from "../../layout/MainContainer";
import NotFound404 from "../NotFound404";

import useCustomer, { getText, getValue } from "../../../hooks/useCustomer";
import Notes from "./Notes";
import ErrorMessage from "../ErrorMessage";
import PaySuiteSettings from "../../forms/PaySuiteSettings";
import AlertDialog from "../../forms/AlertDialog";
import LinkCard from "../../layout/LinkCard";
import usePermissions from "../../../hooks/usePermissions";

const CustomerDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
  const [showSendEmailAlertDialog, setShowSendEmailAlertDialog] =
    useState(false);
  const { state: authState } = useContext(AuthContext);
  const userName = `${authState.user.firstname} ${authState.user.lastname}`;
  const { isLoading, error, customer, contacts, notes } = useCustomer(id);
  const permissions = usePermissions();
  const [emailData, setEmailData] = useState({
    email: getText(customer, "email") || "",
    isSendingEmail: false,
    error: null,
    message: "",
  });

  const confirmDelete = () => {
    setShowDeleteAlertDialog(true);
  };

  const handleDelete = async (e) => {
    setIsDeleting(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customers/${id}`, { 
        method: "DELETE",
        credentials: 'include',
      });
      if (res.ok) {
        toast.success("Customer deleted");
        navigate("/customers");
      } else throw res;
    } catch (e) {
      setIsDeleting(false);
      console.error(e);
    }
  };

  const confirmSendPaySuiteInvite = () => {
    setShowSendEmailAlertDialog(true);
  };

  // Send PaySuite invite email
  const handleSendEmail = async (e) => {
    try {
      setEmailData({
        email: getText(customer, "email"),
        error: null,
        isSendingEmail: true,
        message: "",
      });
      const emailResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customers/paysuiteemail`, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: getText(customer, "email"),
          customerName: getText(customer, "altname"),
          internalId: getValue(customer, "internalid"),
          profileId: customer.custentity_ps_cust_prof_id,
        }),
      });
      if (emailResponse.ok) {
        const json = await emailResponse.json();
        setEmailData({ ...emailData, message: json, error: null });
        toast.success("Payment invite email sent!");
      } else if (emailResponse.status === 401) {
        let json;
        try {
          json = await emailResponse.json();
        } catch (e) {
          throw emailResponse;
        }
        if (json.error) throw json.error;
      } else {
        throw emailResponse;
      }
    } catch (e) {
      console.error(e);
      setEmailData({ ...emailData, error: e, message: "" });
    }
  };

  // Netsuite dates are America/Los_Angeles timezone
  // Netsuite also never sets the seconds, so remove them from the displayed time
  const formatDate = (date) =>
    date
      ? new Date(`${date}-07:00`).toLocaleString().replace(/:00 (AM|PM)/, " $1")
      : "";
  const renameKey = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey];
  };

  // Clean data
  notes.forEach((note) => {
    Object.keys(note).forEach((key) => (note[key] = getValue(note, key)));
    note.notedate = formatDate(note.notedate);
    renameKey(note, "author.entityid", "authorname");
    note.editing = false;
  });

  // Permissions for displaying buttons
  let canEdit;
  let canDelete;
  let canViewSalesOrders;
  let canViewInvoices;
  let canCreateSalesOrders;

  if (customer) {
    const isCustomerSalesRep = customer.salesrep
      .map((a) => a.value)
      .includes(authState.user.netsuite_id);
    const isCustomerPartner = customer.partner
      .map((a) => a.value)
      .includes(authState.user.netsuite_id);

    canEdit =
      permissions.canEditAllCustomers ||
      ((isCustomerSalesRep || isCustomerPartner) &&
        permissions.canEditOwnCustomers);

    canDelete =
      permissions.canDeleteAllCustomers ||
      ((isCustomerSalesRep || isCustomerPartner) &&
        permissions.canDeleteOwnCustomers);

    canViewSalesOrders =
      permissions.canViewAllSalesOrders ||
      ((isCustomerSalesRep || isCustomerPartner) &&
        permissions.canViewOwnSalesOrders);

    canViewInvoices =
      permissions.canViewAllInvoices ||
      ((isCustomerSalesRep || isCustomerPartner) &&
        permissions.canViewOwnInvoices);

    // You can't create a sales order if you don't have access to any items
    canCreateSalesOrders =
      (permissions.canCreateAllSalesOrders ||
        ((isCustomerSalesRep || isCustomerPartner) &&
          permissions.canCreateOwnSalesOrders)) &&
      permissions.canViewAnyItems;
  }

  window.document.title = isLoading
    ? "Suite Sales"
    : `${getText(customer, "altname")} | Suite Sales`;

  if (isLoading) return <EclipseSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (!customer) return <NotFound404 />;

  return (
    <>
      <Header className="flex-col  justify-center lg:flex-row py-2">
        <Box className="flex-1 mx-1 min-w-max	">
          {canViewSalesOrders && (
            <SubmitButton
              onClick={() => {
                navigate({
                  pathname: `/customers/${getValue(
                    customer,
                    "internalid"
                  )}/salesorders`,
                });
              }}
              className="mr-2"
            >
              <span>Sales Orders</span>
            </SubmitButton>
          )}
          {canViewInvoices && (
            <SubmitButton
              onClick={() => {
                navigate({
                  pathname: `/customers/${getValue(
                    customer,
                    "internalid"
                  )}/invoices/`,
                });
              }}
              className="mr-2"
            >
              <span>Invoices</span>
            </SubmitButton>
          )}
          {canCreateSalesOrders && (
            <SubmitButton
              endIcon={<AddIcon />}
              onClick={() => {
                navigate({
                  pathname: `/salesorders/create/customer/${getValue(
                    customer,
                    "internalid"
                  )}`,
                });
              }}
            >
              <span>New Sales Order</span>
            </SubmitButton>
          )}
        </Box>
        <Typography variant="h6" className="font-bold text-center mx-1">
          {`${getText(customer, "entitystatus")}: ${getText(
            customer,
            "altname"
          )}`}
        </Typography>
        <Box className="flex-1 mx-1 min-w-max flex flex-row flex-nowrap justify-end">
          {canDelete && (
            <SubmitButton
              className="mr-2"
              loadingPosition="end"
              endIcon={<DeleteIcon />}
              color="error"
              loading={isDeleting}
              onClick={confirmDelete}
            >
              Delete
            </SubmitButton>
          )}
          {canEdit && (
            <SubmitButton
              className="mr-2"
              loadingPosition="end"
              endIcon={<EditIcon />}
              onClick={() => navigate(`/customers/${id}/edit`)}
            >
              Edit
            </SubmitButton>
          )}
          {canEdit && (
            <SubmitButton
              loadingPosition="end"
              endIcon={<EmailIcon />}
              onClick={confirmSendPaySuiteInvite}
            >
              Send Payment Method Invitation
            </SubmitButton>
          )}
        </Box>
      </Header>
      <MainContainer>
        <Box>
          <Card header={"Primary Info"} color="bg-blue-300">
            {/* Entity Id, category, sales rep, partner, customer service rep, isperson  */}
            <DataCard header="Customer ID">
              <span>{`${getText(customer, "entityid")} ${getText(
                customer,
                "altname"
              )}`}</span>
            </DataCard>
            <DataCard header="Status">
              <span>{`${getText(customer, "entitystatus")}`}</span>
            </DataCard>
            <DataCard header="Email">{getText(customer, "email")}</DataCard>
            <DataCard header="Phone">{getText(customer, "phone")}</DataCard>
            <DataCard header="Category">
              {getText(customer, "category")}
            </DataCard>
            <DataCard header="Type">
              {getValue(customer, "isperson") ? "Individual" : "Company"}
            </DataCard>
            <DataCard header="Inside Sales Rep">
              {getText(customer, "salesrep")}
            </DataCard>
            <DataCard header="Costco Sales Rep">
              {getText(customer, "custentity_costco_sales_rep")}
            </DataCard>
            <DataCard header="Outside Sales Rep">
              {getText(customer, "partner")}
            </DataCard>
            <DataCard header="Customer Service Rep">
              {getText(customer, "custentity_customer_service_rep")}
            </DataCard>
            {getText(customer, "overduebalance") !== ".00" &&
              !canViewInvoices && (
                <DataCard header="Overdue Balance" className="text-red-600">
                  {getText(customer, "overduebalance")}
                </DataCard>
              )}
            {getText(customer, "overduebalance") !== ".00" &&
              canViewInvoices && (
                <LinkCard
                  title={"Overdue Balance"}
                  titleProps={{
                    variant: "body2",
                    fontWeight: "bold",
                    className: "text-red-600",
                  }}
                  bodyProps={{ className: "text-red-600" }}
                  body={`$${getText(customer, "overduebalance")}`}
                  url={`/customers/${getValue(
                    customer,
                    "internalid"
                  )}/invoices/?overduebalance=true`}
                />
              )}
          </Card>
        </Box>

        <Box>
          <Card header={"Address"} color="bg-yellow-100">
            {/* shipping addr, billing addr */}
            <DataCard header="Shipping Address">
              <Typography className="whitespace-pre-wrap">
                {getText(customer, "shipaddress")}
              </Typography>
            </DataCard>
            <DataCard header="Billing Address">
              <Typography className="whitespace-pre-wrap">
                {getText(customer, "billaddress")}
              </Typography>
            </DataCard>
          </Card>
        </Box>

        <Box>
          <Card header={"Email Notifications"} color="bg-pink-200">
            {/* order, shipping, and invoice notifications  */}
            <DataCard header="Order Notifications">
              {getText(customer, "custentity_email_sales_orders_to")}
            </DataCard>
            <DataCard header="Shipping Notifications">
              {getText(customer, "custentity_email_fulfillments_to")}
            </DataCard>
            <DataCard header="Invoice Notifications">
              {getText(customer, "custentity_email_invoices_to")}
            </DataCard>
            <DataCard header="Statement Notifications">
              {getText(customer, "custentity_email_statements_to")}
            </DataCard>
          </Card>
        </Box>

        <Box>
          <Card header={"Financial"} color="bg-green-200">
            {/* terms, class, price level,  */}
            <DataCard header="Default Class">
              {getText(customer, "custentity_default_class")}
            </DataCard>
            <DataCard header="Terms">{getText(customer, "terms")}</DataCard>
            <DataCard header="Price Level">
              {getText(customer, "pricelevel")}
            </DataCard>
            <DataCard header="Taxable?">
              {customer.taxable ? "Yes" : "No"}
            </DataCard>
            <DataCard header="Active in Exemptax?">
              {customer.custentity_active_in_exemptax ? "Yes" : "No"}
            </DataCard>
            <DataCard header="Credit Limit">
              ${getText(customer, "creditlimit")}
            </DataCard>
            <DataCard header="Inxeption ID">
              {getText(customer, "custentity_inxeption_id")}
            </DataCard>
            <PaySuiteSettings
              profileId={customer.custentity_ps_cust_prof_id}
              entityId={getValue(customer, "internalid")}
            />
          </Card>
        </Box>
        <Box>
          <Card header={"Marketing"} color="bg-gray-300">
            {/* utm parameters  */}
            <DataCard header="UTM Source">
              {getText(customer, "custentity_utm_source")}
            </DataCard>
            <DataCard header="UTM Medium">
              {getText(customer, "custentity_utm_medium")}
            </DataCard>
            <DataCard header="UTM Campaign">
              {getText(customer, "custentity_utm_campaign")}
            </DataCard>
            <DataCard header="UTM Term">
              {getText(customer, "custentity_utm_term")}
            </DataCard>
            <DataCard header="UTM Content">
              {getText(customer, "custentity_utm_content")}
            </DataCard>
          </Card>
        </Box>
        {!getValue(customer, "isperson") && (
          <Box>
            <Card header={"Contacts"} color="bg-red-300">
              {contacts.map((contact, index) => (
                <DataCard key={index} header={getText(contact, "entityid")}>
                  {getText(contact, "title", false)}
                  {getText(contact, "title") && <br />}
                  {getText(contact, "email", false)}
                  {getText(contact, "email") && <br />}
                  {getText(contact, "phone", false)}
                </DataCard>
              ))}
            </Card>
          </Box>
        )}
        <Box>
          <Card header={"Notes"} color="bg-purple-200">
            <Box className="w-full">
              <Notes
                userName={userName}
                customerId={id}
                notes={notes}
                canEdit={canEdit}
                canDelete={canDelete}
              />
            </Box>
          </Card>
        </Box>
      </MainContainer>
      {showDeleteAlertDialog && (
        <AlertDialog
          header="Delete customer?"
          body="Are you sure you want to delete this customer?"
          open={showDeleteAlertDialog}
          setShowAlertDialog={setShowDeleteAlertDialog}
          agreeFunction={handleDelete}
          disagreeText="Cancel"
          agreeText="Delete"
        />
      )}
      {showSendEmailAlertDialog && (
        <AlertDialog
          header="Email customer?"
          body="Are you sure you want to send an email to this customer inviting them to set up a new payment method?"
          open={showSendEmailAlertDialog}
          setShowAlertDialog={setShowSendEmailAlertDialog}
          agreeFunction={handleSendEmail}
          disagreeText="Cancel"
          agreeText="Send Email"
        />
      )}
    </>
  );
};

export default CustomerDetails;
