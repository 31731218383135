import { useEffect, useState } from "react";
import useFetch from "./useFetch";

function useInvoice(invoiceId) {
  const [invoice, setInvoice] = useState(false);
  const [lineItems, setLineItems] = useState([]);
  const [installments, setInstallments] = useState([]);
  const [packages, setPackages] = useState([]);

  const { isLoading, error, data } = useFetch({
    url: `${process.env.REACT_APP_BASE_URL}/api/invoices/${invoiceId}`,
    options: { method: "GET" },
  });

  useEffect(() => {
    if (data) {
      const {
        invoice: { data: [invoiceDetails] = [] } = {},
        lineItems: { data: lineItemsDetails = [] } = {},
        installments: {data: installmentsDetails = []} = {},
        packages: { data: packagesDetails = [] } = {},
      } = data;


      const transformedInstallments = installmentsDetails.map(installment => {
        const transformed = {};
        for (const key in installment) {
            const newKey = key.replace('installment.', '');
            transformed[newKey] = installment[key];
        }
        return transformed;
    });

      setInvoice(invoiceDetails);
      setLineItems(lineItemsDetails);
      setInstallments(transformedInstallments);
      setPackages(packagesDetails);
    }
  }, [data]);

  return { isLoading, error, invoice, lineItems, installments, packages };
}

export default useInvoice;
