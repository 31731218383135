import React from "react";
import { Link } from "@mui/material";
import DataCard from "../layout/DataCard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PAYSUITE_URL =
  "https://5518720.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=573&deploy=1&compid=5518720&h=86e7b8b1f512d1728a25";

const PaySuiteSettings = ({ profileId = "", entityId }) => {
  // List payment methods if they have an existing account, otherwise go directly to the "add payment method" form
  const route = profileId ? "listPaymentMethods" : "addMethodForm";

  return (
    <DataCard header="Manage Payment Methods">
      {entityId ? (
        <Link
          target="_blank"
          underline="none"
          href={`${PAYSUITE_URL}&route=${route}&entityId=${entityId}${
            profileId ? "&profileId=" + profileId : ""
          }`}
        >
          Manage <OpenInNewIcon fontSize="small" />
        </Link>
      ) : (
        <div>Error - No customer id provided</div>
      )}
    </DataCard>
  );
};

export default PaySuiteSettings;
