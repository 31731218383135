import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import SubmitButton from "../forms/SubmitButton";
import { ClickAwayListener } from "@mui/material";
import ComboBox from "../forms/ComboBox";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton } from "@mui/material";
import Input from "../forms/Input";
import { Box, Button } from "@mui/material";
import { inventoryLocations, itemCollections } from "../constants";
import usePermissions from "../../hooks/usePermissions";

const InventoryAdvancedSearch = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const permissions = usePermissions()

  // Set the search to the existing parameters, so they can adjust their previous search without putting everything in again
  const previousSearch = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const [search, setSearch] = useState({
    itemcollection: previousSearch?.itemcollection || "",
    location: previousSearch?.location || "",
    sku: previousSearch?.sku || "",
    displayname: previousSearch?.displayname || "",
  });

  const numFilters = Object.keys(previousSearch).length || 0;

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.value });

  const handleSelectChange = (name) => (event, value) => {
    setSearch({ ...search, [name]: value?.abbreviation || value?.id || "" });
  };

  // Clear an individual input
  const handleClear = (name) => (e) => setSearch({ ...search, [name]: "" });

  // Clear entire search
  const handleClearAll = () => {
    setIsSearching(true);
    navigate("/inventory");
    setIsSearching(false);
    setOpen(false);
  };

  const handleSearch = () => {
    setIsSearching(true);
    const searchCopy = JSON.parse(JSON.stringify(search));

    // Remove any blank parameters
    Object.keys(searchCopy).forEach((key, index) => {
      if (!searchCopy[key]) delete searchCopy[key];
    });

    // Remove things not needed for the api call
    delete searchCopy.country_id;
    delete searchCopy.state_id;
    delete searchCopy.isperson_id;

    // Update the url with the new search
    const urlParams = new URLSearchParams(searchCopy).toString();
    navigate({ pathname: "/inventory/", search: `?${urlParams}` });
    setIsSearching(false);
    setOpen(false);
  };

  return (
    <Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="p-2 border-2">
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Button
                    endIcon={<ClearIcon />}
                    onClick={handleClearAll}
                    className="float-right"
                  >
                    Clear All
                  </Button>
                  {permissions.canViewAllItems && 
                  <ComboBox
                    options={itemCollections}
                    value={search.itemcollection}
                    name="itemcollection"
                    onChange={handleSelectChange("itemcollection")}
                    disableClearable={false}
                  >
                    Item Collection
                  </ComboBox>
                  }
                  <ComboBox
                    options={inventoryLocations}
                    value={search.location}
                    name="location"
                    onChange={handleSelectChange("location")}
                    disableClearable={false}
                  >
                    Location
                  </ComboBox>
                  <Input
                    name={"sku"}
                    placeholder={"SKU"}
                    value={search?.sku || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={handleClear("sku")}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"displayname"}
                    placeholder={"Description"}
                    value={search?.displayname || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton size="small" onClick={handleClear("displayname")}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <SubmitButton onClick={handleSearch} loading={isSearching}>
                    Apply
                  </SubmitButton>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        startIcon={<FilterListIcon />}
        onClick={handleClick("bottom-end")}
      >
        Filters ({numFilters})
      </Button>
    </Box>
  );
};
export default InventoryAdvancedSearch;
