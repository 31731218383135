import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import EclipseSpinner from "../../EclipseSpinner";

import SubmitButton from "../../forms/SubmitButton";
import InternalLink from "../../routing/InternalLink";
import PasswordInput from "../../forms/PasswordInput";
import useFetch from "../../../hooks/useFetch";
import usePasswordValidation from "../../../hooks/usePasswordValidation"
import PasswordValidationTooltip from "../../forms/PasswordValidationTooltip";

import LionEnergyLogo from "../../LionEnergyLogo";

import SaveIcon from "@mui/icons-material/Save";
import toast from "react-hot-toast";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Container,
} from "@mui/material";


const ResetPassword = () => {
  const { token } = useParams();
  const [loginData, setLoginData] = useState({ id: null, password: "" });
  const [passwordReset, setPasswordReset] = useState(false)
  const [data, setData] = useState({
    isSavingPassword: false,
    validToken: false,
    error: null,
    message: "",
  });
  const {
    isLoading,
    error,
    data: tokenData,
  } = useFetch({ url: `${process.env.REACT_APP_BASE_URL}/api/users/reset/${token}` });
  const [
    validLength,
    hasNumber,
    upperCase,
    lowerCase,
    specialChar,
    ] = usePasswordValidation({
    password: loginData.password,
    });
  const validPassword = validLength && hasNumber && upperCase && lowerCase && specialChar

  useEffect(() => {
    if (!error && tokenData) {
      setData((prevData) => ({
        ...prevData,
        validToken: tokenData.valid_token,
      }));
      setLoginData((prevData) => ({ ...prevData, id: tokenData.id }));
    }
    if (error) setData((prevData) => ({ ...prevData, error: error }));
  }, [tokenData, error]);

  const handleChange = (e) =>
  setLoginData({ ...loginData, [e.target.name]: e.target.value });


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginDataCopy = { ...loginData };
      setData({ ...data, error: null, isSavingPassword: true, message: "" });

      const passwordResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/password/${tokenData.id}/${token}`, {
        method: "PUT",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(loginDataCopy),
      });

      if (passwordResponse.ok) {
        setData({
          ...data,
          message:
            "Success! Please click sign in to log in with your new password.",
          error: null,
        });
        setLoginData({...loginData, password: ""})
        setPasswordReset(true)
        toast.success("New password set!");
      } else if (passwordResponse.status === 401) {
        let json;
        try {
          json = await passwordResponse.json();
        } catch (e) {
          throw passwordResponse;
        }
        if (json.error) throw json.error;
      } else {
        throw passwordResponse;
      }
    } catch (e) {
      console.error(e);
      setData({ ...data, error: e, message: "" });
    }
  };

  window.document.title = "New Password | Suite Sales"

  if (isLoading) return <EclipseSpinner />;
  return (
    <Box className="login flex items-center h-screen bg-gradient-to-b from-black to-gray-700">
      <Container maxWidth="xs">
        <form id="login_form" onSubmit={handleSubmit}>
          <Card style={{ minWidth: "350px" }}>
            <CardContent className="text-center">
              <LionEnergyLogo />
              {!data.validToken && (
                <Typography>
                  The set password link is invalid or has expired.
                </Typography>
              )}
              {data.validToken && (
                <>
                {!passwordReset && <> 
                  <Typography variant="h6" className="my-5">
                    Set your new password
                  </Typography>
                  <PasswordValidationTooltip password={loginData.password} placement="top">
                    <PasswordInput
                    value={loginData.password}
                    onChange={handleChange}
                    name="password"
                    />
                  </PasswordValidationTooltip></>}                  
                  {data.error ? (
                    <Typography variant="body2" color="error">
                      {data.error?.message || data.error?.statusText}
                    </Typography>
                  ) : (
                    ""
                  )}
                  {data.message && (
                    <Typography variant="body2" className="text-green-700">
                      {data.message}
                    </Typography>
                  )}
                </>
              )}
            </CardContent>
            <CardActions className="flex-row-reverse justify-between">
              {(data.validToken && !passwordReset) && (
                <SubmitButton
                  endIcon={<SaveIcon />}
                  loadingPosition="end"
                  loading={data.isSavingPassword}
                  disabled = {!validPassword}
                >
                  Save new password
                </SubmitButton>
              )}
              <InternalLink to="/login">
                <Typography variant="body2">Sign in</Typography>
              </InternalLink>
            </CardActions>
          </Card>
        </form>
      </Container>
    </Box>
  );
};
export default ResetPassword;
