import React, { useReducer } from "react";

import "./styles/output.css";
import { CssBaseline } from "@mui/material";

import Login from "./components/pages/Login/Login";
import Dashboard from "./components/pages/Dashboard";
import CustomerList from "./components/pages/Customers/CustomerList";
import ForgotPassword from "./components/pages/Login/ForgotPassword";
import ResetPassword from "./components/pages/Login/ResetPassword";
import NotFound404 from "./components/pages/NotFound404";
import SalesOrderList from "./components/pages/SalesOrders/SalesOrderList";
import UserList from "./components/pages/Users/UserList";
import SalesOrderForm from "./components/pages/SalesOrders/SalesOrderForm";
import UserForm from "./components/pages/Users/UserForm";

import AuthenticatedRoute from "./components/routing/AuthenticatedRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  AuthContext,
  initialState,
  authReducer,
} from "./services/Authentication";
import CustomerDetails from "./components/pages/Customers/CustomerDetails";
import AppBar from "./components/layout/AppBar";
import SalesOrderDetails from "./components/pages/SalesOrders/SalesOrderDetails";
import InventoryReport from "./components/Reports/InventoryReport";
import CommissionReport from "./components/Reports/CommissionReport";
import ReportsDashboard from "./components/Reports/ReportsDashboard";
import CustomerForm from "./components/pages/Customers/CustomerForm";
import InvoiceList from "./components/pages/Invoices/InvoiceList";
import InvoiceDetails from "./components/pages/Invoices/InvoiceDetails";
import RoleForm from "./components/pages/Roles/RoleForm";
import RoleList from "./components/pages/Roles/RoleList";

import { Toaster } from "react-hot-toast";
import HelpButton from "./components/forms/HelpButton"

function App() {
  const [state, dispatch] = useReducer(authReducer, initialState);

  return (
    <>
      <Toaster />
      <AuthContext.Provider value={{ state, dispatch }}>
        <div className="App" style={{ minWidth: 330 }}>
          <CssBaseline />

          <BrowserRouter>
            {state.isAuthenticated && <AppBar />}
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot" element={<ForgotPassword />} />

              <Route path="/newpassword/:token" element={<ResetPassword />} />
              <Route
                exact
                path="/"
                element={
                  <AuthenticatedRoute>
                    <Dashboard />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/reports"
                element={
                  <AuthenticatedRoute>
                    <ReportsDashboard />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/inventory"
                element={
                  <AuthenticatedRoute>
                    <InventoryReport />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/commission"
                element={
                  <AuthenticatedRoute>
                    <CommissionReport />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/customers"
                element={
                  <AuthenticatedRoute>
                    <CustomerList />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/customers/:id"
                element={
                  <AuthenticatedRoute>
                    <CustomerDetails />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="/customers/create"
                element={
                  <AuthenticatedRoute>
                    <CustomerForm createNewCustomer={true} />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/customers/:id/edit"
                element={
                  <AuthenticatedRoute>
                    <CustomerForm createNewCustomer={false} />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />
              <Route
                exact
                path="/customers/:customerId/salesorders"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/customers/:customerId/invoices"
                element={
                  <AuthenticatedRoute>
                    <InvoiceList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/users"
                element={
                  <AuthenticatedRoute>
                    <UserList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/users/:id"
                element={
                  <AuthenticatedRoute>
                    <UserForm createNewUser={false} />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/profile/:id"
                element={
                  <AuthenticatedRoute>
                    <UserForm createNewUser={false} />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/users/create"
                element={
                  <AuthenticatedRoute>
                    <UserForm createNewUser={true} />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/roles"
                element={
                  <AuthenticatedRoute>
                    <RoleList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/roles/:id"
                element={
                  <AuthenticatedRoute>
                    <RoleForm createNewRole={false} />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/roles/create"
                element={
                  <AuthenticatedRoute>
                    <RoleForm createNewRole={true} />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/salesorders"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/salesorders/:salesOrderId/invoices"
                element={
                  <AuthenticatedRoute>
                    <InvoiceList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/salesorders/:id"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderDetails />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/salesorders/create"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderForm createNewSalesOrder={true} />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/salesorders/create/customer/:customerID"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderForm createNewSalesOrder={true} />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/salesorders/:id/edit"
                element={
                  <AuthenticatedRoute>
                    <SalesOrderForm createNewSalesOrder={false} />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                exact
                path="/invoices"
                element={
                  <AuthenticatedRoute>
                    <InvoiceList />
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="/invoices/:id"
                element={
                  <AuthenticatedRoute>
                    <InvoiceDetails />
                    <HelpButton />
                  </AuthenticatedRoute>
                }
              />

              <Route
                path="*"
                element={
                  <AuthenticatedRoute>
                    <NotFound404 />
                  </AuthenticatedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </div>
      </AuthContext.Provider>
    </>
  );
}

export default App;
