import {Fab, Tooltip } from "@mui/material";
import React from "react";
import HelpIcon from '@mui/icons-material/HelpOutline';

const HelpButton = () => {

  const handleOpenPdf = () => {
    window.open('https://5518720.app.netsuite.com/core/media/media.nl?id=12010486&c=5518720&h=erbf6SU0Vb8VfcRIdIB7piscBRGabmsO0AgPABB5ygEeOjxr&_xt=.pdf', '_blank');
  };

  return (
    <>
       <Tooltip title="Need help?">
            <Fab
              color="primary"
              aria-label="help"
              style={{
                position: 'fixed',
                bottom: 20,
                right: 20,
              }}
              onClick={handleOpenPdf}
            >
              <HelpIcon />
            </Fab>
        </Tooltip>
    </>
  );
};

export default HelpButton;