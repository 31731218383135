import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useInvoice from "../../../hooks/useInvoice";
import { getText, getValue } from "../../../hooks/useCustomer";
import EclipseSpinner from "../../EclipseSpinner";
import ErrorMessage from "../ErrorMessage";
import DataCard from "../../layout/DataCard";
import Header from "../../layout/Header";
import Card from "../../layout/Card";
import MainContainer from "../../layout/MainContainer";
import NotFound404 from "../NotFound404";
import { Box, Tooltip, Typography } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import SubmitButton from "../../forms/SubmitButton";
import { LicenseInfo } from '@mui/x-license-pro';
import PayInvoice from "../../forms/PayInvoice";
import InvoicePDF from "../../forms/PDF";
import EmailIcon from "@material-ui/icons/Email";
import AlertDialog from "../../forms/AlertDialog";
import toast from "react-hot-toast";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdPrice = {
  type: "number",
  valueFormatter: ({ value }) => value ? currencyFormatter.format(Number(value)) : '',
};

const lineItemCols = [
  { field: "id", headerName: "ID", hide: true },
  { renderCell, field: "item", headerName: "SKU", flex: 1.25 },
  { renderCell, field: "description", headerName: "Description", flex: 3 },
  {
    field: "quantity",
    headerName: "Ordered",
    description: "Quantity ordered",
    flex: 1,
    type: "number"
  },
  {
    field: "quantityshiprecv",
    headerName: "Shipped",
    description: "Quantity shipped",
    flex: 1,
    type: "number"
  },
  {
    field: "rate",
    headerName: "Rate",
    description: "Rate",
    flex: 1,
    ...usdPrice,
  },
  {
    field: "amount",
    headerName: "Amount",
    description: "Amount",
    flex: 1,
    ...usdPrice
  },
];

// New installmentsCols for the installments section
const installmentsCols = [
  {
    field: "duedate",
    headerName: "Due Date",
    flex: 1,
    type: "date",
    valueFormatter: ({ value }) => new Date(value).toLocaleDateString("en-US"),
  },
  {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    ...usdPrice,
  },
  {
    field: "amountremaining",
    headerName: "Amount Due",
    flex: 1,
    ...usdPrice,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    align: 'right', // Align the text to the right
    headerAlign: 'right',
  },
];

const packagesCols = [
  { field: "id", headerName: "ID", hide: true },
  { renderCell, field: "service", headerName: "Service", flex: 1 },
  { renderCell, field: "tracking", headerName: "Tracking", flex: 1.5 },
  {
    field: "contents",
    headerName: "Contents",
    flex: 3,
    renderCell: (params) => {
      // contents is formatted as {quantity}<qtybr>{sku}<descbr>{description}<linebr>...repeat
      const lines = params?.row?.contents
        .split("<linebr>")
        .map((line) =>
          line.split("<qtybr>").map((item) => item.split("<descbr>"))
        );
      // Check that the first sku in the list isn't null
      const [[[firstSku] = []] = []] = lines;
      const contents = lines
        .map(
          ([qty, [sku, desc] = []]) =>
            `${qty}x ${sku}${desc ? " - " + desc : ""}`
        )
        .join(", ");
      return firstSku ? (
        <Tooltip title={contents}>
          <span>{contents}</span>
        </Tooltip>
      ) : (
        ""
      );
    },
  },
];

const InvoiceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSendEmailAlertDialog, setShowSendEmailAlertDialog] =
    useState(false);
  const [emailData, setEmailData] = useState({
    email: "",
    isSendingEmail: false,
    error: null,
    message: "",
  });

  const { isLoading, error, invoice, lineItems, installments, packages } = useInvoice(id);


  const confirmSendPaySuiteInvite = () => {
    setShowSendEmailAlertDialog(true);
  };

  // Send PaySuite pay email
  const handleSendEmail = async (e) => {
    try {
      // Fetch customer data
      const method = "GET";
      const url = `${process.env.REACT_APP_BASE_URL}/api/customers/${getValue(invoice, "entity")}`;
      const res = await fetch(url, {
        method: method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
      });

      console.log(res);

      const json = await res.json();
      const customer = json.customer.data[0];

      setEmailData({
        email: getText(customer, "email"),
        error: null,
        isSendingEmail: true,
        message: "",
      });
      const emailResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/invoices/paysuitepayemail`, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: getText(customer, "email"),
          customerName: getText(customer, "altname"),
          internalId: getValue(customer, "internalid"),
          recordId: id,
        }),
      });
      if (emailResponse.ok) {
        const json = await emailResponse.json();
        setEmailData({ ...emailData, message: json, error: null });
        toast.success("Pay email sent!");
      } else if (emailResponse.status === 401) {
        let json;
        try {
          json = await emailResponse.json();
        } catch (e) {
          throw emailResponse;
        }
        if (json.error) throw json.error;
      } else {
        throw emailResponse;
      }
    } catch (e) {
      console.error(e);
      setEmailData({ ...emailData, error: e, message: "" });
    }
  };

  window.document.title = isLoading ? "Suite Sales" : `${getText(invoice, "tranid")} | Suite Sales`

  if (error) return <ErrorMessage error={error} />;
  if (isLoading) return <EclipseSpinner />;
  if (!invoice) return <NotFound404 />;

  return (
    <>
      <Header className="flex-col justify-center  w-full lg:flex-row pt-2 pb-2">
        <Box className="flex-1">
          <SubmitButton
            className="mr-2"
            onClick={() => navigate(`/customers/${getValue(invoice, "entity")}`)}
          >
            <span>View Customer</span>
          </SubmitButton>
          <SubmitButton
            onClick={() => navigate(`/salesorders/${getValue(invoice, "createdfrom")}`)}
          >
            <span>View Sales Order</span>
          </SubmitButton>
        </Box>
        <Typography variant="h6" className="font-bold text-center ">
          {`Invoice: ${getText(invoice, "tranid")} `}
          <InvoicePDF recordId={id} entityId={getValue(invoice, "entity")} recordType="invoice" />
        </Typography>
        {/* <Box className="lg:ml-auto"></Box> */}
        <Box className="flex-1 mx-1 min-w-max flex flex-row flex-nowrap justify-end">
          {getText(invoice, "status") == "Open"  && (
            <SubmitButton
              loadingPosition="end"
              endIcon={<EmailIcon />}
              onClick={confirmSendPaySuiteInvite}
            >
              Send Invite to Pay
            </SubmitButton>
          )}
          </Box>
      </Header>

      <MainContainer>
        <Box>
          <Card header={"Primary Info"} color="bg-blue-300">
            <DataCard header="Customer">{getText(invoice, "entity")}</DataCard>
            <DataCard header="Order Date">
              {getText(invoice, "trandate")}
            </DataCard>
            <DataCard header="Order Status">
              {getText(invoice, "status")}
            </DataCard>
            <DataCard header="PO #">{getText(invoice, "otherrefnum")}</DataCard>
            <DataCard header="Inside Sales Rep">
              {getText(invoice, "salesrep")}
            </DataCard>
            <DataCard header="Costco Sales Rep">
              {getText(invoice, "custbody_costco_sales_rep")}
            </DataCard>
            <DataCard header="Outside Sales Rep">
              {getText(invoice, "partner")}
            </DataCard>
          </Card>
        </Box>

        <Box>
          <Card header={"Shipping"} color="bg-yellow-100">
            <DataCard header="Actual Ship Date">
              {getText(invoice, "actualshipdate")}
            </DataCard>
            <DataCard header="Ship Method">
              {getText(invoice, "shipmethod")}
            </DataCard>
            <DataCard header="Phone">{getText(invoice, "shipphone")}</DataCard>
            <Box className="w-full h-0" /> {/* line break for flexbox */}
            <DataCard header="Shipping Address">
              <Typography className="whitespace-pre-wrap">
                {getText(invoice, "shipaddress")}
              </Typography>
            </DataCard>
            <DataCard header="Billing Address">
              <Typography className="whitespace-pre-wrap">
                {getText(invoice, "billaddress")}
              </Typography>
            </DataCard>
            <Box className="w-full h-0" /> {/* line break for flexbox */}
            {packages?.length ? (
              <DataCard header={"Packages"} className="flex-1">
                <DataGridPro
                  rows={packages.map((pkg, index) => ({
                    id: index,
                    // carrier: pkg['GROUP(custrecord_packages_carrier)'],
                    service: pkg["GROUP(custrecord_packages_service)"],
                    tracking: pkg["GROUP(custrecord_packages_tracking_number)"],
                    contents: pkg["MAX(formulatext)"],
                  }))}
                  columns={packagesCols}
                  density="compact"
                  //autoPageSize={true}
                  autoHeight={true}
                  disableSelectionOnClick={true}
                  hideFooter={true}
                  minWidth={600}
                />
              </DataCard>
            ) : (
              ""
            )}
          </Card>
        </Box>

        <Box>
          <Card header={"Financial"} color="bg-green-200">
            <DataCard header="Class">{getText(invoice, "class")}</DataCard>
            <DataCard header="Terms">{getText(invoice, "terms")}</DataCard>
            { getText(invoice, "status") == "Open" && 
              <PayInvoice entityId={getValue(invoice, "entity")} recordId={id} />
            }
          </Card>
        </Box>

        
        { installments.length > 0 && (
          <Box>
              <Box>
                <Card header={"Installments"} color="bg-purple-300">
                  <DataGridPro
                    rows={installments.map((installment, index) => ({
                      id: index,
                      duedate: installment.duedate,
                      amount: installment.amount,
                      amountremaining: installment.amountremaining,
                      status: installment.status,
                    }))}
                    columns={installmentsCols}
                    autoHeight={true}
                    hideFooter={true}
                    density="compact"
                  />
                </Card>
              </Box>
              </Box>
            )}
        

        <Box>
          <Card header={"Line Items"} color="bg-red-300">
            {
              <DataGridPro
                rows={[
                  // Start with the normal line items
                  ...lineItems.map(({ amount = "0.00", ...line }, index) => ({
                    ...line,
                    id: index,
                    description: getText(line, "memo"),
                    item: getText(line, "item"),
                    amount,
                  })),
                  // Add footer rows for order totals
                  {
                    id: "discount",
                    description: "Discount",
                    amount: getValue(invoice, "discountamount"),
                  },
                  {
                    id: "shipping",
                    description: "Shipping",
                    amount: getText(invoice, "shippingcost"),
                  },
                  {
                    id: "total",
                    description: "Total",
                    amount: getText(invoice, "amount"),
                  },
                ]}
                disableSelectionOnClick={true}
                columns={lineItemCols}
                //autoPageSize={true}
                autoHeight={true}
                hideFooter={true}
                density="compact"
              />
            }
          </Card>
        </Box>
      </MainContainer>
      {showSendEmailAlertDialog && (
        <AlertDialog
          header="Email customer?"
          body="Are you sure you want to send an email to this customer inviting them to pay their invoice?"
          open={showSendEmailAlertDialog}
          setShowAlertDialog={setShowSendEmailAlertDialog}
          agreeFunction={handleSendEmail}
          disagreeText="Cancel"
          agreeText="Send Email"
        />
      )}
    </>
  );
};

export default InvoiceDetails;
