import React, { useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthContext } from '../../services/Authentication'

const AuthenticatedRoute = ({ children, redirect, ...rest }) => {
  const { state: { isAuthenticated } = {} } = useContext(AuthContext)
  const location = useLocation();

  return isAuthenticated ? (children) : (<Navigate to={{ pathname: '/login', state: { from: location.pathname } }} replace/>)
}

export default AuthenticatedRoute
