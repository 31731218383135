import React, { useContext, useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import Header from "../../layout/Header";
import toast from "react-hot-toast";

import { AuthContext } from "../../../services/Authentication";

import EclipseSpinner from "../../EclipseSpinner";
import NotFound404 from "../NotFound404";
import MainContainer from "../../layout/MainContainer";

import SubmitButton from "../../forms/SubmitButton";

import Card from "../../layout/Card";
import Input from "../../forms/Input";
import ComboBox from "../../forms/ComboBox";
import AddressAutoCompleteForm from "../../forms/AddressAutoCompleteForm";
import ContactForm from "../../forms/ContactForm";

import {
  Box,
  ButtonGroup,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import useCustomer, { getValue } from "../../../hooks/useCustomer";
import ErrorMessage from "../ErrorMessage";
import AlertDialog from "../../forms/AlertDialog";
import usePermissions from "../../../hooks/usePermissions";
import {
  customerClasses,
  priceLevels,
  terms,
  customerStatuses,
} from "../../constants";

const CustomerForm = ({ ...props }) => {
  const permissions = usePermissions();

  // default id of 0 is used if creating a new customer rather than editing
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const { dispatch, state: authState } = useContext(AuthContext);
  const [showErrorAlertDialog, setShowErrorAlertDialog] = useState(false);
  const [showClassAlertDialog, setShowClassAlertDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const [contacts, setContacts] = useState([]);
  const [blankCustomerState, setBlankCustomerState] = useState({
    isperson: "F",
    companyname: "",
    firstname: "",
    lastname: "",
    entitystatus: "",
    category: authState.user.default_category || "",
    salesrep: "",
    custentity_costco_sales_rep: "",
    partner: "",
    custentity_customer_service_rep: "",
    email: "",
    phone: "",
    custentity_email_sales_orders_to: "",
    custentity_email_fulfillments_to: "",
    custentity_email_invoices_to: "",
    custentity_email_statements_to: "",
    custentity_default_class: authState.user.default_class || "",
    terms: authState.user.default_terms || "",
    pricelevel: authState.user.default_pricelevel || "",
    custentity_active_in_exemptax:
      authState.user.default_exemptax_status || false,
    custentity_inxeption_id: "",
  });
  const [billAddr, setBillAddr] = useState({
    addressee: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [shipAddr, setShipAddr] = useState({
    addressee: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });

  useEffect(() => {
    setBlankCustomerState((prevData) => ({
      ...prevData,
      category: authState.user.default_category || "",
      custentity_default_class: authState.user.default_class || "",
      terms: authState.user.default_terms || "",
      pricelevel: authState.user.default_pricelevel || "",
      custentity_active_in_exemptax:
        authState.user.default_exemptax_status || false,
    }));
  }, [authState.user]);

  let {
    isLoading,
    error,
    customer: customerData,
    contacts: contactsData,
  } = useCustomer(id);

  const [customer, setCustomer] = useState(customerData || blankCustomerState);

  const [sameAsShipping, setSameAsShipping] = useState(
    Object.entries(billAddr).every(([key, val]) => shipAddr[key] === val)
  );

  useEffect(() => {
    if (customerData && contactsData) {
      // clean data
      Object.keys(customerData).forEach(
        (key) => (customerData[key] = getValue(customerData, key))
      );
      contactsData.forEach((contact) =>
        Object.keys(contact).forEach(
          (key) => (contact[key] = getValue(contact, key))
        )
      );

      // Warn the user if they are editing a customer that doesn't have a class in suite sales
      const customerClass = customerClasses.find(
        ({ id }) => id === parseInt(customerData.custentity_default_class)
      );
      if (!customerClass) setShowClassAlertDialog(true);

      customerData.isperson = customerData.isperson ? "T" : "F";

      setCustomer({
        isperson: customerData?.isperson || "F",
        companyname: customerData?.companyname || "",
        firstname: customerData?.firstname || "",
        lastname: customerData?.lastname || "",
        entitystatus: customerData?.entitystatus || "",
        category: customerData?.category || "",
        salesrep: customerData?.salesrep || "",
        custentity_costco_sales_rep:
          customerData?.custentity_costco_sales_rep || "",
        partner: customerData?.partner || "",
        custentity_customer_service_rep:
          customerData?.custentity_customer_service_rep || "",
        email: customerData?.email || "",
        phone: customerData?.phone || "",
        custentity_email_sales_orders_to:
          customerData?.custentity_email_sales_orders_to || "",
        custentity_email_fulfillments_to:
          customerData?.custentity_email_fulfillments_to || "",
        custentity_email_invoices_to:
          customerData?.custentity_email_invoices_to || "",
        custentity_email_statements_to:
          customerData?.custentity_email_statements_to || "",
        custentity_default_class: customerData?.custentity_default_class || "",
        terms: customerData?.terms || "",
        pricelevel: customerData?.pricelevel || "",
        custentity_active_in_exemptax:
          customerData?.custentity_active_in_exemptax || false,
        custentity_inxeption_id: customerData?.custentity_inxeption_id || "",
      });

      setBillAddr({
        addressee: customerData?.billaddressee || "",
        addr1: customerData?.billaddress1 || "",
        addr2: customerData?.billaddress2 || "",
        city: customerData?.billcity || "",
        state: customerData?.billstate || "",
        zip: customerData?.billzipcode || "",
        country: customerData?.billcountry || "",
      });

      setShipAddr({
        addressee: customerData?.shipaddressee || "",
        addr1: customerData?.shipaddress1 || "",
        addr2: customerData?.shipaddress2 || "",
        city: customerData?.shipcity || "",
        state: customerData?.shipstate || "",
        zip: customerData?.shipzip || "",
        country: customerData?.shipcountry || "",
      });

      setContacts(contactsData.map((contact) => contact));
    }
  }, [customerData, contactsData, authState.user, id]);

  // Save the initial state to the session for comparison when submitting the edited form
  useEffect(() => {
    sessionStorage.setItem(
      "initialState",
      JSON.stringify({ customerData, contactsData, shipAddr, billAddr })
    );
  }, [customerData]);

  //console.log(shipAddr);

  useEffect(() => {
    if (id === 0) {
      setCustomer(blankCustomerState);
    }
  }, [id, blankCustomerState]);

  const handleOnBlurRequiredField = (name) => (e, value) => {
    if (!e.target.value) {
      setErrors({ ...errors, [name]: "This is a required field" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };
  const handleShipAddrChange = (address) => {
    if (sameAsShipping) {
      setBillAddr(address);
    }
    setShipAddr(address);
  };
  const handleSelectChange = (name) => (event, value) =>
    setCustomer({ ...customer, [name]: value?.id || "" });
  const handleInputChange = (event) =>
    setCustomer({ ...customer, [event.target.name]: event.target.value });
  const handleBillingCheckedChange = (handler) => (event) => {
    handler(event.target.checked);
    if (event.target.checked) setBillAddr(shipAddr);
  };
  const handleCheckedChange = (e) =>
    setCustomer({ ...customer, [e.target.name]: e.target.checked });
  const handleSubmit = async (event) => {
    try {
      setIsSaving(true);

      // FORM VALIDATION
      const requiredFields = [...document.querySelectorAll("[required]")].map(
        (el) => el.getAttribute("name")
      );

      // Check that required fields are not empty
      let formErrors = {};
      for (let field of requiredFields) {
        if (!customer[field]) {
          formErrors[field] = "This is a required field";
        }
      }

      // If there are any errors, let the user know and don't save
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        const { error } = {
          error: {
            name: "You're not done yet!",
            message: "Please fix any fields with errors",
          },
        };
        setShowErrorAlertDialog(true);
        throw error;
      }

      const initialState = JSON.parse(
        sessionStorage.getItem("initialState") || null
      );

      let method = "";
      let url = "";
      if (props.createNewCustomer) {
        method = "POST";
        url = `${process.env.REACT_APP_BASE_URL}/api/customers/`;
      } else {
        method = "PUT";
        url = `${process.env.REACT_APP_BASE_URL}/api/customers/${id}`;
      }

      const res = await fetch(url, {
        method: method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customer,
          shipAddr,
          billAddr,
          contacts,
          sameAsShipping,
          initialState,
        }),
      });
      if (res.status === 401) dispatch({ type: "LOGOUT" });

      const json = await res.json();
      const { error, result, record_id } = json;

      if (error) throw error;
      if (result !== "success") throw json;

      if (record_id) {
        setIsSaving(false);
        toast.success("Customer saved");
        navigate(`/customers/${record_id}`);
      } else {
        setIsSaving(false);
        alert(JSON.stringify(json));
      }
    } catch (e) {
      setIsSaving(false);
      console.error(e);
    }
  };

  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/login" replace />;
  }

  const header = props.createNewCustomer
    ? "New Customer"
    : `Edit: ${customerData?.altname}`;

  window.document.title = `${header} | Suite Sales`;

  if (isLoading) return <EclipseSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (!customerData && id !== 0) return <NotFound404 />;

  return (
    <>
      <Header header={header} className="justify-between flex-col lg:flex-row">
        <Box className="flex flex-row flex-nowrap mb-2">
          <SubmitButton
            disabled={isSaving}
            className="mr-2 bg-gray-500 hover:bg-gray-600 flex-1"
            endIcon={<CancelIcon />}
            onClick={() => navigate(-1)}
            loadingPosition="end"
          >
            Cancel
          </SubmitButton>
          <SubmitButton
            className="flex-1"
            endIcon={<SaveIcon />}
            onClick={handleSubmit}
            loading={isSaving}
            loadingPosition="end"
          >
            Save
          </SubmitButton>
        </Box>
      </Header>
      <MainContainer>
        <Box>
          <Card header={"Primary Info"} color="bg-blue-300">
            <ButtonGroup className="w-full mb-3">
              <Button
                className="w-1/2"
                color="info"
                variant={customer.isperson === "T" ? "outlined" : "contained"}
                onClick={() => setCustomer({ ...customer, isperson: "F" })}
              >
                Company
              </Button>
              <Button
                className="w-1/2"
                color="info"
                variant={customer.isperson === "T" ? "contained" : "outlined"}
                onClick={() => setCustomer({ ...customer, isperson: "T" })}
              >
                Individual
              </Button>
            </ButtonGroup>
            {customer.isperson === "T" ? (
              <>
                <Box className="flex w-full">
                  <Box className="flex-1 mr-2 ">
                    <Input
                      required
                      error={!!errors.firstname}
                      helperText={errors.firstname}
                      name="firstname"
                      placeholder="First Name"
                      value={customer.firstname}
                      onChange={handleInputChange}
                      onBlur={handleOnBlurRequiredField("firstname")}
                    />
                  </Box>
                  <Box className="flex-1 ">
                    <Input
                      required
                      error={!!errors.lastname}
                      helperText={errors.lastname}
                      name="lastname"
                      placeholder="Last Name"
                      value={customer.lastname}
                      onChange={handleInputChange}
                      onBlur={handleOnBlurRequiredField("lastname")}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Input
                required
                error={!!errors.companyname}
                helperText={errors.companyname}
                name="companyname"
                placeholder="Company Name"
                value={customer.companyname}
                onChange={handleInputChange}
                onBlur={handleOnBlurRequiredField("companyname")}
              />
            )}

            <ComboBox
              required
              textFieldProps={{
                error: !!errors.status,
                helperText: errors.status,
              }}
              options={customerStatuses}
              value={customer.entitystatus}
              name="entitystatus"
              onChange={handleSelectChange("entitystatus")}
            >
              Status
            </ComboBox>

            <Box className="md:flex w-full">
              <Input
                className="md:flex-1 md:mr-1 "
                name="email"
                type="email"
                placeholder="Email"
                value={customer.email}
                onChange={handleInputChange}
              />
              <Input
                className="md:flex-1 md:ml-1 "
                name="phone"
                placeholder="Phone"
                value={customer.phone}
                onChange={handleInputChange}
              />
            </Box>
            <ComboBox
              required
              textFieldProps={{
                error: !!errors.category,
                helperText: errors.category,
              }}
              options={metadata.categories?.data}
              value={customer.category}
              name="category"
              onChange={handleSelectChange("category")}
            >
              Category
            </ComboBox>

            <Box className="md:flex w-full">
              {permissions.canCreateAllCustomers && (
                <Box className="md:flex-1 md:mr-1">
                  <ComboBox
                    textFieldProps={{
                      name: "salesrep",
                      error: !!errors.salesrep,
                      helperText: errors.salesrep,
                    }}
                    options={metadata.salesreps?.data}
                    value={customer.salesrep}
                    name="salesrep"
                    onChange={handleSelectChange("salesrep")}
                  >
                    Inside Sales Rep
                  </ComboBox>
                </Box>
              )}
              {permissions.canCreateAllCustomers && (
                <Box className="md:flex-1 md:ml-1">
                  <ComboBox
                    textFieldProps={{
                      name: "custentity_costco_sales_rep",
                      error: !!errors.custentity_costco_sales_rep,
                      helperText: errors.custentity_costco_sales_rep,
                    }}
                    options={metadata.costcoreps?.data}
                    value={customer.custentity_costco_sales_rep}
                    name="custentity_costco_sales_rep"
                    onChange={handleSelectChange("custentity_costco_sales_rep")}
                    disableClearable={false}
                  >
                    Costco Sales Rep
                  </ComboBox>
                </Box>
              )}
            </Box>
            <Box className="md:flex w-full">
              {authState.user.user_type !== "PARTNER" && (
                <Box className="md:flex-1 md:mr-1">
                  <ComboBox
                    options={metadata.partners?.data}
                    value={customer.partner}
                    name="partner"
                    onChange={handleSelectChange("partner")}
                    disableClearable={false}
                  >
                    Outside Sales Rep
                  </ComboBox>
                </Box>
              )}
              <Box className="md:flex-1 md:ml-1">
                <ComboBox
                  textFieldProps={{
                    error: !!errors.custentity_customer_service_rep,
                    helperText: errors.custentity_customer_service_rep,
                  }}
                  options={metadata.customerServiceReps?.data}
                  value={customer.custentity_customer_service_rep}
                  name="custentity_customer_service_rep"
                  onChange={handleSelectChange(
                    "custentity_customer_service_rep"
                  )}
                >
                  Customer Service Rep
                </ComboBox>
              </Box>
            </Box>
          </Card>
        </Box>

        <Box>
          <Card header={"Address"} color="bg-yellow-100">
            <Box className="md:flex w-full">
              <Box className="md:flex-1 md:mr-1">
                <AddressAutoCompleteForm
                  label="Shipping Address"
                  idSuffix="shipping"
                  updateFunction={handleShipAddrChange}
                  value={shipAddr}
                  onChange={handleShipAddrChange}
                />
              </Box>
              <Box className="md:flex-1 md:ml-1">
                <AddressAutoCompleteForm
                  label="Billing Address"
                  idSuffix="billing"
                  disabled={sameAsShipping}
                  value={billAddr}
                  onChange={setBillAddr}
                  updateFunction={setBillAddr}
                >
                  <FormGroup className="absolute -right-4 -top-3">
                    <FormControlLabel
                      label="Same as shipping?"
                      control={
                        <Checkbox
                          checked={sameAsShipping}
                          name="sameAsShipping"
                          onChange={handleBillingCheckedChange(
                            setSameAsShipping
                          )}
                        />
                      }
                    />
                  </FormGroup>
                </AddressAutoCompleteForm>
              </Box>
            </Box>
          </Card>
        </Box>

        <Box>
          <Card header={"Email Notifications"} color="bg-pink-200">
          <div style={{ textAlign: 'center', opacity: 0.8, width: '100%' }}>
            Multiple Email Addresses Need to be Separated by a Semicolon
          </div>

            <Input
              name="custentity_email_sales_orders_to"
              title="Separate emails with semicolons"
              placeholder="Order Notifications"
              value={customer.custentity_email_sales_orders_to}
              onChange={handleInputChange}
            />

            <Input
              name="custentity_email_fulfillments_to"
              title="Separate emails with semicolons"
              placeholder="Shipping Notifications"
              value={customer.custentity_email_fulfillments_to}
              onChange={handleInputChange}
            />

            <Input
              name="custentity_email_invoices_to"
              title="Separate emails with semicolons"
              placeholder="Invoice Notifications"
              value={customer.custentity_email_invoices_to}
              onChange={handleInputChange}
            />

            <Input
              name="custentity_email_statements_to"
              title="Separate emails with semicolons"
              placeholder="Statement Notifications"
              value={customer.custentity_email_statements_to}
              onChange={handleInputChange}
            />
          </Card>
        </Box>

        <Box>
          <Card header={"Financial"} color="bg-green-200">
            <ComboBox
              required
              textFieldProps={{
                error: !!errors.custentity_default_class,
                helperText: errors.custentity_default_class,
              }}
              options={customerClasses}
              value={customer.custentity_default_class}
              name="custentity_default_class"
              onChange={handleSelectChange("custentity_default_class")}
            >
              Default Class
            </ComboBox>
            <ComboBox
              required
              textFieldProps={{
                error: !!errors.terms,
                helperText: errors.terms,
              }}
              options={terms}
              value={customer.terms}
              name="terms"
              onChange={handleSelectChange("terms")}
            >
              Terms
            </ComboBox>
            <ComboBox
              required
              textFieldProps={{
                error: !!errors.pricelevel,
                helperText: errors.pricelevel,
              }}
              options={priceLevels}
              value={customer.pricelevel}
              name="pricelevel"
              onChange={handleSelectChange("pricelevel")}
            >
              Price Level
            </ComboBox>
            <FormControlLabel
              label="Activate in Exemptax?"
              control={
                <Checkbox
                  checked={customer.custentity_active_in_exemptax}
                  name="custentity_active_in_exemptax"
                  onChange={handleCheckedChange}
                />
              }
            />
            <Input
              name="custentity_inxeption_id"
              placeholder="Inxeption ID"
              value={customer.custentity_inxeption_id}
              onChange={handleInputChange}
            />
          </Card>
        </Box>
        {customer.isperson === "F" && (
          <Box>
            <Card header={"Contacts"} color="bg-red-300">
              <ContactForm setContacts={setContacts} contacts={contacts} />
            </Card>
          </Box>
        )}
      </MainContainer>
      {showErrorAlertDialog && (
        <AlertDialog
          header="You're not done yet!"
          body="Please fix any fields with errors before saving."
          open={showErrorAlertDialog}
          setShowAlertDialog={setShowErrorAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
      {showClassAlertDialog && (
        <AlertDialog
          header="Careful!"
          body="This customer's class is not available in Suite Sales. If you edit and save this customer, its class will change."
          open={showClassAlertDialog}
          setShowAlertDialog={setShowClassAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
    </>
  );
};

export default CustomerForm;
