// SalesOrderForm.js
import React, { useContext, useState, useEffect } from "react";
import { useParams, Navigate, useNavigate } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import Header from "../../layout/Header";
import toast from "react-hot-toast";

import { AuthContext, initialState } from "../../../services/Authentication";

import EclipseSpinner from "../../EclipseSpinner";
import NotFound404 from "../NotFound404";
import MainContainer from "../../layout/MainContainer";
import SubmitButton from "../../forms/SubmitButton";

import Card from "../../layout/Card";
import Input from "../../forms/Input";
import ComboBox from "../../forms/ComboBox";
import AddressAutoCompleteForm from "../../forms/AddressAutoCompleteForm";
import LineItemForm from "../../forms/LineItemForm";

import {
  Box,
  ButtonGroup,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";

import useCustomer from "../../../hooks/useCustomer";
import useSalesOrder, { getValue, getText } from "../../../hooks/useSalesOrder";
import AlertDialog from "../../forms/AlertDialog";
import WarningBanner from "../../forms/WarningBanner";
import ErrorMessage from "../ErrorMessage";
import usePermissions from "../../../hooks/usePermissions";
import {
  customerClasses,
  terms,
  salesOrderStatuses,
  priceLevels,
  shippingMethods,
} from "../../constants";

const SalesOrderForm = ({ ...props }) => {
  const permissions = usePermissions();

  // default id of 0 is used if creating a new Sales Order rather than editing
  const { id = 0, customerID = 0 } = useParams();
  const navigate = useNavigate();
  const { dispatch, state: authState } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const [showErrorAlertDialog, setShowErrorAlertDialog] = useState("");
  const [showAlertDialog, setShowAlertDialog] = useState("");
  const [lineItems, setLineItems] = useState([]);
  const [customerPriceLevel, setCustomerPriceLevel] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [errors, setErrors] = useState({});
  const [blankSalesOrderState, setBlankSalesOrderState] = useState({
    entity: "",
    partner: "",
    otherrefnum: "",
    inside_sales_rep: "",
    costco_sales_rep: "",
    shipmethod: "",
    shipphone: "",
    class: "",
    terms: authState.user.default_terms || "",
    custbody_is_will_call_order: false,
    custbody_is_replacement_order: false,
    custbody_sanctuary_will_call: false,
    custbody_is_high_priority: false,
    shipdate: "",
    customerPriceLevel: "",
  });
  const [billAddr, setBillAddr] = useState({
    addressee: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });
  const [shipAddr, setShipAddr] = useState({
    addressee: "",
    addr1: "",
    addr2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  });


  useEffect(() => {
    setBlankSalesOrderState((prevData) => ({
      ...prevData,
      custentity_default_class: authState.user.default_class || "",
      terms: authState.user.default_terms || "",
    }));
  }, [authState.user]);

  // API Get call to the backend to store Sales Order data
  let {
    isLoading,
    error,
    salesOrder: salesOrderData,
    lineItems: lineItemsData,
    // We don't need to worry about the packages
    packages,
  } = useSalesOrder(id);

  // If customerID is greater than 0, set salesOrder.entity
  useEffect(() => {
    if (customerID > 0) {
      fetchCustomerData(customerID);
    }
  }, [customerID]);

  const [salesOrder, setSalesOrder] = useState(
    salesOrderData || blankSalesOrderState
  );

  const [sameAsShipping, setSameAsShipping] = useState(true);

  // Populate the form with the sales order information, if its a new form, the form is blank
  useEffect(() => {
    if (salesOrderData && lineItemsData) {
      
      setCustomerName(getText(salesOrderData, "entity"));

      Object.keys(salesOrderData).forEach(
        (key) => (salesOrderData[key] = getValue(salesOrderData, key))
      );

      lineItemsData.forEach((lineItem) =>
        Object.keys(lineItem).forEach(
          (key) => (lineItem[key] = getValue(lineItem, key))
        )
      );

      // There are some fields that arn't in suite sales
      let missingFields = checkForAlertDialog(salesOrderData, lineItemsData);

      setSalesOrder({
        entity: salesOrderData?.entity || "",
        partner: salesOrderData?.partner || "",
        otherrefnum: salesOrderData?.otherrefnum || "",
        salesrep: salesOrderData?.salesrep || "",
        custbody_costco_sales_rep:
          salesOrderData?.custbody_costco_sales_rep || "",
        shipmethod: salesOrderData?.shipmethod || "",
        shipphone: salesOrderData?.shipphone || "",
        // If the class and term arn't in suite sales, then make them an empty string
        class: missingFields.includes("class") ? "" : salesOrderData?.class,
        terms: missingFields.includes("term") ? "" : salesOrderData?.terms,
        custbody_is_will_call_order:
          salesOrderData?.custbody_is_will_call_order || false,
        custbody_is_replacement_order:
          salesOrderData?.custbody_is_replacement_order || false,
        custbody_sanctuary_will_call:
          salesOrderData?.custbody_sanctuary_will_call || false,
        custbody_is_high_priority:
          salesOrderData?.custbody_is_high_priority || false,
        shipdate: salesOrderData?.shipdate || "",
      });

      // Set addresses
      const salesOrderShipAddr = {
        addressee: salesOrderData?.shipaddressee || "",
        addr1: salesOrderData?.shipaddress1 || "",
        addr2: salesOrderData?.shipaddress2 || "",
        city: salesOrderData?.shipcity || "",
        state: salesOrderData?.shipstate || "",
        zip: salesOrderData?.shipzip || "",
        country: salesOrderData?.shipcountry || "",
      };

      const salesOrderBillAddr = {
        addressee: salesOrderData?.billaddressee || "",
        addr1: salesOrderData?.billaddress1 || "",
        addr2: salesOrderData?.billaddress2 || "",
        city: salesOrderData?.billcity || "",
        state: salesOrderData?.billstate || "",
        zip: salesOrderData?.billzip || "",
        country: salesOrderData?.billcountry || "",
      };

      setShipAddr(salesOrderShipAddr);
      setBillAddr(salesOrderBillAddr);

      // Check if the addresses are the same
      const isSame = areAddressesSame(salesOrderShipAddr, salesOrderBillAddr);
      setSameAsShipping(isSame);

      setLineItems(lineItemsData.map((lineItems) => lineItems));

      //compareAddresses(shipAddr, billAddr);
    }
  }, [salesOrderData, authState.user, id]);

  //Save the initial state to the session for comparison when submitting the edited form
  useEffect(() => {
    sessionStorage.setItem(
      "initialState",
      JSON.stringify({
        salesOrderData,
        lineItemsData,
        packages,
        shipAddr,
        billAddr,
      })
    );
  }, [salesOrderData]);

  useEffect(() => {
    if (id === 0) {
      setSalesOrder(blankSalesOrderState);
    }
  }, [id, blankSalesOrderState]);

  const checkForAlertDialog = (salesOrderData, lineItemsData) => {
    let missingFields = [];
    const salesOrderClass = customerClasses.find(({ id }) => id === parseInt(salesOrderData.class));
    if (!salesOrderClass) missingFields.push("class");

    const salesOrderTerm = terms.find(({ id }) => id === parseInt(salesOrderData.terms));
    if (!salesOrderTerm) missingFields.push("term");

    let missingPriceLevel = false;
    let missingItem = false;
    for (const lineItem of lineItemsData) {
        const priceLevelExists = priceLevels.find(({ id }) => id === parseInt(lineItem.pricelevel));
        const itemExists = metadata.items.data.find(({ id }) => id === parseInt(lineItem.item));
        if (!priceLevelExists) {
            lineItem.pricelevel = "";
            missingPriceLevel = true;
            if (!missingFields.includes("price level")) {
                missingFields.push("price level"); 
            }
        }
        if (!itemExists) {
            lineItem.item = "";
            missingItem = true;
            if (!missingFields.includes("item")) {
                missingFields.push("item");
            }
        }
    }

    if (missingFields.length > 0 || missingPriceLevel || missingItem) {
        const alertDialogContent = (
            <div>
                <div>This sales order has values that are not available in Suite Sales or that you don't have permission to access. If you edit and save this sales order, the following fields will change:</div>
                <ul>
                    {missingFields.map((field, index) => (
                        <li key={index}><strong> - {field}</strong></li>
                    ))}
                </ul>
            </div>
        );

        // Assuming setShowAlertDialog can accept JSX
        setShowAlertDialog(alertDialogContent);
    }

    return missingFields;
};

  const handleOnBlurRequiredField = (name) => (e, value) => {
    if (!e.target.value) {
      setErrors({ ...errors, [name]: "This is a required field" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleShipAddrChange = (address) => {
    if (sameAsShipping) {
      setBillAddr(address);
    }
    setShipAddr(address);
  };

  const handleSelectChange = (name) => (event, value) => {
    setSalesOrder({ ...salesOrder, [name]: value?.id || "" });
  };
  const handleInputChange = (event) =>
    setSalesOrder({ ...salesOrder, [event.target.name]: event.target.value });
  const handleBillingCheckedChange = (handler) => (event) => {
    handler(event.target.checked);
    if (event.target.checked) setBillAddr(shipAddr);
  };
  const handleCheckedChange = (e) =>
    setSalesOrder({ ...salesOrder, [e.target.name]: e.target.checked });

  // Function to compare two address objects
  const areAddressesSame = (addr1, addr2) => {
    const keys = [
      "addressee",
      "addr1",
      "addr2",
      "city",
      "state",
      "zip",
      "country",
    ];
    return keys.every((key) => addr1[key] === addr2[key]);
  };

  // Populates relate fields when a customer is selected
  const fetchCustomerData = async (newCustomerID) => {
    try {
      if (newCustomerID) {
        //console.log("CUSTOMER ID", newCustomerID);
        const method = "GET";
        const url = `${process.env.REACT_APP_BASE_URL}/api/customers/${newCustomerID}`;
        const res = await fetch(url, {
          method: method,
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
        });

        //console.log(res);

        if (res.status === 200) {
          const json = await res.json();
          const customer = json.customer.data[0];

          setCustomerPriceLevel(customer?.pricelevel[0]?.value || "");
          setCustomerName(customer?.altname || "");

          Object.keys(customer).forEach(
            (key) => (customer[key] = getValue(customer, key))
          );

          // Check if the class and terms fetch from the customer are in Suite Sales, if not, leave them blank
          const customerClass = customerClasses.find(({ id }) => id === parseInt(customer.custentity_default_class));
          const customerTerm = terms.find(({ id }) => id === parseInt(customer.terms));
          setSalesOrder({
            ...salesOrder,
            entity: customer?.internalid || "",
            partner: customer?.partner || "",
            salesrep: customer?.salesrep || "",
            custbody_costco_sales_rep:
              customer?.custentity_costco_sales_rep || "",
            shipphone: customer?.phone || "",
            class: customerClass ? customer?.custentity_default_class : "",
            terms: customerTerm ? customer?.terms : "",
          });

          // Set addresses
          const fetchedShipAddr = {
            addressee: customer?.shipaddressee || "",
            addr1: customer?.shipaddress1 || "",
            addr2: customer?.shipaddress2 || "",
            city: customer?.shipcity || "",
            state: customer?.shipstate || "",
            zip: customer?.shipzip || "",
            country: customer?.shipcountry || "",
          };

          const fetchedBillAddr = {
            addressee: customer?.billaddressee || "",
            addr1: customer?.billaddress1 || "",
            addr2: customer?.billaddress2 || "",
            city: customer?.billcity || "",
            state: customer?.billstate || "",
            zip: customer?.billzipcode || "",
            country: customer?.billcountry || "",
          };

          setShipAddr(fetchedShipAddr);
          setBillAddr(fetchedBillAddr);

          // Check if the addresses are the same
          const isSame = areAddressesSame(fetchedShipAddr, fetchedBillAddr);
          setSameAsShipping(isSame);
        } else {
          toast.error("Customer couldn't be found in NetSuite");
        }
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // If it is a new sales order, populate the fields when a customer is selected
  // useEffect(() => {
  //   if (props.createNewSalesOrder && salesOrder.entity) {
  //     fetchCustomerData(salesOrder.entity);
  //   }
  // }, [props.createNewSalesOrder, salesOrder.entity]);

  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/login" replace />;
  }

  let allLineItems = metadata.items.data;
  // Makes the allLineItems a key-value pair with the "id" as the key for quicker lookup speed
  allLineItems = allLineItems.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  const handleSubmit = async () => {
    try {
      setIsSaving(true);

      // FORM VALIDATION
      const requiredFields = [...document.querySelectorAll("[required]")].map(
        (el) => el.getAttribute("name")
      );

      // Check that required fields are not empty
      let formErrors = {};
      for (let field of requiredFields) {
        if (!salesOrder[field]) {
          formErrors[field] = "This is a required field";
        }
      }

      // If there are any errors, let the user know and don't save
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        const { error } = {
          error: {
            name: "You're not done yet!",
            message: "Please fix any fields with errors",
          },
        };
        setShowErrorAlertDialog(
          "Please fix any fields with errors before saving."
        );
        throw error;
      }

      // Check to see if there are any incomplete line items
      let unfinishedLineItem = false;
      lineItems.forEach((lineItem) => {
        if (
          lineItem.item === "" ||
          lineItem.quantity === "" ||
          lineItem.pricelevel === "" ||
          lineItem.rate === ""
        ) {
          unfinishedLineItem = true;
        }
      });
      if (unfinishedLineItem || lineItems.length <= 0) {
        // Show AlertDialog with custom error message
        let message =
          lineItems.length <= 0
            ? "You need at least one item for the Sales Order."
            : "You have an incomplete line item.";
        const { error } = {
          error: {
            name: "You're not done yet!",
            message: message,
          },
        };
        setShowErrorAlertDialog(message);
        throw error;
      }

      const initialState = JSON.parse(
        sessionStorage.getItem("initialState") || null
      );

      let method = "";
      let url = "";
      if (props.createNewSalesOrder) {
        method = "POST";
        url = `${process.env.REACT_APP_BASE_URL}/api/salesorders/`;
      } else {
        method = "PUT";
        url = `${process.env.REACT_APP_BASE_URL}/api/salesorders/${id}`;
      }

      //console.log("Sales Order", salesOrder);

      const res = await fetch(url, {
        method: method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          salesOrder,
          shipAddr,
          billAddr,
          lineItems,
          sameAsShipping,
          initialState,
        }),
      });
      if (res.status === 401) dispatch({ type: "LOGOUT" });

      const json = await res.json();
      const { error, result, record_id } = json;

      if (error) throw error;
      if (result !== "success") throw json;

      if (record_id) {
        setIsSaving(false);
        toast.success("Sales Order saved");
        navigate(`/salesorders/${record_id}`);
      } else {
        setIsSaving(false);
        alert(JSON.stringify(json));
      }

      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
      console.error(e);
    }
  };

  const header = props.createNewSalesOrder
    ? "New Sales Order - " + customerName
    : `Edit: ${salesOrderData?.tranid} - ` + customerName;

  window.document.title = `${header} | Suite Sales`;

  if (isLoading) return <EclipseSpinner />;
  if (error) return <ErrorMessage error={error} />;
  if (!salesOrderData && id !== 0) return <NotFound404 />;

  return (
    <>
      <Header header={header} className="justify-between flex-col lg:flex-row">
        <Box className="flex flex-row flex-nowrap mb-2">
          <SubmitButton
            disabled={isSaving}
            className="mr-2 bg-gray-500 hover:bg-gray-600 flex-1"
            endIcon={<CancelIcon />}
            onClick={() => navigate(-1)}
            loadingPosition="end"
          >
            Cancel
          </SubmitButton>
          <SubmitButton
            className="flex-1"
            endIcon={<SaveIcon />}
            onClick={handleSubmit}
            loading={isSaving}
            loadingPosition="end"
          >
            Save
          </SubmitButton>
        </Box>
      </Header>
      <MainContainer>
        <Box>
          <Card header={"Primary Info"} color="bg-blue-300">
            <Box className="flex w-full">
              <Box className="flex-1 mr-2">
                {/* <ComboBox
                      required
                      textFieldProps={{
                        error: !!errors.entity,
                        helperText: errors.entity,
                      }}
                      options={metadata.customers?.data}
                      value={salesOrder.entity}
                      name="entity"
                      onChange={handleSelectChange("entity")}
                    >
                      Customer
                    </ComboBox> */}
              </Box>
            </Box>
            <Box className="flex w-full">
              <Box className="flex-1 mr-2">
                <Input
                  name="otherrefnum"
                  placeholder="PO #"
                  value={salesOrder.otherrefnum}
                  onChange={handleInputChange}
                />
              </Box>
              {permissions.canCreateAllSalesOrders && (
                <Box className="flex-1 mr-2">
                  <ComboBox
                    textFieldProps={{
                      name: "salesrep",
                      error: !!errors.salesrep,
                      helperText: errors.salesrep,
                    }}
                    options={metadata.salesreps?.data}
                    value={salesOrder.salesrep}
                    name="salesrep"
                    onChange={handleSelectChange("salesrep")}
                    className="py-1"
                  >
                    Inside Sales Rep
                  </ComboBox>
                </Box>
              )}
            </Box>
            <Box className="flex w-full">
              {permissions.canCreateAllSalesOrders && (
                <Box className="flex-1 mr-2">
                  <ComboBox
                    // textFieldProps={{
                    //   name: "custbody_costco_sales_rep",
                    //   error: !!errors.custbody_costco_sales_rep,
                    //   helperText: errors.custbody_costco_sales_rep,
                    // }}
                    options={metadata.costcoreps?.data}
                    value={salesOrder.custbody_costco_sales_rep}
                    name="custbody_costco_sales_rep"
                    onChange={handleSelectChange("custbody_costco_sales_rep")}
                    disableClearable={false}
                  >
                    Costco Sales Rep
                  </ComboBox>
                </Box>
              )}
              {authState.user.user_type !== "PARTNER" && (
                <Box className="flex-1 mr-2">
                  <ComboBox
                    options={metadata.partners?.data}
                    value={salesOrder.partner}
                    name="partner"
                    onChange={handleSelectChange("partner")}
                    disableClearable={false}
                  >
                    Outside Sales Rep
                  </ComboBox>
                </Box>
              )}
            </Box>
            <Box className="flex w-full">
              <Box className="flex-1 mr-2">
                <FormControlLabel
                  label="Replacement Order"
                  control={
                    <Checkbox
                      checked={salesOrder.custbody_is_replacement_order}
                      name="custbody_is_replacement_order"
                      onChange={handleCheckedChange}
                    />
                  }
                />
              </Box>
              <Box className="flex-1 mr-2">
                <FormControlLabel
                  label="Lion Will Call Order"
                  control={
                    <Checkbox
                      checked={salesOrder.custbody_is_will_call_order}
                      name="custbody_is_will_call_order"
                      onChange={handleCheckedChange}
                    />
                  }
                />
              </Box>
              <Box className="flex-1 mr-2">
                <FormControlLabel
                  label="Sanctuary Will Call Order"
                  control={
                    <Checkbox
                      checked={salesOrder.custbody_sanctuary_will_call}
                      name="custbody_sanctuary_will_call"
                      onChange={handleCheckedChange}
                    />
                  }
                />
              </Box>
              <Box className="flex-1 mr-2">
                <FormControlLabel
                  label="High Priority"
                  control={
                    <Checkbox
                      checked={salesOrder.custbody_is_high_priority}
                      name="custbody_is_high_priority"
                      onChange={handleCheckedChange}
                    />
                  }
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Card header={"Shipping"} color="bg-yellow-100">
            <Box className="flex w-full">
              <Box className="flex-1 mr-2">
                <ComboBox
                  // required
                  // textFieldProps={{
                  //   error: !!errors.terms,
                  //   helperText: errors.terms,
                  // }}
                  options={shippingMethods}
                  value={salesOrder.shipmethod}
                  name="shipmethod"
                  onChange={handleSelectChange("shipmethod")}
                >
                  Shipping Method
                </ComboBox>
              </Box>
              <Box className="flex-1 mr-2">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Ship Date"
                    name="shipdate"
                    value={
                      salesOrder.shipdate ? new Date(salesOrder.shipdate) : null
                    }
                    onChange={(date) => {
                      try {
                        const formattedDate = date
                          ? new Intl.DateTimeFormat("en-US").format(date)
                          : "";
                        handleInputChange({
                          target: { name: "shipdate", value: formattedDate },
                        });
                      } catch (e) {
                        console.log("Invalid");
                      }
                    }}
                    renderInput={(props) => (
                      <TextField {...props} className="w-full" size="small" />
                    )}
                    placeholder="Select Actual Ship Date"
                    className="py-1"
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box className="md:flex w-full">
              <Box className="md:flex-1 md:mr-1">
                <AddressAutoCompleteForm
                  label="Shipping Address"
                  idSuffix="shipping"
                  updateFunction={handleShipAddrChange}
                  value={shipAddr}
                  onChange={handleShipAddrChange}
                />
              </Box>
              <Box className="md:flex-1 md:ml-1">
                <AddressAutoCompleteForm
                  label="Billing Address"
                  idSuffix="billing"
                  disabled={sameAsShipping}
                  value={billAddr}
                  onChange={setBillAddr}
                  updateFunction={setBillAddr}
                >
                  <FormGroup className="absolute -right-4 -top-3">
                    <FormControlLabel
                      label="Same as shipping?"
                      control={
                        <Checkbox
                          checked={sameAsShipping}
                          name="sameAsShipping"
                          onChange={handleBillingCheckedChange(
                            setSameAsShipping
                          )}
                        />
                      }
                    />
                  </FormGroup>
                </AddressAutoCompleteForm>
              </Box>
            </Box>
            <Box className="flex w-full">
              <Box className="flex-1 mr-2">
                <Input
                  required
                  error={!!errors.shipphone}
                  helperText={errors.shipphone}
                  name="shipphone"
                  placeholder="Phone"
                  value={salesOrder.shipphone}
                  onChange={handleInputChange}
                  onBlur={handleOnBlurRequiredField("phone")}
                />
              </Box>
            </Box>
          </Card>
        </Box>
        <Box>
          <Card header={"Financial"} color="bg-green-200">
            <ComboBox
              required
              textFieldProps={{
                error: !!errors.class,
                helperText: errors.class,
              }}
              options={customerClasses}
              value={salesOrder.class}
              name="class"
              onChange={handleSelectChange("class")}
            >
              Class
            </ComboBox>
            <ComboBox 
              required
              textFieldProps={{
                error: !!errors.terms,
                helperText: errors.terms,
              }}
              options={terms}
              value={salesOrder.terms}
              name="terms"
              onChange={handleSelectChange("terms")}
            >
              Terms
            </ComboBox>
          </Card>
        </Box>
        <Box>
          <Box>
            <Card header={"Items"} color="bg-red-300">
              <LineItemForm
                setLineItems={setLineItems}
                lineItems={lineItems}
                allLineItems={allLineItems}
                errors={errors}
                priceLevel={customerPriceLevel}
              />
            </Card>
          </Box>
        </Box>
      </MainContainer>
      {showErrorAlertDialog && (
        <AlertDialog
          header="You're not done yet!"
          body={showErrorAlertDialog}
          open={!!showErrorAlertDialog}
          setShowAlertDialog={setShowErrorAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
      {showAlertDialog && (
        <AlertDialog
          header="Careful!"
          body={showAlertDialog}
          open={!!showAlertDialog}
          setShowAlertDialog={setShowAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
    </>
  );
};

export default SalesOrderForm;
