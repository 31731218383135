import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import usePasswordValidation from "../../hooks/usePasswordValidation";


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const PasswordValidationTooltip = ({ children, password, placement, ...props }) => {
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
  usePasswordValidation({
    password: password,
  });
  const Title = () => {
    return (
      <>
        <Typography
          variant="caption"
          display="block"
          color={validLength ? "green" : "error"}
        >
          {validLength ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <CloseIcon fontSize="inherit" />
          )}
          8 characters
        </Typography>
        <Typography
          variant="caption"
          display="block"
          color={hasNumber ? "green" : "error"}
        >
          {hasNumber ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <CloseIcon fontSize="inherit" />
          )}
          Number
        </Typography>
        <Typography
          variant="caption"
          display="block"
          color={upperCase ? "green" : "error"}
        >
          {upperCase ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <CloseIcon fontSize="inherit" />
          )}
          Uppercase
        </Typography>
        <Typography
          variant="caption"
          display="block"
          color={lowerCase ? "green" : "error"}
        >
          {lowerCase ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <CloseIcon fontSize="inherit" />
          )}
          Lowercase
        </Typography>
        <Typography
          variant="caption"
          display="block"
          color={specialChar ? "green" : "error"}
        >
          {specialChar ? (
            <CheckIcon fontSize="inherit" />
          ) : (
            <CloseIcon fontSize="inherit" />
          )}
          Special character
        </Typography>
      </>
    );
  };
  return (
    <LightTooltip title={<Title />} placement={placement} className="bg-white">
      {children}
    </LightTooltip>
  );
};
export default PasswordValidationTooltip;
