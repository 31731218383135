import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Typography from "@mui/material/Typography"


/**
 * Requires an endIcon prop to function correctly
 * @param {props.endIcon} - You need this to have the spinner render correctly
 * @param {props.loading}  - This is controlled by the state of the parent
 * @returns 
 */
const SubmitButton = ({ children, ...props }) => {

  return (
    <LoadingButton
      type="submit"
      size="small"
      variant="contained"
      color="primary"
      {...props}
    ><Typography variant="body1">{children}</Typography>
    </LoadingButton>
  )
}

export default SubmitButton