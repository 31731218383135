import React, { useEffect, useRef } from 'react'
import Input from './Input'
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined'
import useScript from '../../hooks/useScript'

const addressNameFormat = {
  'street_number': 'short_name',
  'route': 'long_name',
  'locality': 'long_name',
  'administrative_area_level_1': 'short_name',
  'country': 'short_name',
  'postal_code': 'short_name',
}


const AddressAutoCompleteForm = ({ label, disabled, onChange, value, updateFunction, idSuffix, ...props }) => {
  // Default the idSuffix prop to a unique value if none is provided 
  // This prevents collisions if multiple address forms are on the same page
  idSuffix = idSuffix || new Date().getTime() + Math.round(Math.random() * 100000)

  // Add google maps script to page
  const { loaded } = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
    scriptId: 'google-maps',
    globalVar: 'google',
  })

  const autoCompleteInput = useRef('')
  const autoComplete = useRef('')
  const sessionToken = useRef('')

  // Get ref to autocomplete input and setup Mutation Observer
  useEffect(() => {
    autoCompleteInput.current = document.getElementById(`location_${idSuffix}`)

    // This is required to keep google's autocomplete constructor from changing autocomplete to off
    const observerHack = new MutationObserver(() => {
      observerHack && observerHack.disconnect()
      autoCompleteInput.current.setAttribute('autocomplete', 'chrome-off')
    })
    observerHack.observe(autoCompleteInput.current, { attributes: true, attributeFilter: ['autocomplete'] })

    return () => {
      if (observerHack) observerHack.disconnect()
    }

  }, [idSuffix])

  // Whenever the google maps script is loaded, initialize the autoComplete field
  useEffect(() => {
    if (loaded && window.google) {
      // Create a new autocomplete session
      if (!sessionToken.current) {
        sessionToken.current = new window.google.maps.places.AutocompleteSessionToken()
      }
      autoComplete.current = new window.google.maps.places.Autocomplete(
        autoCompleteInput.current, {
        sessionToken: sessionToken.current,
        fields: ['address_components']
      })
    }

  }, [loaded])


  // Add place change event listener
  useEffect(() => {
    try {
      if (window.google && autoComplete.current) {

        // If you don't re-add this event listener every time state changes, then state will be stale when it runs
        // That means that those fields not controlled by google will be blank (addr2 and addressee)
        autoComplete.current.addListener('place_changed', () => {
          const place = autoComplete.current.getPlace()

          const getAddrComp = type => (
            place.address_components
              .find(({ types: [firstType] = [] }) => type === firstType) || {}
          )[addressNameFormat[type]] || ''

          onChange({
            addressee: value.addressee,
            addr2: value.addr2,
            addr1: `${getAddrComp('street_number')} ${getAddrComp('route')}`,
            city: getAddrComp('locality'),
            state: getAddrComp('administrative_area_level_1'),
            zip: getAddrComp('postal_code'),
            country: getAddrComp('country')
          })

        })
        // Clear event listener before unmount or re-render
        return () => {
          if (window.google) {
            window.google.maps.event.clearInstanceListeners(autoComplete.current)
          }
        }
      }

    } catch (e) {
      console.error(e)
    }
  }, [loaded, value.addressee, value.addr2, onChange])


  const handleChange = ({ target }) => onChange(prevState => ({ ...prevState, [target.name]: target.value }))


  return (
    <div title={disabled ? 'Uncheck "Same?" to update this address.' : ''}>
      <div className="relative" >
        {props.children}
        <div className="flex mb-2">
          <LocationOnIcon />
          <span >{label || 'Address Selection'}</span>
        </div>
        <Input onChange={handleChange} type="text" name={`addressee`} placeholder="Addressee" disabled={disabled} autoComplete="chrome-off" value={value.addressee} />
        <Input onChange={handleChange} type="text" name={`addr1`} placeholder="Address" id={`location_${idSuffix}`} disabled={disabled} autoComplete="chrome-off" value={value.addr1} />
        <Input onChange={handleChange} type="text" name={`addr2`} placeholder="Apt, Suite, etc (optional)" disabled={disabled} autoComplete="chrome-off" value={value.addr2} />
        <div className="flex">
          <div className="flex-1 mr-2">
            <Input onChange={handleChange} type="text" name={`city`} placeholder="City" disabled={disabled} autoComplete="chrome-off" value={value.city} />
          </div>
          <div className="flex-1">
            <Input onChange={handleChange} type="text" name={`state`} placeholder="State/Province" disabled={disabled} autoComplete="chrome-off" value={value.state} />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1 mr-2">
            <Input onChange={handleChange} type="text" name={`zip`} placeholder="Post Code" disabled={disabled} autoComplete="chrome-off" value={value.zip} />
          </div>
          <div className="flex-1">
            <Input onChange={handleChange} type="text" name={`country`} placeholder="Country" disabled={disabled} autoComplete="chrome-off" value={value.country}
            title='Country is always required to update any address. No address will be updated if country is left blank.'
            />
          </div>
        </div>

      </div>
    </div>
  )
}
export default AddressAutoCompleteForm