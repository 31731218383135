import React, { useState, useContext } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import SubmitButton from "../forms/SubmitButton";
import { ClickAwayListener } from "@mui/material";
import ComboBox from "../forms/ComboBox";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton } from "@mui/material";
import Input from "../forms/Input";
import { Box, Button } from "@mui/material";
import { AuthContext } from "../../services/Authentication";
import usePermissions from "../../hooks/usePermissions";
import DateRange from "../forms/DateRange";

const CommissionAdvancedSearch = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { state: authState, dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const permissions = usePermissions();

  // Set the search to the existing parameters, so they can adjust their previous search without putting everything in again
  const previousSearch = Object.fromEntries(
    new URLSearchParams(location.search)
  );

  const [search, setSearch] = useState({
    date: previousSearch.startdate
      ? [previousSearch?.startdate, previousSearch?.enddate]
      : [null, null],
    salesrep: previousSearch?.salesrep || "",
    supervisor: previousSearch?.supervisor || "",
    partner: previousSearch?.partner || "",
    csr: previousSearch?.csr || "",
    customer: previousSearch?.customer || "",
  });

  // Remove one of the dates so that the filter count will be correct
  if (previousSearch?.startdate && previousSearch?.enddate) {
    delete previousSearch.enddate;
  }
  const numFilters = Object.keys(previousSearch).length || 0;

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.value });

  const handleDateChange = (newValue) =>
    setSearch({ ...search, date: newValue });

  const handleSelectChange = (name) => (event, value) => {
    setSearch({ ...search, [name]: value?.abbreviation || value?.id || "" });
  };

  // Clear an individual input
  const handleClear = (name) => (e) => setSearch({ ...search, [name]: "" });

  // Clear entire search
  const handleClearAll = () => {
    setIsSearching(true);
    setSearch({ ...search, date: [null, null] });
    navigate("/commission");
    setIsSearching(false);
    setOpen(false);
  };

  const handleSearch = () => {
    setIsSearching(true);
    const searchCopy = JSON.parse(JSON.stringify(search));

    // Remove any blank parameters
    Object.keys(searchCopy).forEach((key, index) => {
      if (!searchCopy[key]) delete searchCopy[key];
    });
    if (!searchCopy.date[0]) delete searchCopy.date;

    // Format dates
    if (searchCopy?.date) {
      searchCopy.startdate = new Date(searchCopy.date[0]).toLocaleDateString();
      searchCopy.enddate = new Date(searchCopy.date[1]).toLocaleDateString();
    }

    // Remove things not needed for the api call
    delete searchCopy.date;

    // Update the url with the new search
    const urlParams = new URLSearchParams(searchCopy).toString();
    navigate({ pathname: "/commission/", search: `?${urlParams}` });
    setIsSearching(false);
    setOpen(false);
  };

  // Get metadata
  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };
  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/login" replace />;
  }

  let osrManager = search.salesrep || authState.user.netsuite_id;

  const filteredPartners =
    (!permissions.isAdmin && !permissions.canViewAllOSRCommission) ||
    search.salesrep
      ? metadata.partners.data.filter(
          (partner) => partner.manager == osrManager
        )
      : metadata.partners.data;

  const filteredSalesReps =
    !permissions.isAdmin && !permissions.canViewAllISRCommission
      ? metadata.salesreps.data.filter(
          (salesrep) =>
            salesrep.manager == authState.user.netsuite_id ||
            salesrep.id == authState.user.netsuite_id
        )
      : metadata.salesreps?.data;

  return (
    <Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="p-2 border-2">
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box className="flex justify-end">
                    <Button endIcon={<ClearIcon />} onClick={handleClearAll}>
                      Clear All
                    </Button>
                  </Box>
                  <DateRange
                    onChange={handleChange}
                    value={search.date}
                    setValue={handleDateChange}
                  />
                  {filteredSalesReps.length > 0 && (
                    <ComboBox
                      options={filteredSalesReps}
                      value={search.salesrep}
                      name="salesrep"
                      onChange={handleSelectChange("salesrep")}
                      disableClearable={false}
                    >
                      Inside Sales Rep
                    </ComboBox>
                  )}
                  {permissions.isAdmin && (
                    <ComboBox
                      options={metadata.salesreps?.data}
                      value={search.supervisor}
                      name="supervisor"
                      onChange={handleSelectChange("supervisor")}
                      disableClearable={false}
                    >
                      ISR Supervisor
                    </ComboBox>
                  )}
                  {filteredPartners.length > 0 && (
                    <ComboBox
                      options={filteredPartners}
                      value={search.partner}
                      name="partner"
                      onChange={handleSelectChange("partner")}
                      disableClearable={false}
                    >
                      Outside Sales Rep
                    </ComboBox>
                  )}

                  <ComboBox
                    options={metadata.customerServiceReps?.data}
                    value={search.csr}
                    name="csr"
                    onChange={handleSelectChange("csr")}
                    disableClearable={false}
                  >
                    Customer Service Rep
                  </ComboBox>

                  <Input
                    name={"customer"}
                    placeholder={"Customer"}
                    value={search.customer || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={handleClear("customer")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <SubmitButton onClick={handleSearch} loading={isSearching}>
                    Apply
                  </SubmitButton>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        startIcon={<FilterListIcon />}
        onClick={handleClick("bottom-end")}
      >
        Filters ({numFilters})
      </Button>
    </Box>
  );
};
export default CommissionAdvancedSearch;
