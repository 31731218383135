import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../pages/ErrorMessage";
import useFetch from "../../hooks/useFetch";

import { LicenseInfo } from "@mui/x-license-pro";
import { Tooltip } from "@mui/material";
import InternalLink from "../routing/InternalLink";
import SearchIcon from "@mui/icons-material/Search";
import { AuthContext } from "../../services/Authentication";
import usePermissions from "../../hooks/usePermissions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);
const columns = [
  { field: "id", headerName: "ID", hide: true },
  {
    renderCell,
    field: "altname",
    headerName: "Name",
    description: "Full Netsuite entity ID",
    minWidth: 120,
    flex: 1,
  },
  {
    renderCell,
    field: "daysoverdue",
    headerName: "Days Overdue",
    description: "Days Overdue in Netsuite",
    width: 120,
    type: "number",
    valueFormatter: (params) => {
      if (params.value == null) {
        return 0;
      }
      return parseInt(params.value);
    },
  },
  {
    field: "view",
    width: 18,
    renderCell: (params) => (
      <InternalLink to={`/customers/${params?.row?.id}`}>
        <SearchIcon />
      </InternalLink>
    ),
    renderHeader: (params) => <SearchIcon className="text-gray-400" />,
  },
];

const OverdueBalance = (props) => {
  const { state: authState } = useContext(AuthContext);
  const permissions = usePermissions();

  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const netsuiteColumns = JSON.stringify([
    { name: "daysoverdue", sort: "DESC" },
    "internalid",
    "altname",
  ]);
  const netsuiteFilters = ["AND", ["daysoverdue", "greaterthan", "0"]];
  // If a user is a salesrep and can't see all customers, only show them their own customers
  if (permissions.canViewAllCustomers && !permissions.isAdmin) {
    if (authState.user.user_type === "PARTNER") {
      netsuiteFilters.push("AND", [
        "partner",
        "is",
        authState.user.netsuite_id,
      ]);
    } else {
      netsuiteFilters.push("AND", [
        ["salesrep", "is", authState.user.netsuite_id],
        "OR",
        ["custentity_costco_sales_rep", "is", authState.user.netsuite_id],
        "OR",
        ["salesrep.supervisor", "is", authState.user.netsuite_id],
      ]);
    }
  }

  const {
    error,
    isLoading,
    data: json,
  } = useFetch({
    url: `${process.env.REACT_APP_BASE_URL}/api/customers/?params=${JSON.stringify(
      netsuiteFilters
    )}&results=${netsuiteColumns}`,
  });

  useEffect(() => {
    if (json) {
      const { result, data = [] } = json;

      if (result === "success") {
        setCustomers(
          data.map(
            ({
              altname,
              daysoverdue,
              internalid: [{ value: id } = {}] = [],
            }) => ({ id, altname, daysoverdue })
          )
        );
      }
    }
  }, [json]);

  if (error) return <ErrorMessage error={error} />;

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="flex justify-between">
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <DataGridPro
        rows={customers}
        disableSelectionOnClick={true}
        onRowDoubleClick={(e) => navigate(`/customers/${e.id}`)}
        columns={columns}
        loading={isLoading}
        density="standard"
        disableColumnMenu={true}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </>
  );
};

export default OverdueBalance;
