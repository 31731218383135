import { useEffect, useState, useContext, useRef } from 'react'
import { AuthContext } from '../services/Authentication'

const safeJsonParse = str => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return null
  }
}

/**
 *! The options param must be placed into a react ref to avoid
 *! a JS object being considered a dependency. Due to how react
 *! compares objects, this will result in an infinite fetch loop
 */
const useFetch = ({ url = '', options = {}, isJson = true, test = false }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const { dispatch } = useContext(AuthContext)

  const optionsRef = useRef(options)
  const { method, body, headers } = optionsRef.current 

  useEffect(() => {
    let controller = new AbortController();

    (async () => {
      try {
        if(test) return
        
        setIsLoading(true)
        const res = await fetch(url, { ...optionsRef.current, credentials: 'include', signal: controller.signal })

        // 401 Unauthorized errors mean the JWT token is stale and re-login is required
        if (res.status === 401) dispatch({ type: "LOGOUT" })

        if (!res.ok) throw res

        const text = await res.text()
        if (isJson) {
          const json = safeJsonParse(text)
          if (json.error) throw json.error
          setData(json)
          setIsLoading(false)
        } else {
          setData(text)
        }
      } catch (e) {
        // Ignore abort errors from clean-up callback
        if (e instanceof DOMException) return
        setError(e)
        console.error(e)
        setIsLoading(false)
      } finally {
        controller = null
      }
    })()

    // Cancel the pending fetch request on un-mount
    return () => controller?.abort()

  }, [url, isJson, dispatch, optionsRef, method, body, headers, test])

  return { isLoading, error, data }
}

export default useFetch