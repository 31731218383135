import React, { useEffect } from "react"
import Input from "./Input"
import { Box, IconButton } from "@mui/material"
import AddIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import UndoIcon from '@mui/icons-material/Undo';


const ContactInput = ({ name, placeholder, className, ...props }) => (
  <Input
    className={`w-full px-1 ${className}`}
    name={name}
    placeholder={placeholder || name[0].toUpperCase() + name.substring(1)}
    {...props}
  />
)

export default function ContactForm({ data, contacts = [], setContacts, ...props }) {
  const newContact = {
    entityid: "",
    title: "",
    email: "",
    phone: "",
    markForDelete: false
  }

  useEffect(() => {
    if (!contacts.length) setContacts([{ ...newContact }])
  })

  const createContact = () => setContacts([...contacts, { ...newContact }])

  const handleOnChange = index => e => {
    const tempContacts = [...contacts]
    tempContacts[index][e.currentTarget.name] = e.currentTarget.value
    setContacts(tempContacts)
  }

  const handleRemove = index => () => {
    const tempContacts = [...contacts]
    const contactToDelete = tempContacts[index]
    if (contactToDelete.internalid) {
      tempContacts[index].markForDelete = true
    }
    else {
      tempContacts.splice(index, 1)
    }

    setContacts(tempContacts)
  }

  const handleUndoDelete = index => () => {
    const tempContacts = [...contacts]
    tempContacts[index].markForDelete = false
    setContacts(tempContacts)
  }

  return (
    <>
      {
        contacts.map((contact, index) => (
          <Box className='w-full flex items-center' key={contact.internalid || index}>
            <Box 
            className="grid grid-cols-2 lg:grid-cols-4 flex-1"
            >

              <ContactInput
                onChange={handleOnChange(index)}
                value={contact.entityid}
                name="entityid"
                placeholder='Name'
                className={contact.markForDelete ? 'line-through' : ''}
                disabled={contact.markForDelete}
              />
              <ContactInput
                onChange={handleOnChange(index)}
                value={contact.title}
                name="title"
                className={contact.markForDelete ? 'line-through' : ''}
                disabled={contact.markForDelete}
              />
              <ContactInput
                onChange={handleOnChange(index)}
                value={contact.email}
                name="email"
                className={contact.markForDelete ? 'line-through' : ''}
                disabled={contact.markForDelete}
              />
              <ContactInput
                onChange={handleOnChange(index)}
                value={contact.phone}
                name="phone"
                className={contact.markForDelete ? 'line-through' : ''}
                disabled={contact.markForDelete}
              />
            </Box>

            {
              contact.markForDelete ?
                <Box className="flex-shrink" title="Undo delete">
                  <IconButton onClick={handleUndoDelete(index)} color="success" className='float-right'>
                    <UndoIcon className='-mt-2 mb-1' fontSize="large" />
                  </IconButton>
                </Box>
                :
                <Box className="flex-shrink" title="Remove row">
                  <IconButton onClick={handleRemove(index)} color="error" className='float-right'>
                    <RemoveCircleIcon className='-mt-2 mb-1' fontSize="large" />
                  </IconButton>
                </Box>
            }

          </Box>
        ))
      }

      <Box className='w-full'>
        <IconButton onClick={createContact} className='float-right' color='success'>
          <AddIcon fontSize='large' />
        </IconButton>
      </Box>

    </>
  )
}
