import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ComboBox from "./ComboBox";
import useFetch from "../../hooks/useFetch";
import EclipseSpinner from "../EclipseSpinner";
import { Typography } from "@mui/material";
import { inventoryLevels, commissionTypes } from "../constants";
import MultipleSelect from "./MultipleSelect";

const Permissions = ({
  // permissions is an array of permissions from the role or user
  userOrRole,
  setUserOrRole,
  readOnly,
  ...props
}) => {
  const [permissionLevels, setPermissionLevels] = useState([]);

  // Get permission levels
  const { isLoadingPermissionLevels, data } = useFetch({
    url: `${process.env.REACT_APP_BASE_URL}/api/roles/permissionlevels`,
  });
  useEffect(() => {
    if (data) {
      const { result, permission_levels: permissionLevels = [] } = data;
      if (result === "success") {
        setPermissionLevels(permissionLevels);
      }
    }
  }, [data]);

  const handlePermissionChange = (permissionLevelName) => (event, value) => {
    // Make sure that own entity permission levels are never lower than all entity permission levels
    // And that all entity permission levels are never higher than own entity permission levels
    if (
      (permissionLevelName === "all_customers_permission_level" &&
        value.id > userOrRole.own_customers_permission_level) ||
      (permissionLevelName === "own_customers_permission_level" &&
        value.id < userOrRole.all_customers_permission_level)
    )
      setUserOrRole({
        ...userOrRole,
        all_customers_permission_level: value?.id || 1,
        own_customers_permission_level: value?.id || 1,
      });
    else if (
      (permissionLevelName === "all_sales_orders_permission_level" &&
        value.id > userOrRole.own_sales_orders_permission_level) ||
      (permissionLevelName === "own_sales_orders_permission_level" &&
        value.id < userOrRole.all_sales_orders_permission_level)
    )
      setUserOrRole({
        ...userOrRole,
        all_sales_orders_permission_level: value?.id || 1,
        own_sales_orders_permission_level: value?.id || 1,
      });
    else if (
      (permissionLevelName === "all_invoices_permission_level" &&
        value.id > userOrRole.own_invoices_permission_level) ||
      (permissionLevelName === "own_invoices_permission_level" &&
        value.id < userOrRole.all_invoices_permission_level)
    )
      setUserOrRole({
        ...userOrRole,
        all_invoices_permission_level: value?.id || 1,
        own_invoices_permission_level: value?.id || 1,
      });
    else if (
      (permissionLevelName === "all_users_permission_level" &&
        value.id > userOrRole.own_user_permission_level) ||
      (permissionLevelName === "own_user_permission_level" &&
        value.id < userOrRole.all_users_permission_level)
    )
      setUserOrRole({
        ...userOrRole,
        all_users_permission_level: value?.id || 1,
        own_user_permission_level: value?.id || 1,
      });
    else
      setUserOrRole({ ...userOrRole, [permissionLevelName]: value?.id || 1 });
  };

  const handleMultipleSelectChange = (permissionName) => (event, values) => {
    // Make sure that the all commission permissions doesn't have commission types not in the own commission permissions
    if (permissionName === "own_commission_permissions") {
      const allCommissionPermissions = userOrRole.all_commission_permissions.filter(
        (commissionType) => values.includes(commissionType)
      );
      setUserOrRole({
        ...userOrRole,
        own_commission_permissions: values,
        all_commission_permissions: allCommissionPermissions, 
      });
    } else if (permissionName === "all_commission_permissions") {
      let differenceForAllCommissionPermissions = values.filter(
        (commissionType) =>
          !userOrRole.own_commission_permissions.includes(commissionType)
      );
      setUserOrRole((prevUserOrRole) => ({
        ...prevUserOrRole,
        own_commission_permissions:
          differenceForAllCommissionPermissions.concat(prevUserOrRole.own_commission_permissions),
        all_commission_permissions: values,
      }));
    }
  };

  if (isLoadingPermissionLevels) return <EclipseSpinner />;
  return (
    <>
      <>
        <Box className="text-right grid grid-cols-4 gap-2">
          <Typography>Customers</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <ComboBox
              options={permissionLevels}
              value={userOrRole.own_customers_permission_level}
              name="ownCustomersPermissionLevel"
              onChange={handlePermissionChange(
                "own_customers_permission_level"
              )}
              disabled={readOnly}
            >
              Own Customers
            </ComboBox>
            <ComboBox
              options={permissionLevels}
              value={userOrRole.all_customers_permission_level}
              name="allCustomersPermissionLevel"
              onChange={handlePermissionChange(
                "all_customers_permission_level"
              )}
              disabled={readOnly}
            >
              All Customers
            </ComboBox>
          </Box>
          <Typography>Sales Orders</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <ComboBox
              options={permissionLevels}
              value={userOrRole.own_sales_orders_permission_level}
              name="ownSalesOrdersPermissionLevel"
              onChange={handlePermissionChange(
                "own_sales_orders_permission_level"
              )}
              disabled={readOnly}
            >
              Own Sales Orders
            </ComboBox>
            <ComboBox
              options={permissionLevels}
              value={userOrRole.all_sales_orders_permission_level}
              name="AllSalesOrdersPermissionLevel"
              onChange={handlePermissionChange(
                "all_sales_orders_permission_level"
              )}
              disabled={readOnly}
            >
              All Sales Orders
            </ComboBox>
          </Box>
          <Typography>Invoices</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <ComboBox
              options={permissionLevels}
              value={userOrRole.own_invoices_permission_level}
              name="ownInvoicesPermissionLevel"
              onChange={handlePermissionChange("own_invoices_permission_level")}
              disabled={readOnly}
            >
              Own Invoices
            </ComboBox>
            <ComboBox
              options={permissionLevels}
              value={userOrRole.all_invoices_permission_level}
              name="allInvoicesPermissionLevel"
              onChange={handlePermissionChange("all_invoices_permission_level")}
              disabled={readOnly}
            >
              All Invoices
            </ComboBox>
          </Box>
          <Typography>User Accounts</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <ComboBox
              options={permissionLevels}
              value={userOrRole.own_user_permission_level}
              name="ownUserPermissionLevel"
              onChange={handlePermissionChange("own_user_permission_level")}
              disabled={readOnly}
            >
              Own User Account
            </ComboBox>
            <ComboBox
              options={permissionLevels}
              value={userOrRole.all_users_permission_level}
              name="allUsersPermissionLevel"
              onChange={handlePermissionChange("all_users_permission_level")}
              disabled={readOnly}
            >
              All User Accounts
            </ComboBox>
          </Box>
          <Typography>Inventory</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <ComboBox
              options={inventoryLevels}
              value={userOrRole.inventory_permission_level}
              name="inventoryPermissionLevel"
              onChange={handlePermissionChange("inventory_permission_level")}
              disabled={readOnly}
            >
              Inventory
            </ComboBox>
          </Box>
          <Typography>Commission</Typography>
          <Box className="col-span-3 grid grid-cols-2 gap-2">
            <MultipleSelect
              options={commissionTypes}
              disabled={readOnly}
              id="ownCommissionPermissionLevel"
              onChange={handleMultipleSelectChange(
                "own_commission_permissions"
              )}
              value={userOrRole.own_commission_permissions}
            >
              Own Transactions
            </MultipleSelect>
            <MultipleSelect
              options={commissionTypes}
              disabled={readOnly}
              id="allCommissionPermissionLevel"
              onChange={handleMultipleSelectChange(
                "all_commission_permissions"
              )}
              value={userOrRole.all_commission_permissions}
            >
              All Transactions
            </MultipleSelect>
          </Box>
        </Box>
      </>
    </>
  );
};

export default Permissions;
