import React from 'react'
import { useLocation } from "react-router"
import MainContainer from "../layout/MainContainer"

const NotFound404 = props => {
  let location = useLocation()

  window.document.title = "Not Found | Suite Sales"

  return (
    <MainContainer>
      <h2>Whoops!</h2>
      <h3>Unable to find <code>{location.pathname}</code></h3>
    </MainContainer>
  )
}

export default NotFound404