import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import { Tooltip, Box } from "@mui/material";
import Header from "../layout/Header";
import CommissionAdvancedSearch from "./CommissionAdvancedSearch";
import FullPageGridContainer from "../layout/FullPageGridContainer";
import useFetch from "../../hooks/useFetch";
import ErrorMessage from "../pages/ErrorMessage";
import InternalLink from "../routing/InternalLink";
import { AuthContext } from "../../services/Authentication";
import usePermissions from "../../hooks/usePermissions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdPrice = {
  type: "number",
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

const percentageFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const percentage = {
  type: "number",
  valueFormatter: ({ value }) =>
    percentageFormatter.format(Number(value.slice(0, -1) / 100)),
};

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-between">
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Box>
      <CommissionAdvancedSearch />
    </GridToolbarContainer>
  );
};

const CustomFooter = (props) => {
  return (
    <Box
      sx={{ padding: "16px", display: "flex", flexDirection: "row-reverse" }}
    >
      {props.totalCommissionString}
    </Box>
  );
};

const CommissionReport = (props) => {
  const [sales, setSales] = useState([]);
  const location = useLocation();
  const params = Object.fromEntries(new URLSearchParams(location.search));
  const { state: authState } = useContext(AuthContext);
  const permissions = usePermissions();
  const [totalCommissionString, setTotalCommissionString] = useState("");

  const netsuiteColumns = [
    "closedate",
    "trandate",
    "tranid",
    "internalid",
    "entity",
    "quantity",
    "item",
    "memo",
    "amount",
    "partner",
    "salesrep",
    "custbody_customer_service_rep",
  ];

  const columns = [
    { field: "id", headerName: "id", hide: true },
    { field: "closedate", headerName: "Date Closed", minWidth: 100 },
    {
      field: "document_number",
      headerName: "Document Number",
      minWidth: 100,
      renderCell: (params) =>
        // Link to the invoice detail page if the type is invoice
        /^INV[0-9]+$/i.test(params?.row?.document_number) ? (
          <InternalLink to={`/invoices/${params?.row?.internalid}`}>
            {params?.row?.document_number}
          </InternalLink>
        ) : (
          params?.row?.document_number
        ),
    },
    {
      renderCell,
      field: "customer",
      headerName: "Customer",
      description: "",
      minWidth: 50,
      flex: 1,
    },
    { field: "item", headerName: "Item", minWidth: 50 },
    { field: "quantity", headerName: "Quantity", minWidth: 50, type: "number" },
    {
      renderCell,
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Amount",
      description: "",
      minWidth: 50,
      ...usdPrice,
    },
  ];

  // Add ISR Supervisor Column
  if (permissions.canViewAllISRCommission) {
    columns.push({
      renderCell,
      field: "isr_supervisor",
      headerName: "ISR Supervisor",
      minWidth: 50,
    });

    netsuiteColumns.push("salesrep.supervisor");
  }

  // Add reps
  columns.push(
    {
      renderCell,
      field: "isr",
      headerName: "ISR",
      description: "Inside sales rep in Netsuite",
      minWidth: 50,
    },
    {
      renderCell,
      field: "osr",
      headerName: "OSR",
      description: "Outside sales rep in Netsuite",
      minWidth: 50,
    },
    {
      renderCell,
      field: "csr",
      headerName: "CSR",
      description: "Customer service rep in Netsuite",
      minWidth: 50,
    }
  );

  if (permissions.canViewAnyISRCommission) {
    // Add the ISR datagrid columns
    columns.push(
      {
        field: "isr_rate",
        headerName: "ISR Rate",
        description: "ISR rate based on whether this is a first-year customer",
        minWidth: 50,
        ...percentage,
      },
      {
        field: "isr_commission",
        headerName: "ISR Commission",
        minWidth: 50,
        ...usdPrice,
      }
    );

    // Create the formulas for the ISR NetSuite columns
    let isrFirstYearRateFormula =
      "CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_year_1_commission},0) END";
    let isrStandardRateFormula =
      "CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_std_commission},0) END";
    let isrCommissionFormula =
      "CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({salesrep.custentity_commission_rate},0) WHEN ((sysdate-{customer.datecreated} <= 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_year_1_commission},0) WHEN ((sysdate-{customer.datecreated} > 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_std_commission},0) ELSE {amount}*NVL({item.custitem_csr_std_commission},0) END";
    let firstYearFormula =
      "CASE WHEN (sysdate-{customer.datecreated}) <= 366 THEN 'T' ELSE 'F' END";
    if (
      permissions.canViewOwnISRCommission &&
      !permissions.canViewAllISRCommission
    ) {
      if (authState.user.user_type === "PARTNER") {
        // Use a case when on each NetSuite column to check if the user is the partner for that transaction
        // If the user isn't the partner - don't return the data
        isrFirstYearRateFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_year_1_commission},0) END ELSE NULL END`;
        isrStandardRateFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_std_commission},0) END ELSE NULL END`;
        isrCommissionFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({salesrep.custentity_commission_rate},0) WHEN ((sysdate-{customer.datecreated} <= 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_year_1_commission},0) WHEN ((sysdate-{customer.datecreated} > 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_std_commission},0) ELSE {amount}*NVL({item.custitem_csr_std_commission},0) END) ELSE NULL END`;
        firstYearFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN (sysdate-{customer.datecreated}) <= 366 THEN 'T' ELSE 'F' END) ELSE NULL END`;
      } else {
        // Use a case when on each NetSuite column to check if the user is the sales rep for that transaction
        // If the user isn't the sales rep - don't return the data
        isrFirstYearRateFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_year_1_commission},0) END ELSE NULL END`;
        isrStandardRateFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN NVL({salesrep.custentity_commission_rate},0) ELSE NVL({item.custitem_sales_rep_std_commission},0) END ELSE NULL END`;
        isrCommissionFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {salesrep.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({salesrep.custentity_commission_rate},0) WHEN ((sysdate-{customer.datecreated} <= 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_year_1_commission},0) WHEN ((sysdate-{customer.datecreated} > 366) AND {partner} IS NULL) THEN {amount}*NVL({item.custitem_sales_rep_std_commission},0) ELSE {amount}*NVL({item.custitem_csr_std_commission},0) END) ELSE NULL END`;
        firstYearFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN (sysdate-{customer.datecreated}) <= 366 THEN 'T' ELSE 'F' END) ELSE NULL END`;
      }
    }
    // Add the ISR NetSuite columns
    netsuiteColumns.push(
      {
        // ISR 1st Year Rate
        name: "formulapercent",
        formula: isrFirstYearRateFormula,
      },
      {
        // ISR Std Rate
        name: "formulapercent_1",
        formula: isrStandardRateFormula,
      },
      {
        // ISR Commission
        name: "formulacurrency",
        formula: isrCommissionFormula,
      },
      {
        // First Year?
        name: "formulatext",
        formula: firstYearFormula,
      }
    );
  }

  if (permissions.canViewAnyOSRCommission) {
    // Add the OSR columns to the data grid
    columns.push(
      {
        field: "osr_rate",
        headerName: "OSR Rate",
        description: "OSR rate in Netsuite",
        minWidth: 50,
        ...percentage,
      },
      {
        field: "osr_commission",
        headerName: "OSR Commission",
        minWidth: 50,
        ...usdPrice,
      }
    );

    // Create the formulas for the OSR NetSuite Columns
    let osrRateFormula =
      "CASE WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN NVL({partner.custentity_commission_rate},0) ELSE NVL({item.custitem_outside_sales_rep_commission},0) END";
    let osrCommissionFormula =
      "CASE WHEN {partner} IS NULL THEN 0 WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({partner.custentity_commission_rate},0) ELSE {amount}*NVL({item.custitem_outside_sales_rep_commission},0) END";
    if (
      permissions.canViewOwnOSRCommission &&
      !permissions.canViewAllOSRCommission
    ) {
      if (authState.user.user_type === "PARTNER") {
        osrRateFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN NVL({partner.custentity_commission_rate},0) ELSE NVL({item.custitem_outside_sales_rep_commission},0) END) ELSE NULL END`;
        osrCommissionFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {partner} IS NULL THEN 0 WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({partner.custentity_commission_rate},0) ELSE {amount}*NVL({item.custitem_outside_sales_rep_commission},0) END) ELSE NULL END`;
      } else {
        osrRateFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN NVL({partner.custentity_commission_rate},0) ELSE NVL({item.custitem_outside_sales_rep_commission},0) END) ELSE NULL END`;
        osrCommissionFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {partner} IS NULL THEN 0 WHEN {partner.custentity_has_flat_rate_commission} = 'T' THEN {amount}*NVL({partner.custentity_commission_rate},0) ELSE {amount}*NVL({item.custitem_outside_sales_rep_commission},0) END) ELSE NULL END`;
      }
    }
    // Add the OSR NetSuite columns
    netsuiteColumns.push(
      {
        // OSR Rate
        name: "formulapercent_2",
        formula: osrRateFormula,
      },
      {
        // OSR Commission
        name: "formulacurrency_1",
        formula: osrCommissionFormula,
      }
    );
  }
  if (permissions.canViewAnyCSRCommission) {
    // Add the CSR columns to the data grid
    columns.push(
      {
        field: "csr_rate",
        headerName: "CSR Rate",
        description: "CSR rate in Netsuite",
        minWidth: 50,
        ...percentage,
      },

      {
        field: "csr_commission",
        headerName: "CSR Commission",
        minWidth: 50,
        ...usdPrice,
      }
    );
    // Create the formulas for the CSR NetSuite columns
    let csrRateFormula = "NVL({item.custitem_csr_std_commission},0)";
    let csrCommissionFormula =
      "CASE WHEN {customer.custentity_customer_service_rep} IS NOT NULL THEN {amount}*{item.custitem_csr_std_commission} ELSE 0 END";
    if (
      permissions.canViewOwnCSRCommission &&
      !permissions.canViewAllCSRCommission
    ) {
      if (authState.user.user_type === "PARTNER") {
        csrRateFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN NVL({item.custitem_csr_std_commission},0) ELSE NULL END`;
        csrCommissionFormula = `CASE WHEN {partner.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {customer.custentity_customer_service_rep} IS NOT NULL THEN {amount}*{item.custitem_csr_std_commission} ELSE 0 END) ELSE NULL END`;
      } else {
        csrRateFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN NVL({item.custitem_csr_std_commission},0) ELSE NULL END`;
        csrCommissionFormula = `CASE WHEN {salesrep.internalid} = ${authState.user.netsuite_id} THEN (CASE WHEN {customer.custentity_customer_service_rep} IS NOT NULL THEN {amount}*{item.custitem_csr_std_commission} ELSE 0 END) ELSE NULL END`;
      }
    }

    // Add the CSR NetSuite columns
    netsuiteColumns.push(
      {
        // CSR Rate
        name: "formulapercent_3",
        formula: csrRateFormula,
      },

      {
        // CSR Commission
        name: "formulacurrency_2",
        formula: csrCommissionFormula,
      }
    );
  }

  // Set filters for netsuite api
  const netsuiteFilters = [];
  if (params.salesrep)
    netsuiteFilters.push("AND", ["salesrep", "is", params.salesrep]);
  if (params?.supervisor)
    netsuiteFilters.push("AND", [
      "salesrep.supervisor",
      "is",
      params.supervisor,
    ]);
  if (params.partner)
    netsuiteFilters.push("AND", ["partner", "is", params.partner]);
  if (params.csr)
    netsuiteFilters.push("AND", [
      "customer.custentity_customer_service_rep",
      "is",
      params.csr,
    ]);
  if (params.customer)
    netsuiteFilters.push("AND", [
      "customer.altname",
      "contains",
      params.customer,
    ]);
  const invoiceFilters = [...netsuiteFilters];
  const creditMemoFilters = [...netsuiteFilters];
  if (params.startdate && params.enddate) {
    invoiceFilters.push("AND", [
      "formuladate: CASE WHEN {type}='Invoice' THEN {closedate} ELSE {trandate} END",
      "within",
      params.startdate,
      params.enddate,
    ]);
    creditMemoFilters.push("AND", [
      "systemnotes.date",
      "within",
      params.startdate,
      params.enddate,
    ]);
  }

  // Set the url
  let url = `${process.env.REACT_APP_BASE_URL}/api/reports/commission/?results=${JSON.stringify(
    netsuiteColumns
  )}`;
  if (netsuiteFilters) {
    url += `&invoiceParams=${JSON.stringify(
      invoiceFilters
    )}&creditMemoParams=${JSON.stringify(creditMemoFilters)}`;
  }

  // Get the data
  const { isLoading, error, data: json } = useFetch({ url });

  useEffect(() => {
    if (json) {
      const {
        invoices: { result: invoiceResult, data: invoiceData = [] },
        creditMemos: { result: creditMemoResult, data: creditMemoData = [] },
      } = json;

      if (invoiceResult === "success" && creditMemoResult === "success") {
        const data = [...invoiceData, ...creditMemoData];
        console.log(data);
        setSales(
          data.map(
            (
              {
                internalid: [{ value: internalid } = {}] = [],
                entity: [{ text: customer } = {}] = [],
                closedate,
                tranid: document_number,
                quantity,
                item: [{ text: item } = {}] = [],
                memo: description,
                amount,
                custbody_customer_service_rep: [{ text: csr } = {}] = [],
                partner: [{ text: osr } = {}] = [],
                salesrep: [{ text: isr } = {}] = [],
                "salesrep.supervisor": [{ text: isr_supervisor } = {}] = [],
                formulatext: first_year,
                formulapercent: year_1_isr_rate,
                formulapercent_1: standard_isr_rate,
                formulapercent_2: osr_rate,
                formulapercent_3: csr_rate,
                formulacurrency: isr_commission,
                formulacurrency_1: osr_commission,
                formulacurrency_2: csr_commission,
                trandate,
              },
              index
            ) => ({
              id: index,
              internalid,
              quantity,
              description,
              document_number,
              customer,
              csr,
              osr,
              isr,
              isr_supervisor,
              isr_rate:
                first_year === "T" ? year_1_isr_rate : standard_isr_rate,
              osr_rate,
              csr_rate,
              isr_commission: `${isr_commission || "0.00"}`,
              osr_commission: `${osr_commission || "0.00"}`,
              csr_commission: `${csr_commission || "0.00"}`,
              closedate: closedate ? closedate : trandate,
              amount: `${amount || "0.00"}`,
              item,
            })
          )
        );

        // Sum the commission for the total in the footer
        let commissionString = "";
        if (permissions.canViewAnyISRCommission) {
          const isrTotalCommission = data
            .reduce((sum, sale) => {
              return sum + (parseFloat(sale["formulacurrency"]) || 0);
            }, 0)
            .toFixed(2);
          commissionString += `Total ISR Commission: $${isrTotalCommission}`;
        }
        if (permissions.canViewAnyOSRCommission) {
          commissionString += " | ";
        }
        if (permissions.canViewAnyOSRCommission) {
          const osrTotalCommission = data
            .reduce((sum, sale) => {
              return sum + (parseFloat(sale["formulacurrency_1"]) || 0);
            }, 0)
            .toFixed(2);
          commissionString += `Total OSR Commission: $${osrTotalCommission} \t`;
        }
        if (permissions.canViewAnyCSRCommission) commissionString += " | ";
        if (permissions.canViewAnyCSRCommission) {
          const csrTotalCommission = data
            .reduce((sum, sale) => {
              return sum + parseFloat(sale["formulacurrency_2"] || 0);
            }, 0)
            .toFixed(2);
          commissionString += `Total CSR Commission: $${csrTotalCommission}`;
        }

        setTotalCommissionString(commissionString);
      }
    }
  }, [
    json,
    authState,
    permissions.canViewAnyCSRCommission,
    permissions.canViewAnyISRCommission,
    permissions.canViewAnyOSRCommission,
  ]);

  window.document.title = "Commission Report | Suite Sales";
  console.log(sales);

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Header header="Commission" className="justify-between" />
      <FullPageGridContainer>
        <DataGridPro
          rows={sales}
          disableSelectionOnClick={true}
          columns={columns}
          // autoHeight={true}
          loading={isLoading}
          density="standard"
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          componentsProps={{
            footer: { totalCommissionString },
          }}
        />
      </FullPageGridContainer>{" "}
    </>
  );
};

export default CommissionReport;
