import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const WarningBanner = ({ severity, title, message }) => {
  return (
    <Alert severity={severity}>
        <AlertTitle sx={{ fontWeight: 'bold' }}>{title}</AlertTitle>
        {message}
    </Alert>
  );
};

export default WarningBanner;