import React from 'react'
import { Typography } from "@mui/material";
import MainContainer from '../layout/MainContainer'

export default function ErrorMessage({ error, ...props }) {

  window.document.title = "Error | Suite Sales"

  return (
    <MainContainer>
      <Typography color='error' variant='h6'>{`${error.name || error.status} - ${error.message || error.statusText}`}</Typography>
    </MainContainer>
  )
}