import React, { useState, forwardRef } from "react"
import Input from "./Input"

import InputAdornment from "@mui/material/InputAdornment"
import IconButton from '@mui/material/IconButton'

import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

const PasswordInput = forwardRef(({children, ...props}, ref) => {
  const [state, setState] = useState({ showPassword: false })

  return (
    <Input
      ref={ref}
      id="password"
      placeholder="Password"
      type={state.showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <IconButton
            edge="end"
            tabIndex={-1}
            onClick={() => setState({ ...state, showPassword: !state.showPassword })}
          >
            {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
        </InputAdornment>
      }}
      {...props}
    >
      {children}
    </Input>
)
    })


export default PasswordInput