import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { AuthContext } from "../../../services/Authentication";
import Input from "../../forms/Input";
import PasswordValidationTooltip from "../../forms/PasswordValidationTooltip.jsx";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  ButtonGroup,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import EclipseSpinner from "../../EclipseSpinner";
import ComboBox from "../../forms/ComboBox";
import PasswordInput from "../../forms/PasswordInput";
import Header from "../../layout/Header";
import { Typography } from "@mui/material";
import SubmitButton from "../../forms/SubmitButton";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import toast from "react-hot-toast";
import AlertDialog from "../../forms/AlertDialog";
import usePasswordValidation from "../../../hooks/usePasswordValidation";
import usePermissions from "../../../hooks/usePermissions";
import {
  customerClasses,
  defaultLocations,
  priceLevels,
  terms,
  commissionTypes,
} from "../../constants";
import RolesAndPermissions from "./RolesAndPermissions";
import { useCallback } from "react";

const UserForm = ({ createNewUser, ...props }) => {
  // Default id of 0 is used when user is creating a new user rather than editing one
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { dispatch, state: authState } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState({});
  const [helpers, setHelpers] = useState({});
  const [showErrorAlertDialog, setShowErrorAlertDialog] = useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
  const [showEmailAlertDialog, setShowEmailAlertDialog] = useState(false);
  // blankUserState used for creating a new user
  const blankUserState = useRef({
    firstname: "",
    lastname: "",
    companyname: "",
    netsuite_id: "",
    default_class: 1,
    default_category: 7,
    default_terms: 2,
    default_pricelevel: 13,
    default_location: 1,
    default_exemptax_status: false,
    email: "",
    password: "",
    user_type: "INTERNAL",
    is_sales_rep: true,
    is_costco_rep: false,
    create_in_netsuite: false,
    is_person: true,
    manager_id: null,
    role_id: 3,
    role_name: "Sales Rep",
    default_role_permissions: true,
    own_customers_permission_level: 1,
    all_customers_permission_level: 1,
    own_sales_orders_permission_level: 1,
    all_sales_orders_permission_level: 1,
    own_invoices_permission_level: 1,
    all_invoices_permission_level: 1,
    own_user_permission_level: 1,
    all_users_permission_level: 1,
    inventory_permission_level: 1,
    own_commission_permissions: [],
    all_commission_permissions: [],
  });
  const [profile, setProfile] = useState(blankUserState.current);
  // Validate user's password
  const [validLength, hasNumber, upperCase, lowerCase, specialChar] =
    usePasswordValidation({
      password: profile.password,
    });
  const validPassword =
    validLength && hasNumber && upperCase && lowerCase && specialChar;

  const [emailData, setEmailData] = useState({
    email: profile.email || "",
    isSendingEmail: false,
    error: null,
    message: "",
  });

  console.log(profile);

  // PERMISSIONS //
  // based on whether the user is editing their own profile and their permission levels
  const editingOwnProfile =
    (profile.id === authState.user.id &&
      !createNewUser &&
      permissions.canEditOwnUser) ||
    false;
  // All fields should be disabled if they're viewing someone else's profile that they can't edit, or if they're viewing their own profile and can't edit
  const readOnly =
    !createNewUser &&
    ((profile.id === authState.user.id && !permissions.canEditOwnUser) ||
      (profile.id !== authState.user.id && !permissions.canEditAllUsers));
  // Some fields should not be changed by the user themselves if they are not an admin
  const limitedAccess =
    (!createNewUser && !permissions.canEditAllUsers) ||
    (createNewUser && !permissions.canCreateAllUsers);

  // Get user (id is 0 if creating a new user)
  const {
    isLoading,
    error,
    data: user,
  } = useFetch({ url: `${process.env.REACT_APP_BASE_URL}/api/users/${id}`, test: !id });

  // set profile's inventory permission level
  // (all items and lion items permissions are stored separately in the database in case we add more functionality later,
  // but right now inventory is read-only, so they show up in the same dropdown on the page)
  // This is a callback function so that it can be a useEffect dependency
  const getInventoryPermissionLevel = useCallback(
    (allItemsPermissionLevel, lionItemsPermissionLevel) => {
      let inventoryPermissionLevel = 1;
      if (allItemsPermissionLevel >= 2) {
        inventoryPermissionLevel = 3;
      } else if (lionItemsPermissionLevel >= 2) {
        inventoryPermissionLevel = 2;
      }
      return inventoryPermissionLevel;
    },
    []
  );

  // set user's commission permissions for own transactions
  // (The multiple select input requires an array)
  // This is a callback function so that it can be a useEffect dependency
  const getOwnCommissionPermissions = useCallback(
    (ownISRPermissionLevel, ownOSRPermissionLevel, ownCSRPermissionLevel) => {
      let ownCommissionPermissions = [];
      if (ownISRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[0]);
      }
      if (ownOSRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[1]);
      }
      if (ownCSRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[2]);
      }
      return ownCommissionPermissions;
    },
    []
  );

  // set user's commission permissions for all transactions
  // (The multiple select input requires an array)
  // This is a callback function so that it can be a useEffect dependency
  const getAllCommissionPermissions = useCallback(
    (allISRPermissionLevel, allOSRPermissionLevel, allCSRPermissionLevel) => {
      let allCommissionPermissions = [];
      if (allISRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[0]);
      }
      if (allOSRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[1]);
      }
      if (allCSRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[2]);
      }
      return allCommissionPermissions;
    },
    []
  );

  // This sets the fields to blank if the user goes from editing a user straight to creating a new user
  useEffect(() => {
    if (id === 0) {
      setProfile(blankUserState.current);
    }
  }, [id]);

  // If editing a user, set the fields to the user's information
  useEffect(() => {
    if (user) {
      const {
        remember_token,
        email_verified_at,
        created_at,
        updated_at,
        ...userFields
      } = user;

      const inventoryPermissionLevel = getInventoryPermissionLevel(
        user.permissions.all_items?.permission_level_id || 1,
        user.permissions.lion_items?.permission_level_id || 1
      );

      const ownCommissionPermissions = getOwnCommissionPermissions(
        user.permissions.own_isr_commission?.permission_level_id || 1,
        user.permissions.own_osr_commission?.permission_level_id || 1,
        user.permissions.own_csr_commission?.permission_level_id || 1
      );

      const allCommissionPermissions = getAllCommissionPermissions(
        user.permissions.all_isr_commission?.permission_level_id || 1,
        user.permissions.all_osr_commission?.permission_level_id || 1,
        user.permissions.all_csr_commission?.permission_level_id || 1
      );

      setProfile({
        ...userFields,
        password: "",
        role_id: user.user_has_roles[0]?.role?.id,
        role_name: user.user_has_roles[0]?.role?.name,
        own_customers_permission_level:
          user.permissions.own_customers?.permission_level_id || 1,
        all_customers_permission_level:
          user.permissions.all_customers?.permission_level_id || 1,
        own_sales_orders_permission_level:
          user.permissions.own_sales_orders?.permission_level_id || 1,
        all_sales_orders_permission_level:
          user.permissions.all_sales_orders?.permission_level_id || 1,
        own_invoices_permission_level:
          user.permissions.own_invoices?.permission_level_id || 1,
        all_invoices_permission_level:
          user.permissions.all_invoices?.permission_level_id || 1,
        own_user_permission_level:
          user.permissions.own_user?.permission_level_id || 1,
        all_users_permission_level:
          user.permissions.all_users?.permission_level_id || 1,
        inventory_permission_level: inventoryPermissionLevel || 1,
        own_commission_permissions: ownCommissionPermissions || [],
        all_commission_permissions: allCommissionPermissions || [],
      });
    }
  }, [
    user,
    getInventoryPermissionLevel,
    getOwnCommissionPermissions,
    getAllCommissionPermissions,
  ]);

  // Get metadata
  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
  }

  // Check if a user already exists with provided netsuite id
  const checkUniqueNetsuiteId = async (netsuiteId) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/id/${netsuiteId}`, {
        credentials: 'include',
      });
      if (res.ok) {
        const json = await res.json();
        if (json.result === "success") {
          return json.data.unique_netsuite_id;
        } else {
          if (json.error) throw json.error;
          else throw json;
        }
      } else throw res;
    } catch (e) {
      console.error(e);
    }
  };

  // Check if a user already exists with provided email address
  const checkUniqueEmail = async (email) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/email/${email}`, {
        credentials: 'include',
      });
      if (res.ok) {
        const json = await res.json();
        if (json.result === "success") {
          return json.data.unique_email;
        } else {
          if (json.error) throw json.error;
          else throw json;
        }
      } else throw res;
    } catch (e) {
      console.error(e);
    }
  };

  // Check that the email is not blank, is in the correct format, and is unique
  const validateEmail = async (email) => {
    let emailError = "";
    if (!email) {
      emailError = "This is a required field";
    } else if (
      //check that email is in the correct format
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      emailError = "Please enter a valid email address";
    } else if (createNewUser) {
      try {
        const uniqueEmail = await checkUniqueEmail(email);
        if (!uniqueEmail) {
          emailError = "A user already exists with this email";
        } else {
          emailError = "";
        }
      } catch (error) {
        console.error(error);
      }
    }
    return emailError;
  };

  const validatePassword = (password) => {
    let passwordError = "";
    if (createNewUser && !password) {
      passwordError = "This is a required field";
    } else if ((createNewUser || password) && !validPassword) {
      passwordError = "Please enter a valid password";
    } else {
      passwordError = "";
    }
    return passwordError;
  };

  const handleOnBlurPassword = (e) => {
    const passwordError = validatePassword(e.target.value);
    setErrors({ ...errors, password: passwordError });
  };

  const handleOnChangePassword = (e) => {
    const password = e.target.value;
    setProfile({ ...profile, password: password });
    const passwordError = validatePassword(password);
    if (!passwordError) {
      setErrors({ ...errors, password: "" });
    }
  };

  const handleOnChangeEmail = async (e) => {
    const email = e.target.value;
    setProfile({ ...profile, email: email });
    try {
      const emailError = await validateEmail(email);
      if (!emailError) {
        setErrors({ ...errors, email: "" });
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Email validation and autofill user information from netsuite based on the email
  const handleOnBlurEmail = async (e) => {
    const emailError = await validateEmail(e.target.value);
    if (emailError) {
      setErrors({ ...errors, email: emailError });
    } else {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/users/netsuite/${e.target.value || "noemail"}`, {
            credentials: 'include',
          }
        );
        if (res.ok) {
          const json = await res.json();
          const user = json.data[0];
          if (user) {
            setProfile({
              ...profile,
              firstname: user.firstname || "",
              lastname: user.lastname || "",
              netsuite_id: user.id.toString() || "",
              user_type: user.type || "INTERNAL",
              companyname: user.companyname || "",
              is_person: user.isperson === "F" ? false : true,
              manager_id: user.manager,
              is_sales_rep: user.issalesrep === "F" ? false : true,
              is_costco_rep:
                user.custentity_is_costco_sales_rep === "F" ? false : true,
            });
          }
          setErrors({ ...errors, email: "" });
          setHelpers({ ...helpers, email: "" });
          if (!user) {
            setHelpers({
              ...helpers,
              email:
                "No internal employee or external sales rep was found in netsuite with this email address. You are creating a new netsuite employee or partner.",
              netsuite_id: "Netsuite will assign an id automatically.",
            });
            setProfile({ ...profile, create_in_netsuite: true });
          }
        } else if (res.status === 400) {
          let json;
          try {
            json = await res.json();
          } catch (e) {
            throw res;
          }
          if (json.error) throw json.error;
        } else {
          throw res;
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Netsuite id validation
  const handleOnBlurNetsuiteId = async (e) => {
    if (!e.target.value) {
      setErrors({ ...errors, netsuite_id: "This is a required field" });
    } else if (createNewUser) {
      try {
        const uniqueNetsuiteId = await checkUniqueNetsuiteId(e.target.value);
        if (!uniqueNetsuiteId) {
          setErrors({
            ...errors,
            netsuite_id: "A user already exists with this netsuite id",
          });
        }
      } catch (edge) {
        console.error(e);
      }
    } else {
      setErrors({ ...errors, netsuite_id: "" });
    }
  };
  const handleChange = (e) =>
    setProfile({ ...profile, [e.target.name]: e.target.value });
  const handleSelectChange = (name) => (event, value) =>
    setProfile({ ...profile, [name]: value?.id || "" });
  const handleCheckedChange = (e) =>
    setProfile({ ...profile, [e.target.name]: e.target.checked });

  const handleSave = async (e) => {
    try {
      const profileCopy = { ...profile };

      // FORM VALIDATION
      const requiredFields = [...document.querySelectorAll("[required]")].map(
        (el) => el.getAttribute("name")
      );
      let formErrors = {};

      // Check that required fields are not empty
      for (let field of requiredFields) {
        if (!profileCopy[field]) {
          formErrors[field] = "This is a required field";
        }
      }
      // Check that the email is valid
      const emailError = await validateEmail(profileCopy.email);
      if (emailError) {
        formErrors.email = emailError;
      }
      // Check that password meets requirements
      if (profileCopy.password || createNewUser) {
        const passwordError = validatePassword(profileCopy.password);
        if (passwordError) {
          formErrors.password = passwordError;
        }
      } else {
        // If editing rather than creating, then a blank password means don't update the password
        delete profileCopy.password;
      }
      // If creating, check that netsuite id is unique
      if (createNewUser && profileCopy.netsuite_id) {
        const uniqueNetsuiteId = await checkUniqueNetsuiteId(
          profile.netsuite_id
        );
        if (!uniqueNetsuiteId) {
          formErrors.netsuite_id =
            "A user already exists with this netsuite id";
        }
      }
      // If there are any errors, let the user know and don't save
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        const { error } = {
          error: {
            name: "You're not done yet!",
            message: "Please fix any fields with errors",
          },
        };
        throw error;
      }

      setIsSaving(true);

      console.log(profileCopy);

      // Post if creating a new user and put if editing an old one
      let method = "";
      let url = "";
      if (createNewUser) {
        method = "POST";
        url = `${process.env.REACT_APP_BASE_URL}/api/users/`;
      } else {
        method = "PUT";
        url = `${process.env.REACT_APP_BASE_URL}/api/users/${id}`;
      }
      const res = await fetch(url, {
        method: method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(profileCopy),
      });

      if (res.ok) {
        const json = await res.json();
        // If the user edited their own profile, update local storage user info with newly saved profile data
        if (editingOwnProfile) {
          let user = safeJSONparse(localStorage.getItem("user"));
          user = { ...user, ...json };
          localStorage.setItem("user", JSON.stringify(user));
        }
        if (createNewUser) {
          toast.success("User created");
        } else {
          toast.success("Profile updated");
        }
      } else if (res.status === 400) {
        let json;
        try {
          json = await res.json();
        } catch (e) {
          throw res;
        }
        if (json.error) throw json.error;
      } else {
        throw res;
      }
      setIsSaving(false);
      if (createNewUser && res.ok) {
        setShowEmailAlertDialog(true);
      }
    } catch (e) {
      console.error(e);
      setShowErrorAlertDialog(true);
      setIsSaving(false);
    }
  };

  const confirmDelete = () => {
    setShowDeleteAlertDialog(true);
  };

  const handleDelete = async (e) => {
    setIsDeleting(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/${id}`, { 
        method: "DELETE",
        credentials: 'include',
      });
      if (res.ok) {
        toast.success("User deleted");
        navigate("/users");
      } else throw res;
    } catch (e) {
      setIsDeleting(false);
      console.error(e);
    }
  };

  // Send welcome email
  const handleSendEmail = async (e) => {
    try {
      setEmailData({
        email: profile.email,
        error: null,
        isSendingEmail: true,
        message: "",
      });
      const emailResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/welcomeemail`, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: profile.email,
        }),
      });
      if (emailResponse.ok) {
        const json = await emailResponse.json();
        setEmailData({ ...emailData, message: json, error: null });
        toast.success("Welcome email sent!");
        navigate("/users");
      } else if (emailResponse.status === 401) {
        let json;
        try {
          json = await emailResponse.json();
        } catch (e) {
          throw emailResponse;
        }
        if (json.error) throw json.error;
      } else {
        throw emailResponse;
      }
    } catch (e) {
      console.error(e);
      setEmailData({ ...emailData, error: e, message: "" });
    }
  };

  // Header for page title
  const header = createNewUser
    ? "New User"
    : `${
        profile.is_person
          ? profile.firstname.concat(` ${profile.lastname}`)
          : profile.companyname
      }`;

  window.document.title = isLoading ? "Suite Sales" : `${header} | Suite Sales`;

  if (createNewUser && !permissions.canCreateAllUsers)
    return (
      <MainContainer>You are not authorized to view this page</MainContainer>
    );
  if (error) return <MainContainer>{JSON.stringify(error)}</MainContainer>;
  if (isLoading) return <EclipseSpinner />;
  return (
    <>
      <Header className="justify-between">
        <Typography variant="h6">
          <b>{header}</b>
        </Typography>
        <Box>
          <SubmitButton
            disabled={isSaving}
            className="mr-2 bg-gray-500 hover:bg-gray-600 flex-1"
            endIcon={<CancelIcon />}
            onClick={() => navigate(-1)}
            loadingPosition="end"
          >
            Cancel
          </SubmitButton>
          {/* Prevent users from deleting their own accounts */}
          {!createNewUser &&
            !editingOwnProfile &&
            permissions.canDeleteAllUsers && (
              <SubmitButton
                className="mr-2"
                loadingPosition="end"
                endIcon={<DeleteIcon />}
                color="error"
                loading={isDeleting}
                onClick={confirmDelete}
              >
                Delete
              </SubmitButton>
            )}
          <SubmitButton
            loadingPosition="end"
            endIcon={<SaveIcon />}
            onClick={handleSave}
            loading={isSaving}
          >
            Save
          </SubmitButton>
        </Box>
      </Header>
      <MainContainer>
        <Box>
          {/* firstname, lastname, email, password, netsuite_id, employee type  */}
          <Card className="mb-3">
            <CardHeader title="Personal Info" />
            <CardContent>
              <Input
                required
                error={!!errors.email}
                helperText={
                  errors.email
                    ? errors.email
                    : helpers.email
                    ? helpers.email
                    : ""
                }
                name={"email"}
                placeholder={"Email"}
                value={profile.email}
                onChange={handleOnChangeEmail}
                onBlur={handleOnBlurEmail}
                disabled={readOnly}
              />
              <ButtonGroup className="w-full mb-3">
                <Button
                  className="w-1/2"
                  color="info"
                  variant={
                    profile.user_type === "PARTNER" ? "outlined" : "contained"
                  }
                  onClick={() =>
                    setProfile({
                      ...profile,
                      user_type: "INTERNAL",
                      is_person: true,
                    })
                  }
                  disabled={limitedAccess}
                >
                  Internal Employee
                </Button>
                <Button
                  className="w-1/2"
                  color="info"
                  variant={
                    profile.user_type === "PARTNER" ? "contained" : "outlined"
                  }
                  onClick={() =>
                    setProfile({
                      ...profile,
                      user_type: "PARTNER",
                      role_name: "Sales Rep",
                      role_id: 3,
                    })
                  }
                  disabled={limitedAccess}
                >
                  External Sales Rep
                </Button>
              </ButtonGroup>
              {profile.user_type === "PARTNER" && (
                <>
                  <ButtonGroup className="w-full mb-3">
                    <Button
                      className="w-1/2"
                      color="info"
                      variant={profile.is_person ? "contained" : "outlined"}
                      onClick={() =>
                        setProfile({ ...profile, is_person: true })
                      }
                      disabled={limitedAccess}
                    >
                      Individual
                    </Button>
                    <Button
                      className="w-1/2"
                      color="info"
                      variant={profile.is_person ? "outlined" : "contained"}
                      onClick={() =>
                        setProfile({
                          ...profile,
                          is_person: false,
                        })
                      }
                      disabled={limitedAccess}
                    >
                      Company
                    </Button>
                  </ButtonGroup>
                </>
              )}
              {profile.is_person ? (
                <>
                  <Box className="flex w-full">
                    <Input
                      className="flex-1 mr-2 "
                      error={!!errors.firstname}
                      helperText={errors.firstname}
                      name={"firstname"}
                      placeholder={"First Name"}
                      value={profile.firstname || ""}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                    <Input
                      className="flex-1 "
                      error={!!errors.lastname}
                      helperText={errors.lastname}
                      name={"lastname"}
                      placeholder={"Last Name"}
                      value={profile.lastname || ""}
                      onChange={handleChange}
                      disabled={readOnly}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Input
                    error={!!errors.companyname}
                    helperText={errors.companyname}
                    name={"companyname"}
                    placeholder={"Company Name"}
                    value={profile.companyname || ""}
                    onChange={handleChange}
                    disabled={readOnly}
                  />
                </>
              )}
              {!readOnly && (
                <PasswordValidationTooltip
                  password={profile.password}
                  placement="top-end"
                >
                  <PasswordInput
                    required={createNewUser}
                    error={!!errors.password}
                    helperText={
                      errors.password
                        ? errors.password
                        : !createNewUser
                        ? "Password will not update if left blank."
                        : ""
                    }
                    value={profile.password}
                    name="password"
                    onChange={handleOnChangePassword}
                    autoComplete="new-password"
                    onBlur={handleOnBlurPassword}
                    disabled={readOnly}
                  />
                </PasswordValidationTooltip>
              )}

              <Input
                error={!!errors.netsuite_id}
                helperText={
                  errors.netsuite_id
                    ? errors.netsuite_id
                    : helpers.netsuite_id
                    ? helpers.netsuite_id
                    : createNewUser
                    ? "Please enter an email address to pull an id from netsuite"
                    : ""
                }
                name={"netsuite_id"}
                placeholder={"Netsuite Id"}
                value={profile.netsuite_id}
                onChange={handleChange}
                onBlur={handleOnBlurNetsuiteId}
                disabled={!permissions.canEditAllUsers || createNewUser}
              />
              {profile.user_type === "INTERNAL" && (
                <Box className="flex-col ">
                  <Typography>Employee Type</Typography>
                  <FormControlLabel
                    className="flex-1 "
                    label="Sales Rep"
                    control={
                      <Checkbox
                        disabled={limitedAccess}
                        checked={profile.is_sales_rep}
                        name="is_sales_rep"
                        onChange={handleCheckedChange}
                      />
                    }
                  />
                  <FormControlLabel
                    className="flex-1"
                    label="Costco Rep"
                    control={
                      <Checkbox
                        disabled={limitedAccess}
                        checked={profile.is_costco_rep}
                        name="is_costco_rep"
                        onChange={handleCheckedChange}
                      />
                    }
                  />
                </Box>
              )}
            </CardContent>
          </Card>
          <Card className="mb-3">
            <CardHeader title="Role and Permissions" />
            <CardContent>
              <RolesAndPermissions
                profile={profile}
                setProfile={setProfile}
                readOnly={readOnly}
                getInventoryPermissionLevel={getInventoryPermissionLevel}
                getOwnCommissionPermissions={getOwnCommissionPermissions}
                getAllCommissionPermissions={getAllCommissionPermissions}
                limitedAccess={limitedAccess}
                metadata={metadata}
                handleSelectChange={handleSelectChange}
              />
            </CardContent>
          </Card>

          <Card>
            <CardHeader title="Defaults" />
            <CardContent>
              <ComboBox
                options={metadata.categories?.data}
                value={profile.default_category}
                name="default_category"
                onChange={handleSelectChange("default_category")}
                disabled={readOnly}
              >
                Default Category
              </ComboBox>
              <ComboBox
                options={customerClasses}
                value={profile.default_class}
                name="default_class"
                onChange={handleSelectChange("default_class")}
                disabled={readOnly}
              >
                Default Class
              </ComboBox>
              <ComboBox
                options={terms}
                value={profile.default_terms}
                name="default_terms"
                onChange={handleSelectChange("default_terms")}
                disabled={readOnly}
              >
                Default Terms
              </ComboBox>
              <ComboBox
                options={priceLevels}
                value={profile.default_pricelevel}
                name="default_pricelevel"
                onChange={handleSelectChange("default_pricelevel")}
                disabled={readOnly}
              >
                Default Price Level
              </ComboBox>
              <ComboBox
                options={defaultLocations}
                value={profile.default_location}
                name="default_location"
                onChange={handleSelectChange("default_location")}
                disabled={readOnly}
              >
                Default Location
              </ComboBox>
              <FormControlLabel
                label="Activate customers in Exemptax?"
                control={
                  <Checkbox
                    checked={profile.default_exemptax_status}
                    name="default_exemptax_status"
                    onChange={handleCheckedChange}
                    disabled={readOnly}
                  />
                }
              />
            </CardContent>
          </Card>
        </Box>
      </MainContainer>

      {/* Alert dialogs for errors, deletion confirmation, and welcome email */}
      {showErrorAlertDialog && (
        <AlertDialog
          header="You're not done yet!"
          body="Please fix any fields with errors before saving."
          open={showErrorAlertDialog}
          setShowAlertDialog={setShowErrorAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
      {showDeleteAlertDialog && (
        <AlertDialog
          header="Delete user?"
          body="Are you sure you want to delete this user?"
          open={showDeleteAlertDialog}
          setShowAlertDialog={setShowDeleteAlertDialog}
          agreeFunction={handleDelete}
          disagreeText="Cancel"
          agreeText="Delete"
          showAgree={true}
        />
      )}
      {showEmailAlertDialog && (
        <AlertDialog
          header="Email user?"
          body="Would you like to email this user a welcome email?"
          open={showEmailAlertDialog}
          setShowAlertDialog={setShowEmailAlertDialog}
          agreeFunction={handleSendEmail}
          disagreeFunction={() => navigate("/users")}
          disagreeText="No"
          agreeText="Send Email"
          showAgree={true}
        />
      )}
    </>
  );
};

export default UserForm;
