import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({ agreeFunction, disagreeFunction, setShowAlertDialog, disagreeText, agreeText, showAgree = true, header, body, ...props }) => {
  const [open, setOpen] = useState(false);

  const handleCancel = () => {
    if (disagreeFunction) {
      disagreeFunction()
    }
    setOpen(false);
    setShowAlertDialog(false)
  };

  const handleAgree = () => {
      agreeFunction()
    setOpen(false)
    setShowAlertDialog(false);
  }

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  // Determine if the body content is a string or a JSX element
  const isJSX = React.isValidElement(body);

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {header}
      </DialogTitle>
      <DialogContent>
        {/* Render different content based on whether the body is JSX or a string */}
        {isJSX ? body : <p>{body}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>{disagreeText}</Button>
        {showAgree && (
          <Button onClick={handleAgree} autoFocus>
            {agreeText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog