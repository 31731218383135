import React, { useState, useContext } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import SubmitButton from "../../forms/SubmitButton";
import { AuthContext } from "../../../services/Authentication";
import Input from "../../forms/Input";
import { ClickAwayListener } from "@mui/material";
import ComboBox from "../../forms/ComboBox";
import ClearIcon from "@material-ui/icons/Clear";
import { IconButton } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import {
  unitedStates,
  canadianProvinces,
  countries,
  invoiceStatuses,
} from "../../constants";
import DateRange from "../../forms/DateRange";
import usePermissions from "../../../hooks/usePermissions";

const InvoiceAdvancedSearch = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { dispatch, state: authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const permissions = usePermissions();

  // Set the search to the existing parameters, so they can adjust their previous search without putting everything in again
  const previousSearch = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );

  const country =
    countries.find(
      ({ abbreviation }) => abbreviation === previousSearch?.shipcountry
    ) || null;
  const state =
    country?.id === 1 || !country
      ? unitedStates.find(
          ({ abbreviation }) => abbreviation === previousSearch?.shipstate
        )
      : canadianProvinces.find(
          ({ abbreviation }) => abbreviation === previousSearch?.shipstate
        ) || null;
  const status =
    invoiceStatuses.find(
      ({ netsuiteId }) => netsuiteId === previousSearch?.status
    ) || null;

  const [search, setSearch] = useState({
    customer: previousSearch?.customer || "",
    numbertext: previousSearch?.numbertext || "",
    poastext: previousSearch?.poastext || "",
    shipaddressee: previousSearch?.shipaddressee || "",
    shipcountry: previousSearch?.shipcountry || "",
    shipcountry_id: country?.id || "",
    shipstate: previousSearch?.shipstate || "",
    shipstate_id: state?.id || "",
    status: previousSearch?.status || "",
    status_id: status?.id || "",
    salesrep: previousSearch?.salesrep || "",
    supervisor: previousSearch?.supervisor || "",
    costcorep: previousSearch?.costcorep || "",
    partner: previousSearch?.partner || "",
    overduebalance: previousSearch?.overduebalance === "true" || false,
    date: previousSearch.startdate
      ? [previousSearch?.startdate, previousSearch?.enddate]
      : [null, null],
  });

  // Remove one of the dates so that the filter count will be correct
  if (previousSearch?.startdate && previousSearch?.enddate) {
    delete previousSearch.enddate;
  }
  const numFilters = Object.keys(previousSearch).length || 0;

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.value });

  const handleDateChange = (newValue) =>
    setSearch({ ...search, date: newValue });

  const handleCheckedChange = (e) =>
    setSearch({ ...search, [e.target.name]: e.target.checked });

  const handleSelectChange = (name) => (event, value) => {
    if (name === "shipstate") {
      setSearch({
        ...search,
        shipstate: value?.abbreviation || "",
        shipstate_id: value?.id || "",
      });
    } else if (name === "shipcountry") {
      setSearch({
        ...search,
        shipcountry: value?.abbreviation || "",
        shipcountry_id: value?.id || "",
        shipstate: "",
        shipstate_id: "",
      });
    } else if (name === "status") {
      setSearch({
        ...search,
        status: value?.netsuiteId || "",
        status_id: value?.id || "",
      });
    } else {
      setSearch({ ...search, [name]: value?.abbreviation || value?.id || "" });
    }
  };

  // Clear an individual input
  const handleClear = (name) => (e) => setSearch({ ...search, [name]: "" });

  // Clear entire search
  const handleClearAll = () => {
    setIsSearching(true);
    navigate("/invoices");
    setIsSearching(false);
    setOpen(false);
  };

  const handleSearch = () => {
    setIsSearching(true);
    const searchCopy = JSON.parse(JSON.stringify(search));

    // Remove any blank parameters
    Object.keys(searchCopy).forEach((key, index) => {
      if (!searchCopy[key]) delete searchCopy[key];
    });
    if (!searchCopy.date[0]) delete searchCopy.date;

    // Format dates
    if (searchCopy?.date) {
      searchCopy.startdate = new Date(searchCopy.date[0]).toLocaleDateString();
      searchCopy.enddate = new Date(searchCopy.date[1]).toLocaleDateString();
    }

    // Remove things not needed for the api call
    delete searchCopy.shipcountry_id;
    delete searchCopy.shipstate_id;
    delete searchCopy.status_id;
    delete searchCopy.date;

    // Update the url with the new search
    const urlParams = new URLSearchParams(searchCopy).toString();
    navigate({ pathname: "/invoices/", search: `?${urlParams}` });
    setIsSearching(false);
    setOpen(false);
  };

  // Get metadata
  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };
  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
    return <Navigate to="/login" replace />;
  }

  let osrManager = search.salesrep || authState.user.netsuite_id;

  const filteredPartners =
    (!permissions.isAdmin && !permissions.canViewAllInvoices) || search.salesrep
      ? metadata.partners.data.filter(
          (partner) => partner.manager == osrManager
        )
      : metadata.partners.data;

  const filteredSalesReps =
    !permissions.isAdmin && !permissions.canViewAllInvoices
      ? metadata.salesreps.data.filter(
          (salesrep) =>
            salesrep.manager == authState.user.netsuite_id ||
            salesrep.id == authState.user.netsuite_id
        )
      : metadata.salesreps?.data;

  return (
    <Box>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className="p-2 border-2">
              <ClickAwayListener onClickAway={handleClose}>
                <Box>
                  <Box className="flex justify-end">
                    <Button
                      endIcon={<ClearIcon />}
                      onClick={handleClearAll}
                      className="float-right"
                    >
                      Clear All
                    </Button>
                  </Box>
                  <DateRange
                    onChange={handleChange}
                    value={search.date}
                    setValue={handleDateChange}
                  />
                  {filteredSalesReps.length > 0 && (
                    <ComboBox
                      options={filteredSalesReps}
                      value={search.salesrep}
                      name="salesrep"
                      onChange={handleSelectChange("salesrep")}
                      disableClearable={false}
                    >
                      Inside Sales Rep
                    </ComboBox>
                  )}
                  {permissions.canViewAllInvoices && (
                    <ComboBox
                      options={metadata.salesreps?.data}
                      value={search.supervisor}
                      name="supervisor"
                      onChange={handleSelectChange("supervisor")}
                      disableClearable={false}
                    >
                      ISR Supervisor
                    </ComboBox>
                  )}
                  {permissions.canViewAllInvoices && (
                    <ComboBox
                      options={metadata.costcoreps?.data}
                      value={search.costcorep}
                      name="costcorep"
                      onChange={handleSelectChange("costcorep")}
                      disableClearable={false}
                    >
                      Costco Sales Rep
                    </ComboBox>
                  )}
                  {filteredPartners.length > 0 && (
                    <ComboBox
                      options={filteredPartners}
                      value={search.partner}
                      name="partner"
                      onChange={handleSelectChange("partner")}
                      disableClearable={false}
                    >
                      Outside Sales Rep
                    </ComboBox>
                  )}
                  <Input
                    name={"customer"}
                    placeholder={"Customer"}
                    value={search.customer || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={handleClear("customer")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"numbertext"}
                    placeholder={"INV #"}
                    value={search.numbertext || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={handleClear("numbertext")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"poastext"}
                    placeholder={"PO #"}
                    value={search.poastext || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={handleClear("poastext")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <Input
                    name={"shipaddressee"}
                    placeholder={"Ship To"}
                    value={search.shipaddressee || ""}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          onClick={handleClear("shipaddressee")}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      ),
                    }}
                  />
                  <ComboBox
                    options={countries}
                    value={search.shipcountry_id}
                    name="shipcountry"
                    onChange={handleSelectChange("shipcountry")}
                    disableClearable={false}
                  >
                    Shipping Country
                  </ComboBox>
                  <ComboBox
                    options={
                      search.shipcountry_id === 2
                        ? canadianProvinces
                        : unitedStates
                    }
                    value={search.shipstate_id}
                    name="shipstate"
                    onChange={handleSelectChange("shipstate")}
                    disableClearable={false}
                  >
                    Shipping State
                  </ComboBox>
                  <ComboBox
                    options={invoiceStatuses}
                    value={search.status_id}
                    name="status"
                    onChange={handleSelectChange("status")}
                    disableClearable={false}
                  >
                    Status
                  </ComboBox>
                  <FormGroup className="px-1">
                    <FormControlLabel
                      label="Has an overdue balance"
                      control={
                        <Checkbox
                          checked={search.overduebalance}
                          name="overduebalance"
                          onChange={handleCheckedChange}
                        />
                      }
                    />
                  </FormGroup>
                  <SubmitButton onClick={handleSearch} loading={isSearching}>
                    Apply
                  </SubmitButton>
                </Box>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Button
        startIcon={<FilterListIcon />}
        onClick={handleClick("bottom-end")}
      >
        Filters ({numFilters})
      </Button>
    </Box>
  );
};
export default InvoiceAdvancedSearch;
