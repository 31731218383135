import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import useFetch from "../../../hooks/useFetch";
import SearchIcon from "@mui/icons-material/Search";
import InternalLink from "../../routing/InternalLink";
import FullPageGridContainer from "../../layout/FullPageGridContainer";
import Header from "../../layout/Header";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SubmitButton from "../../forms/SubmitButton";
import CustomerAdvancedSearch from "./CustomerAdvancedSearch";
import usePermissions from "../../../hooks/usePermissions";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const CustomToolbar = () => {
  return (
    <GridToolbarContainer className="flex justify-between">
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Box>
      <CustomerAdvancedSearch />
    </GridToolbarContainer>
  );
};

const CustomerList = (props) => {
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const permissions = usePermissions();
  const navigate = useNavigate();
  const location = useLocation();
  const [customers, setCustomers] = useState([]);
  const columns = [
    {
      field: "view",
      width: 18,
      resizable: false,
      renderCell: (params) => (
        <InternalLink to={`/customers/${params?.row?.id}`}>
          <SearchIcon />
        </InternalLink>
      ),
      renderHeader: (params) => <SearchIcon className="text-gray-400" />,
    },
    { field: "id", headerName: "ID", hide: true },
    {
      renderCell,
      field: "entitystatus",
      headerName: "Status",
      description: "Status in Netsuite",
      width: 120,
    },
    {
      renderCell,
      field: "altname",
      headerName: "Name",
      description: "Full Netsuite entity ID",
      minWidth: 120,
      flex: 1,
    },
    {
      renderCell,
      field: "email",
      headerName: "Email",
      description: "Main email in Netsuite",
      minWidth: 120,
      flex: 1,
    },
    {
      renderCell,
      field: "phone",
      headerName: "Phone",
      description: "Main phone in Netsuite",
      width: 120,
    },
    {
      renderCell,
      field: "salesrep",
      headerName: "Inside Sales Rep",
      description: "Inside sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
    },
    {
      renderCell,
      field: "costcorep",
      headerName: "Costco Sales Rep",
      description: "Costco sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "partner",
      headerName: "Outside Sales Rep",
      description: "Outside sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
    },
    {
      renderCell,
      field: "custentity_customer_service_rep",
      headerName: "Customer Service Rep",
      description: "Customer service rep in Netsuite",
      minWidth: 120,
      flex: 1,
    },
    {
      renderCell,
      field: "terms",
      headerName: "Terms",
      description: "Customer's terms in NetSuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "daysoverdue",
      headerName: "Days Overdue",
      description: "Number of days by which the customers' balance is overdue",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "taxable",
      headerName: "Taxable",
      description: "Whether the customer is marked as Taxable in NetSuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_active_in_exemptax",
      headerName: "Active in Exemptax",
      description: "Whether the customer is active in Exemptax",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_utm_source",
      headerName: "UTM Source",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_utm_medium",
      headerName: "UTM Medium",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_utm_campaign",
      headerName: "UTM Campaign",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_utm_term",
      headerName: "UTM Term",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "custentity_utm_content",
      headerName: "UTM Content",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
  ];
  const netsuiteColumns = [
    { name: "lastmodifieddate", sort: "DESC" },
    "internalid",
    "entitystatus",
    "altname",
    "email",
    "phone",
    "salesrep",
    "taxable",
    "custentity_costco_sales_rep",
    "partner",
    "custentity_customer_service_rep",
    "daysoverdue",
    "custentity_active_in_exemptax",
    "terms",
    "custentity_utm_source",
    "custentity_utm_medium",
    "custentity_utm_campaign",
    "custentity_utm_term",
    "custentity_utm_content",
  ];

  // Add ISR Supervisor
  if (permissions.canViewAllCustomers) {
    columns.push({
      renderCell,
      field: "isr_supervisor",
      headerName: "ISR Supervisor",
      description: "Inside sales rep's supervisor in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    });
    netsuiteColumns.push("salesrep.supervisor");
  }

  // Set filters for netsuite api
  const netsuiteFilters = [];
  if (params.salesrep)
    netsuiteFilters.push("AND", ["salesrep", "is", params.salesrep]);
  if (params.supervisor)
    netsuiteFilters.push("AND", [
      "salesrep.supervisor",
      "is",
      params.supervisor,
    ]);
  if (params.costcorep)
    netsuiteFilters.push("AND", [
      "custentity_costco_sales_rep",
      "is",
      params.costcorep,
    ]);
  if (params.partner)
    netsuiteFilters.push("AND", ["partner", "is", params.partner]);
  if (params.phone)
    netsuiteFilters.push("AND", ["phone", "contains", params.phone]);
  if (params.email)
    netsuiteFilters.push("AND", ["email", "contains", params.email]);
  if (params.name)
    netsuiteFilters.push("AND", ["altname", "contains", params.name]);
  if (params.status)
    netsuiteFilters.push("AND", ["entitystatus", "is", params.status]);
  if (params.isperson)
    netsuiteFilters.push("AND", ["isperson", "is", params.isperson]);
  if (params.country)
    netsuiteFilters.push("AND", ["country", "is", params.country]);
  if (params.state) netsuiteFilters.push("AND", ["state", "is", params.state]);
  if (params.overduebalance) {
    netsuiteFilters.push("AND", ["daysoverdue", "greaterthan", "0"]);
  }
  if (params.taxable) {
    netsuiteFilters.push("AND", ["taxable", "is", "T"]);
  }
  if (params.exemptax) {
    netsuiteFilters.push("AND", ["custentity_active_in_exemptax", "is", "T"]);
  }
  if (params.csr) {
    netsuiteFilters.push("AND", [
      "custentity_customer_service_rep",
      "is",
      params.csr,
    ]);
  }
  if (params.terms) {
    netsuiteFilters.push("AND", ["terms", "is", params.terms]);
  }

  // Set the url
  let url = `${process.env.REACT_APP_BASE_URL}/api${location.pathname}?results=${JSON.stringify(
    netsuiteColumns
  )}`;
  if (netsuiteFilters) {
    url += `&params=${JSON.stringify(netsuiteFilters)}`;
  }

  const { error, isLoading, data: json } = useFetch({ url });

  useEffect(() => {
    if (json) {
      const { result, data = [] } = json;
      console.log(data);

      if (result === "success") {
        setCustomers(
          data.map(
            ({
              altname,
              entitystatus: [{ text: status } = {}] = [],
              email,
              phone,
              internalid: [{ value: id } = {}] = [],
              salesrep: [{ text: isr } = {}] = [],
              "salesrep.supervisor": [{ text: isr_supervisor } = {}] = [],
              custentity_costco_sales_rep: [{ text: costcorep } = {}] = [],
              partner: [{ text: osr } = {}] = [],
              custentity_customer_service_rep: [{ text: csr } = {}] = [],
              daysoverdue,
              taxable,
              custentity_active_in_exemptax,
              terms: [{ text: paymentTerms } = {}] = [],
              custentity_utm_source,
              custentity_utm_medium,
              custentity_utm_campaign,
              custentity_utm_term,
              custentity_utm_content,
            }) => ({
              id,
              entitystatus: status,
              altname,
              email,
              phone,
              salesrep: isr,
              isr_supervisor,
              costcorep,
              partner: osr,
              custentity_customer_service_rep: csr,
              daysoverdue,
              taxable: taxable ? "Yes" : "No",
              custentity_active_in_exemptax: custentity_active_in_exemptax
                ? "Yes"
                : "No",
              terms: paymentTerms,
              custentity_utm_source,
              custentity_utm_medium,
              custentity_utm_campaign,
              custentity_utm_term,
              custentity_utm_content,
            })
          )
        );
      }
    }
  }, [json]);

  window.document.title = "Customers | Suite Sales";

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <Header header="Customers" className="justify-between">
        <Box className="float-right">
          {permissions.canCreateAnyCustomers && (
            <SubmitButton
              endIcon={<AddIcon />}
              loadingPosition="end"
              onClick={() => navigate(`/customers/create`)}
            >
              New Customer
            </SubmitButton>
          )}
        </Box>
      </Header>
      <FullPageGridContainer>
        <DataGridPro
          rows={customers}
          disableSelectionOnClick={true}
          onRowDoubleClick={(e) => navigate(`/customers/${e.id}`)}
          columns={columns}
          loading={isLoading}
          density="standard"
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </FullPageGridContainer>
    </>
  );
};

export default CustomerList;
