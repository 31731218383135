import React, { useState } from "react";

import Input from "./Input";
import { Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import SubmitButton from "./SubmitButton";
import AlertDialog from "./AlertDialog";

const NoteForm = ({
  noteId,
  noteText,
  customerId,
  createNewNote,
  cancelNewNote,
  index,
  ...props
}) => {
  const [note, setNote] = useState(noteText || "");
  const [submitting, setSubmitting] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);

  const handleChangeNote = (e) => setNote(e.target.value);

  const handleCancelNote = () => {
    if (createNewNote) {
      setNote("");
      cancelNewNote();
    } else {
      props.cancelEditNote(index);
    }
  };

  const handleSaveNote = async () => {
    const trimmedNote = note.trim();
    if (trimmedNote.length < 3 || trimmedNote.length >= 4000) {
      setShowAlertDialog(true)
    } else {
      setSubmitting(true);
      try {
        let method = "";
        let url = "";
        if (createNewNote) {
          url = `${process.env.REACT_APP_BASE_URL}/api/customers/${customerId}/note`;
          method = "POST";
        } else {
          url = `${process.env.REACT_APP_BASE_URL}/api/customers/note/${noteId}`;
          method = "PUT";
        }
  
        const res = await fetch(url, {
          method: method,
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ note: trimmedNote }),
        });
  
        if (res.ok) {
          const json = await res.json();
          if (json.result === "success") {
            if (createNewNote) {
              const internalid = json.record_id;
              props.addNote(note, internalid);
              setNote("");
              setSubmitting(false);
              props.setShowNewNoteForm(false);
            } else {
              setSubmitting(false);
              props.editNote(note, index);
            }
          } else {
            if (json.error) throw json.error;
            else throw json;
          }
        } else throw res;
      } catch (e) {
        setSubmitting(false);
        console.error(e); 
      }
    }
  };

  return (
    <>
      <Box className="mb-2 w-full">
        <Box className="w-full">
          <Input
            multiline
            fullWidth
            rows={4}
            placeholder={createNewNote ? "Add Note" : "Note"}
            value={note}
            onChange={handleChangeNote}
          />
        </Box>
        <Box className="text-right">
          <SubmitButton
            className="bg-purple-300 hover:bg-purple-400 mr-2"
            endIcon={<CancelIcon />}
            loading={submitting}
            onClick={handleCancelNote}
          >
            Cancel
          </SubmitButton>
          <SubmitButton
            className="bg-purple-300 hover:bg-purple-400"
            loadingPosition="end"
            endIcon={<SaveIcon />}
            loading={submitting}
            onClick={handleSaveNote}
          >
            Save
          </SubmitButton>
        </Box>
      </Box>
      {showAlertDialog && (
        <AlertDialog
          header="Can't save!"
          body="Notes must be between 3 and 4000 characters long."
          open={showAlertDialog}
          setShowAlertDialog={setShowAlertDialog}
          disagreeText="Close"
          showAgree={false}
        />
      )}
    </>
  );
};

export default NoteForm;
