import React from 'react'
import { Container } from "@mui/material";
import { Box } from "@mui/system";

export default function EclipseSpinner(props) {

  return (
    <Container className='flex items-center h-screen'>
      <Box className='mx-auto'>
        <img alt="loading spinner" src="/img/Eclipse-Spinner.gif" />
      </Box>
    </Container>
  )
}