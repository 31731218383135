import React, { useContext, useState } from 'react'

import { useLocation, useNavigate, Navigate } from 'react-router-dom'

import SubmitButton from '../../forms/SubmitButton'
import EmailInput from '../../forms/EmailInput'
import PasswordInput from '../../forms/PasswordInput'
import InternalLink from '../../routing/InternalLink'

import LionEnergyLogo from '../../LionEnergyLogo'

import { Box, Card, CardActions, CardContent, Typography, Container } from '@mui/material'
import LoginIcon from '@mui/icons-material/Login';

import { AuthContext } from '../../../services/Authentication'


const Login = (props) => {
  const { state, dispatch } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()
  const {email} = location || ''

  const [data, setData] = useState({
    email: email || '',
    password: '',
    isLoggingIn: false,
    error: null
  })

  const handleChange = prop => event => {
    setData({ ...data, [prop]: event.target.value })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setData({ ...data, isLoggingIn: true })

    try {
      const loginResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/login`, {
        method: "POST",
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: data.email,
          password: data.password
        })
      })

      if (loginResponse.ok) {
        const userData = await loginResponse.json()

        // Load Netsuite metadata
        const metadataResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customers/metadata`, {
          credentials: 'include'
        });
        if (metadataResponse.code === 401) {
          dispatch({ type: "LOGOUT" })
          return <Navigate to='/login' replace/>
        }

        if (metadataResponse.ok) {
          const metadata = await metadataResponse.text()
          localStorage.setItem('metadata', metadata)
        }

        dispatch({
          type: "LOGIN",
          payload: userData
        })

        // Redirect to home page or previously attempted to view page
        const { from } = location.state || { from: { pathname: "/" } }
        if (from.pathname === '/login') from.pathname = '/'
        navigate(from, {replace: true})
      }
      else if (loginResponse.status === 401) {
        let json
        try {
          json = await loginResponse.json()
        } catch (e) {
          throw loginResponse
        }
        if (json.error) throw json.error
        else throw loginResponse
      } else {
        dispatch({ type: "LOGOUT" })
        throw loginResponse
      }

    } catch (e) {
      console.error(e)
      setData({ ...data, error: e })
    }
  }

  if (state.isAuthenticated) return <Navigate to='/' replace/>

  window.document.title = "Login | Suite Sales"

  return (
    <Box className="login flex items-center h-screen bg-gradient-to-b from-black to-gray-700">
      {/* Card with form: email/password input, forgot password link and login button */}
      <Container maxWidth="xs">
        <form id="login_form" onSubmit={handleSubmit}>
          <Card style={{ minWidth: "350px" }}>
            <CardContent>
              <LionEnergyLogo />
              <EmailInput
                autoFocus
                value={data.email}
                onChange={handleChange('email')}
              />
              <PasswordInput
                value={data.password}
                onChange={handleChange('password')}
              />
              {data.error ? <Typography variant="body2" color="error">{data.error?.message || data.error?.statusText}</Typography> : ''}
              <InternalLink to={{ pathname: '/forgot', email: data.email }} className="mr-2 text-center" >
                <Typography variant="body2">Forgot your password?</Typography>
              </InternalLink>
            </CardContent>
            <CardActions className='flex-row-reverse'>
              <SubmitButton loadingPosition="end" endIcon={<LoginIcon />} loading={data.isLoggingIn}>Login</SubmitButton>

            </CardActions>
          </Card>
        </form>
      </Container>
    </Box>
  )
}
export default Login