import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleSelect({
  children,
  options,
  id,
  value,
  disabled,
  ...props
}) {
  return (
    <Autocomplete
      className="mb-3 w-100"
      fullWidth
      size="small"
      value={value}
      multiple
      id={id}
      options={options}
      limitTags={2}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={children} />}
      {...props}
    />
  );
}
