import React from 'react'
import { Container, Box } from "@mui/material"

export default function MainContainer({ children, ...props }) {
  return (
    <Box component="main" className='mb-10'>
      <Container className="mt-5 px-1 flex-wrap" maxWidth="false" sx={{ width: { xs: '100%', sm: '95%', md: '85%', lg: '75%' }, maxWidth: 1440 }} {...props}>
        {children}
      </Container>
    </Box>
  )
}