import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Card, CardContent, CardHeader } from "@mui/material";
import NoteMoreMenu from "./NoteMoreMenu";
import NoteForm from "../../forms/NoteForm";
import AlertDialog from "../../forms/AlertDialog"

const Notes = ({
  userName,
  customerId,
  notes,
  canEdit,
  canDelete,
  ...props
}) => {
  const formattedDate = () =>
    new Date().toLocaleString().replace(/:[0-9]{2} (AM|PM)/, " $1");

  const [editFormOpen, setEditFormOpen] = useState(false);
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);
  const [addedNotes, setAddedNotes] = useState(notes);
  const [showErrorAlertDialog, setShowErrorAlertDialog] = useState(false);

  const handleAddNote = (note, internalid) => {
    // Add a temporary note for display until the next time the page is loaded
    setAddedNotes([
      {
        note,
        notedate: formattedDate(),
        authorname: userName,
        internalid: internalid,
      },
      ...addedNotes,
    ]);
    setEditFormOpen(false);
  };

  const handleDeleteNote = (id) => {
    setAddedNotes(addedNotes.filter((note) => note.internalid !== id));
  };

  const handleStartEditNote = (index) => {
    setEditFormOpen(true);
    addedNotes[index] = { ...addedNotes[index], editing: true };
    setAddedNotes([...addedNotes]);
  };

  const handleFinishEditNote = (note, index) => {
    setEditFormOpen(false);
    addedNotes[index] = { ...addedNotes[index], note: note, editing: false };
    setAddedNotes([...addedNotes]);
  };

  const handleCancelEditNote = (index) => {
    setEditFormOpen(false);
    addedNotes[index] = { ...addedNotes[index], editing: false };
    setAddedNotes([...addedNotes]);
  };

  const handleCancelNewNote = () => {
    setShowNewNoteForm(false);
    setEditFormOpen(false);
  };

  const handleShowNewNoteForm = () => {
    if (editFormOpen) {
      setShowErrorAlertDialog(true);
    } else {
      setEditFormOpen(true);
      setShowNewNoteForm(true);
    }
  };

  return (
    <>
      <Box className="flex items-center justify-center mb-3">
        {showNewNoteForm ? (
          <NoteForm
            noteId={0}
            customerId={customerId}
            cancelNewNote={handleCancelNewNote}
            setShowNewNoteForm={setShowNewNoteForm}
            addNote={handleAddNote}
            createNewNote={true}
          />
        ) : (
          <IconButton
            title="Add note"
            className="bg-purple-200 hover:bg-purple-300"
            onClick={handleShowNewNoteForm}
          >
            <AddCommentOutlinedIcon className="" fontSize="large" />
          </IconButton>
        )}
      </Box>

      {addedNotes.map(
        ({ note, notedate, authorname, internalid, editing }, index) =>
          editing ? (
            <NoteForm
              key={index}
              index={index}
              noteId={internalid}
              customerId={customerId}
              setShowNewNoteForm={setShowNewNoteForm}
              addNote={handleAddNote}
              editNote={handleFinishEditNote}
              cancelEditNote={handleCancelEditNote}
              createNewNote={false}
              noteText={note}
            />
          ) : (
            <Card key={index} className={`relative min-w-min m-1 mb-3 overflow-visible py-1`}>
              <CardHeader
                className="py-1"
                title={authorname ? `${authorname} - ${notedate}` : notedate}
                titleTypographyProps={{ variant: "body2", fontWeight: "bold" }}
                action={
                  (canEdit || canDelete) && (
                    <NoteMoreMenu
                      index={index}
                      id={internalid}
                      canEdit={canEdit}
                      canDelete={canDelete}
                      deleteNote={handleDeleteNote}
                      editNote={handleStartEditNote}
                      editFormOpen={editFormOpen}
                      setShowErrorAlertDialog={setShowErrorAlertDialog}
                    />
                  )
                }
              />
              <CardContent className="py-1">{note}</CardContent>
            </Card>
          )
      )}
      {showErrorAlertDialog && (
        <AlertDialog
          header="Finish up first!"
          body="Save or cancel the note you are currently editing in order to make changes to any other notes"
          open={showErrorAlertDialog}
          setShowAlertDialog={setShowErrorAlertDialog}
          disagreeText="Close"
          showAgree={false}
        />
      )}
    </>
  );
};

export default Notes;
