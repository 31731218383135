import React, { useState } from 'react'
import { Box, Card as MuiCard, CardContent, CardActions, CardHeader, Collapse } from "@mui/material"
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


const Card = ({ children, ...props }) => {

  const [expanded, setExpanded] = useState(true)
  const handleExpandedToggle = () => setExpanded(!expanded)

  return (
    <Box className='py-4'>
      <MuiCard>
        <CardHeader
          className={`py-2 ${props.color} cursor-pointer`}
          title={props.title || props.header}
          onClick={handleExpandedToggle}
          action={
            <ExpandMore expand={expanded}
              onClick={handleExpandedToggle}
              aria-expanded={expanded}
              aria-label="show more"
              tabIndex={-1}
            >
              <ExpandMoreIcon />
            </ExpandMore>
          }>
        </CardHeader>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className='flex flex-wrap pb-2'>
            {props.content || children}
          </CardContent>
          {props.actions && <CardActions>{props.actions}</CardActions>}
        </Collapse>
      </MuiCard>
    </Box>

  )
}

export default Card