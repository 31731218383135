import { useEffect, useState, } from 'react'
import useFetch from './useFetch'


export function getText(obj, field, withFallback = true) {
  //const fallback = <em className="text-gray-300">- None -</em>
  try {
    if (typeof obj[field] === 'string' && obj[field]) return obj[field]
    else if (typeof obj[field] === 'object') return obj[field][0]['text']
    return "";
  } catch (e) {
    return "";
  }
}

export function getValue(obj, field) {
  try {
    if (typeof obj[field] === 'string') {
      if (['T', 'F'].includes(obj[field])) return obj[field] === 'T'
      return obj[field]
    }
    else if (typeof obj[field] === 'boolean') return obj[field]
    else if (typeof obj[field] === 'object') return obj[field][0]['value']
    return "";
  } catch (e) {
    return null
  }
}


function useSalesOrder(salesOrderId) {

  const [salesOrder, setSalesOrder] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [packages, setPackages] = useState([])

  const { isLoading, error, data } = useFetch({url: `${process.env.REACT_APP_BASE_URL}/api/salesorders/${salesOrderId}`, options: { method: 'GET' } })

  useEffect(() => {

    if (data) {
      const {
        salesOrder: { data: [salesOrderDetails] = [] } = {},
        lineItems: { data: lineItemsDetails = [] } = {},
        packages: { data: packagesDetails = [] } = {},
      } = data

      setSalesOrder(salesOrderDetails)
      setLineItems(lineItemsDetails)  
      setPackages(packagesDetails)
    }
  }, [data])

  return { isLoading, error, salesOrder, lineItems, packages }
}

export default useSalesOrder