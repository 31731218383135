import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

export default function ComboBox({
  children,
  options,
  value,
  textFieldProps,
  helperText,
  ...props
}) {
  return (
    <Autocomplete
      className="mb-3"
      fullWidth
      size="small"
      openOnFocus
      autoHighlight
      autoSelect
      disableClearable
      getOptionLabel={(opt) => opt.name || ""}
      options={options}
      isOptionEqualToValue={(option, newValue) => +option.id === +newValue.id}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          label={children}
          helperText={helperText}
        />
      )}
      value={options.find(({ id } = {}) => +id === +value) || null}
      {...props}
    />
  );
}
