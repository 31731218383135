import React from 'react'
import { Card, CardContent, CardHeader } from "@mui/material"

const DataCard = props => {

  return (
    <Card className={`relative w-full sm:w-auto min-w-min m-1 ${props.className}`}>
      {props.header && <CardHeader className="py-1" title={props.header} titleTypographyProps={{ variant: 'body2', fontWeight: 'bold' }} />}
      <CardContent className='py-1'>
        {props.children}
      </CardContent>
    </Card>
  )
}

export default DataCard