import { useEffect, useState } from "react"
/**
 * 
 * @param {src} Required: The value for the src attribute for the script tag
 * @param {scriptId} The value for the id attribute for the script tag (default: 'dynamicallyLoadedScript')
 * @param {isAsync} The value for the async attribute for the script tag (default: true)
 * @param {appendToElem} An element to which the script will be appeneded as a child (default: document.head)
 * @param {globalVar} If the script sets a global variable (on window) then wait for it (default: '')
 * @param {cleanupFunc} Additional cleanup logic (if necessary)
 * @returns {loaded} A boolean flag indicating if the script has finished loading or not
 */
const useScript = ({ src, scriptId = 'dynamicallyLoadedScript', isAsync = true, appendToElem = document.head, globalVar = '', cleanupFunc = () => { } }) => {

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {

    const markScriptLoaded = () => {
      setLoaded(true)
    }

    const loadScript = () => {
      const script = document.createElement('script');
      script.async = isAsync
      script.id = scriptId
      script.src = src
      script.addEventListener('load', markScriptLoaded)
      appendToElem.appendChild(script);
    }

    const script = document.getElementById(scriptId)

    if (!script) {
      // If the script tag isn't present, then add it to the DOM
      loadScript()
    } else if (globalVar && !window[globalVar]) {
      // If the global variable you're waiting for isn't available
      // then add an event listener to wait for the sript to fully load
      script.addEventListener('load', markScriptLoaded)

    } else {
      // Otherwise, the script is already loaded
      // No need to load it again
      markScriptLoaded()
    }

    return () => script && script.removeEventListener('load', markScriptLoaded)

    // The dependency array is required to remove the
    // setLoaded dependency (to prevent an infinite loop)
  }, [src, scriptId, isAsync, appendToElem, globalVar, cleanupFunc])

  return { loaded }
}

export default useScript