import * as React from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardMedia } from "@mui/material";

const LinkCard = ({
  url,
  title,
  body,
  img,
  imgAlt,
  titleProps,
  bodyProps,
  ...props
}) => {
  const navigate = useNavigate();
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea className="p-2" onClick={() => navigate(url)}>
        {img && (
          <CardMedia component="img" className="h-140" image={img} alt={imgAlt} />
        )}
        <Typography {...titleProps}>{title}</Typography>
        <Typography {...bodyProps}>{body}</Typography>
        <CardContent></CardContent>
      </CardActionArea>
    </Card>
  );
};
export default LinkCard;
