import React from "react";
import { Toolbar, Typography } from "@mui/material";

const Header = ({ children, ...props }) => {
  return (
    <Toolbar
      {...props}
      className={`bg-gray-200 sticky top-0 z-20 ${props.className}`}
    >
      {props.header ? (
        <Typography variant="h6" className="font-bold text-center">
          {props.header}
        </Typography>
      ) : (
        ''
      )}
      {children}
    </Toolbar>
  );
};

export default Header;
