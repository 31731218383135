import React, { useState } from "react";
import { useLocation } from 'react-router-dom'

import SubmitButton from "../../forms/SubmitButton";
import EmailInput from "../../forms/EmailInput";
import InternalLink from "../../routing/InternalLink";

import LionEnergyLogo from "../../LionEnergyLogo";

import SendIcon from "@mui/icons-material/Send";

import toast from "react-hot-toast";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Container,
} from "@mui/material";

const ForgotPassword = (props) => {
  const location = useLocation()
  const {email} = location || ''
  const [data, setData] = useState({ email: email || '', isSendingEmail: false, error: null, message: "" });

  const handleChange = (prop) => (event) => {
    setData({ ...data, [prop]: event.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setData({ ...data, error: null, isSendingEmail: true, message: "" })

    if (!data.email) {
      setData({ ...data, error: { name: "NO_EMAIL", message: "Please enter your email address" }});
      return;
    }

    try {
      const emailResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/users/forgotpassword`, {
        method: "POST",
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.email,
        }),
      });
      if (emailResponse.ok){
        const json = await emailResponse.json();
        setData({ ...data, message: json, error: null });
        toast.success("Email sent! Check your inbox.");
      }
      else if (emailResponse.status === 401) {
        let json
        try {
          json = await emailResponse.json()
        } catch (e) {
          throw emailResponse
        }
        if (json.error) throw json.error
      } else {
        throw emailResponse
      }
    } catch (e) {
      console.error(e);
      setData({ ...data, error: e, message: "" });
    }
    
  };

  window.document.title = "Forgot Password? | Suite Sales"

  return (
    <Box className="login flex items-center h-screen bg-gradient-to-b from-black to-gray-700">
      <Container maxWidth="xs">
        <form id="login_form" onSubmit={handleSubmit}>
          <Card style={{ minWidth: "350px" }}>
            <CardContent className="text-center">
              <LionEnergyLogo />
              <Typography variant="h6" className="my-5">
                Forgot your password?
              </Typography>
              <Typography className="my-5">
                Enter your email below to receive a password reset link.{" "}
                <span role="img" aria-label="thumbs up">
                  &#x1F44D;
                </span>
              </Typography>
              <EmailInput
                autoFocus
                value={data.email}
                onChange={handleChange("email")}
              />
              {data.error ? <Typography variant="body2" color="error">{data.error?.message || data.error?.statusText}</Typography> : ''}
              {data.message && (
                <Typography variant="body2" className="text-green-700">
                  {data.message}
                </Typography>
              )}
            </CardContent>
            <CardActions className="flex-row-reverse justify-between">
              <SubmitButton endIcon={<SendIcon />} loadingPosition="end" loading={data.isSendingEmail}>
                Send password reset email
              </SubmitButton>
              <InternalLink to={{ pathname: '/login', email: data.email }} email={data.email}>
                <Typography variant="body2">Sign in</Typography>
              </InternalLink>
            </CardActions>
          </Card>
        </form>
      </Container>
    </Box>
  );
};
export default ForgotPassword;
