import React, { useContext, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import useSalesOrder from "../../../hooks/useSalesOrder";
import { getText, getValue } from "../../../hooks/useSalesOrder";
import EclipseSpinner from "../../EclipseSpinner";
import ErrorMessage from "../ErrorMessage";
import DataCard from "../../layout/DataCard";
import Header from "../../layout/Header";
import Card from "../../layout/Card";
import MainContainer from "../../layout/MainContainer";
import NotFound404 from "../NotFound404";

import { Box, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";

import { DataGridPro } from '@mui/x-data-grid-pro';
import SubmitButton from "../../forms/SubmitButton";
import { AuthContext } from "../../../services/Authentication";
import AlertDialog from "../../forms/AlertDialog";
import usePermissions from "../../../hooks/usePermissions";
import { LicenseInfo } from '@mui/x-license-pro';
import WarningBanner from "../../forms/WarningBanner";
import PDF from "../../forms/PDF";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdPrice = {
  type: "number",
  valueFormatter: ({ value }) => value ? currencyFormatter.format(Number(value)) : '',
};

const lineItemCols = [
  { field: "id", headerName: "ID", hide: true },
  { renderCell, field: "item", headerName: "SKU", flex: 1.25 },
  { renderCell, field: "description", headerName: "Description", flex: 3 },
  {
    field: "quantity",
    headerName: "Ordered",
    description: "Quantity ordered",
    flex: 1,
    type: "number"
  },
  {
    field: "quantityshiprecv",
    headerName: "Shipped",
    description: "Quantity shipped",
    flex: 1,
    type: "number"
  },
  {
    field: "rate",
    headerName: "Rate",
    description: "Rate",
    flex: 1,
    ...usdPrice
  },
  {
    field: "amount",
    headerName: "Amount",
    description: "Amount",
    flex: 1,
    ...usdPrice,
  },
];

const packagesCols = [
  { field: "id", headerName: "ID", hide: true },
  { renderCell, field: "service", headerName: "Service", flex: 1 },
  { renderCell, field: "tracking", headerName: "Tracking", flex: 1.5 },
  {
    field: "contents",
    headerName: "Contents",
    flex: 3,
    renderCell: (params) => {
      // contents is formatted as {quantity}<qtybr>{sku}<descbr>{description}<linebr>...repeat
      const lines = params?.row?.contents
        .split("<linebr>")
        .map((line) =>
          line.split("<qtybr>").map((item) => item.split("<descbr>"))
        );
      // Check that the first sku in the list isn't null
      const [[[firstSku] = []] = []] = lines;
      const contents = lines
        .map(
          ([qty, [sku, desc] = []]) =>
            `${qty}x ${sku}${desc ? " - " + desc : ""}`
        )
        .join(", ");
      return firstSku ? (
        <Tooltip title={contents}>
          <span>{contents}</span>
        </Tooltip>
      ) : (
        ""
      );
    },
  },
];

  const BooleanIndicator = ({ value }) => (
    <Typography color={value ? 'green' : 'red'}>
      {value ? "Yes" : "No"}
    </Typography>
  );

const SalesOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showWarningBanner, setShowWarningBanner] = useState(false);
  const { state: authState } = useContext(AuthContext);
  const permissions = usePermissions();

  const { isLoading, error, salesOrder, lineItems, packages } =
    useSalesOrder(id);

    const confirmCancel = () => {
      setShowAlertDialog(true);
    };
  
    const handleCancel = async (e) => {
      setIsDeleting(true);
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/salesorders/${id}`, {
          method: "DELETE",
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            salesOrder,
            lineItems,
          }),
        });
        if (res.ok) {
          console.log("RES", res);
          toast.success("Sales Order Canceled");
          setIsDeleting(false);
          location.reload();
        } else {
          toast.error("Sales Order Failed to Cancel");
          setIsDeleting(false);
        };
      } catch (e) {
        setIsDeleting(false);
        console.error(e);
      }
    };


  // Permissions for displaying buttons
  let canEdit;
  let canDelete;
  let canViewCustomers;
  let canViewInvoices;

  if (salesOrder) {
    let isSalesOrderSalesRep = false;
    let isSalesOrderPartner = false;
    let itemsNotPicked = true;

    // If any items have been picked, user can't cancel or delete order
    if (lineItems) {
      for (let i = 0; i < lineItems.length; i++) {
        if (lineItems[i].quantitypicked !== "0") {
          itemsNotPicked = false;
          break;
        }
      }
    }


    const salesRepValue = getValue(salesOrder, "salesrep");
    const partnerValue = getValue(salesOrder, "partner");

    if (Array.isArray(salesRepValue)) {
      isSalesOrderSalesRep = salesRepValue.map((a) => a.value).includes(authState.user.netsuite_id);
    }

    if (Array.isArray(partnerValue)) {
      isSalesOrderPartner = partnerValue.map((a) => a.value).includes(authState.user.netsuite_id);
    }
    
    canEdit = itemsNotPicked &&
      permissions.canEditAllSalesOrders ||
      ((isSalesOrderSalesRep || isSalesOrderPartner) &&
        permissions.canEditOwnSalesOrders);

    canDelete = itemsNotPicked &&
      permissions.canDeleteAllSalesOrders ||
      ((isSalesOrderSalesRep || isSalesOrderPartner) &&
        permissions.canDeleteOwnSalesOrders);

    canViewCustomers =
      permissions.canViewAllCustomers ||
      ((isSalesOrderSalesRep || isSalesOrderPartner) &&
        permissions.canViewOwnCustomers);
  
    canViewInvoices =
      permissions.canViewAllInvoices ||
      ((getValue(salesOrder, "salesrep") === authState.user.netsuite_id ||
        getValue(salesOrder, "partner") === authState.user.netsuite_id) &&
        permissions.canViewOwnInvoices);
  }


  window.document.title = isLoading
    ? "Suite Sales"
    : `${getText(salesOrder, "tranid")} | Suite Sales`;

  if (error) return <ErrorMessage error={error} />;
  if (isLoading) return <EclipseSpinner />;
  if (!salesOrder) return <NotFound404 />;

  return (
    <>
      <Header className="flex-col justify-center  w-full lg:flex-row pt-2 pb-2">
        <Box className="flex-1 mx-1 min-w-max	">
          {canViewCustomers && (
            <SubmitButton
              onClick={() => navigate(`/customers/${getValue(salesOrder, "entity")}`)}
              className="mr-2"
            >
              <span>View Customer</span>
            </SubmitButton>
          )}
          
          {canViewInvoices && (
            <SubmitButton
              onClick={() => {
                navigate(
                  `/salesorders/${getValue(salesOrder, "internalid")}/invoices`
                );
              }}
            >
              <span>Invoices</span>
            </SubmitButton>
          )}
        </Box>
        <Typography variant="h6" className="font-bold text-center ">
          {`Sales Order: ${getText(salesOrder, "tranid")} `}
          <PDF recordId={id} entityId={getValue(salesOrder, "entity")} recordType="salesorder" />
        </Typography>
        <Box className="flex-1 mx-1 min-w-max flex flex-row flex-nowrap justify-end">
            {(canDelete && getText(salesOrder, "status") === "Pending Fulfillment")  && (
              <SubmitButton
                className="mr-2"
                loadingPosition="end"
                endIcon={<CancelIcon />}
                color="error"
                loading={isDeleting}
                onClick={confirmCancel}
              >
                Cancel Order
              </SubmitButton>
            )}
            {(canEdit && getText(salesOrder, "status") === "Pending Fulfillment") && (
              <SubmitButton
                loadingPosition="end"
                endIcon={<EditIcon />}
                onClick={() => navigate(`/salesorders/${id}/edit`)}
              >
                Edit
              </SubmitButton>
            )}
          </Box>
      </Header>
      
      {salesOrder.custbody_on_credit_hold && (<WarningBanner 
        severity="warning"
        title="Credit Hold"
        message="This order is being held pending customer payment."
      />)}

      {getText(salesOrder, "status") === "Closed" && <WarningBanner 
        severity="warning"
        title="Canceled Order"
        message="This order is canceled. Contact someone with NetSuite access if you want it to be opened."
      />}

      <MainContainer>
        <Box>
          <Card header={"Primary Info"} color="bg-blue-300">
            <DataCard header="Customer">
              {getText(salesOrder, "entity")}
            </DataCard>
            <DataCard header="Order Date">
              {getText(salesOrder, "trandate")}
            </DataCard>
            <DataCard header="Order Status">
              {getText(salesOrder, "status")}
            </DataCard>
            <DataCard header="Location">
              {getText(salesOrder, "location")}
            </DataCard>
            <DataCard header="PO #">
              {getText(salesOrder, "otherrefnum")}
            </DataCard>
            <DataCard header="Inside Sales Rep">
              {getText(salesOrder, "salesrep")}
            </DataCard>
            <DataCard header="Costco Sales Rep">
              {getText(salesOrder, "custbody_costco_sales_rep")}
            </DataCard>
            <DataCard header="Outside Sales Rep">
              {getText(salesOrder, "partner")}
            </DataCard>
            <DataCard header="Replacement Order?">
              {salesOrder?.custbody_is_replacement_order ? "Yes" : "No"}
            </DataCard>
            <DataCard header="Lion Will Call Order?">
              {salesOrder?.custbody_is_will_call_order ? "Yes" : "No"}
            </DataCard>
            <DataCard header="Santuary Will Call Order?">
              {salesOrder?.custbody_sanctuary_will_call ? "Yes" : "No"}
            </DataCard>
            <DataCard header="High Priority?">
              {salesOrder?.custbody_is_high_priority ? "Yes" : "No"}
            </DataCard>
          </Card>
        </Box>

        <Box>
          <Card header={"Shipping"} color="bg-yellow-100">
            { (getText(salesOrder, "actualshipdate")) && 
              <DataCard header="Actual Ship Date">
                {getText(salesOrder, "actualshipdate")}
              </DataCard>
            }
            <DataCard header="Ship Date">
              {getText(salesOrder, "shipdate")}
            </DataCard>
            <DataCard header="Ship Method">
              {getText(salesOrder, "shipmethod")}
            </DataCard>
            <DataCard header="Phone">
              {getText(salesOrder, "shipphone")}
            </DataCard>
            <Box className="w-full h-0" /> {/* line break for flexbox */}
            <DataCard header="Shipping Address">
              <Typography className="whitespace-pre-wrap">
                {getText(salesOrder, "shipaddress")}
              </Typography>
            </DataCard>
            <DataCard header="Billing Address">
              <Typography className="whitespace-pre-wrap">
                {getText(salesOrder, "billaddress")}
              </Typography>
            </DataCard>
            <Box className="w-full h-0" /> {/* line break for flexbox */}
            {packages?.length ? (
              <DataCard header={"Packages"} className="flex-1">
                <DataGridPro
                  rows={packages.map((pkg, index) => ({
                    id: index,
                    service: pkg["GROUP(custrecord_packages_service)"],
                    tracking: pkg["GROUP(custrecord_packages_tracking_number)"],
                    contents: pkg["MAX(formulatext)"],
                  }))}
                  columns={packagesCols}
                  density="compact"
                  autoHeight={true}
                  disableSelectionOnClick={true}
                  hideFooter={true}
                  minWidth={600}
                />
              </DataCard>
            ) : (
              ""
            )}
          </Card>
        </Box>

        <Box>
          <Card header={"Financial"} color="bg-green-200">
            <DataCard header="Class">{getText(salesOrder, "class")}</DataCard>
            <DataCard header="Terms">{getText(salesOrder, "terms")}</DataCard>
          </Card>
        </Box>

        <Box>
          <Card header={"Line Items"} color="bg-red-300">
            {
              <DataGridPro
                rows={[
                  // Start with the normal line items
                  ...lineItems.map(({ amount = "0.00", ...line }, index) => ({
                    ...line,
                    id: index,
                    description: getText(line, "memo"),
                    item: getText(line, "item"),
                    amount,
                  })),
                  // Add footer rows for order totals
                  {
                    id: "discount",
                    description: "Discount",
                    amount: getValue(salesOrder, "discountamount"),
                  },
                  {
                    id: "shipping",
                    description: "Shipping",
                    amount: getText(salesOrder, "shippingcost"),
                  },
                  {
                    id: "total",
                    description: "Total",
                    amount: getText(salesOrder, "amount"),
                  },
                ]}
                disableSelectionOnClick={true}
                columns={lineItemCols}
                autoHeight={true}
                hideFooter={true}
                density="compact"
              />
            }
          </Card>
        </Box>
      </MainContainer>
      {showAlertDialog && (
        <AlertDialog
          header="Cancel Sales Order?"
          body="Are you sure you want to cancel this Sales Order? 
            This action can only be undone by someone with NetSuite access."
          open={showAlertDialog}
          setShowAlertDialog={setShowAlertDialog}
          agreeFunction={handleCancel}
          disagreeText="No"
          agreeText="Cancel Order"
        />
      )}
    </>
  );
};

export default SalesOrderDetails;
