import { Card, CardContent, List, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ListLink from "../routing/ListLink";
import React, {useContext} from "react";
import MainContainer from "../layout/MainContainer";
import OverdueBalance from "../Reports/OverdueBalance";
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssessmentIcon from '@mui/icons-material/Assessment';
import usePermissions from "../../hooks/usePermissions"
import { AuthContext } from "../../services/Authentication";

const Dashboard = (props) => {
  const { state: authState } = useContext(AuthContext);
  const permissions = usePermissions()

  const today = new Date().toLocaleDateString()

  const handleOpenPdf = () => {
    window.open('https://5518720.app.netsuite.com/core/media/media.nl?id=11987528&c=5518720&h=-YziUwgqWFbbxSODN_TjdCkf3RQgQOLH9iwoscXy21xuGFv6&_xt=.pdf', '_blank');
  };

  // If a user is a salesrep and can see all customers, only show them their own customers on initial load
  const getCustomersURL = () => {
    let params = {}
    if (permissions.canViewAllCustomers && !permissions.isAdmin) {
      if (authState.user.user_type === "PARTNER") {
        params.partner = authState.user.netsuite_id
      } else {
        params.salesrep = authState.user.netsuite_id
      }
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/customers${urlParams}`;
  }

  // If a user can view all sales orders or all invoices, put date parameters in the url on initial load so it doesn't take as long
  const getSalesOrdersURL = () => {
    let params = {};
    if(permissions.canViewAllSalesOrders) {
      params.startdate = today
      params.enddate = today
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/salesorders${urlParams}`;
  }

  const getInvoicesURL = () => {
    let params = {};
    if(permissions.canViewAllInvoices) {
      params.startdate = today
      params.enddate = today
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/invoices${urlParams}`;
  }

  window.document.title = "Home | Suite Sales"

  return (
    <MainContainer maxWidth="sm">
      <Card className="m-4">
        <CardContent>
          <Typography variant="h5">Welcome!</Typography>
          <List>
            {permissions.canViewAnyCustomers &&
              <ListLink
                to={getCustomersURL()}
                startIcon={<GroupIcon />}
                endIcon={<ArrowRightIcon />}
              >
                Customers
              </ListLink>
            }
            {permissions.canViewAnySalesOrders &&
              <ListLink
                to={getSalesOrdersURL()}
                startIcon={<AssignmentIcon />}
                endIcon={<ArrowRightIcon />}
              >
                Sales Orders
              </ListLink>
            }
            {permissions.canViewAnyInvoices &&
              <ListLink
                to={getInvoicesURL()}
                startIcon={<ReceiptIcon />}
                endIcon={<ArrowRightIcon />}
              >
                Invoices
              </ListLink>
            }
            { (permissions.canViewAnyInvoices || permissions.Commission) &&
              <ListLink
                to="/reports"
                startIcon={<AssessmentIcon />}
                endIcon={<ArrowRightIcon />}
              >
                Reports
              </ListLink>
            }
          </List>
        </CardContent>
      </Card>
      {permissions.canViewAnyCustomers && 
        <Card className="m-4 min-h-full">
          <CardContent className="h-96">
            <Typography variant="h6">Customers with Overdue Balances</Typography>
            <OverdueBalance />
          </CardContent>
        </Card>
      }


    </MainContainer>
  );
};

export default Dashboard;