import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import HomeIcon from "@mui/icons-material/Home";
import AddIcon from "@mui/icons-material/Add";
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Select,
  Toolbar,
  IconButton,
  Box,
  Typography,
  InputBase,
  AppBar,
  MenuItem,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import BadgeIcon from "@mui/icons-material/Badge";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InternalLink from "../routing/InternalLink";
import { AuthContext } from "../../services/Authentication";
import { Link } from "react-router-dom";
import { entities } from "../constants";
import InputAdornment from "@mui/material/InputAdornment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import usePermissions from "../../hooks/usePermissions";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const { state: { user } = {}, dispatch } = useContext(AuthContext);
  const { state: authState } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [, basepath] =
    String(window.location.pathname).match(/^(\/[A-z]+)\/?(.*)/) || [];
  const [searchEntity, setSearchEntity] = useState(
    basepath === "/salesorders" ? 2 : basepath === "/invoices" ? 3 : 1
  );
  const [search, setSearch] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);
  const today = new Date().toLocaleDateString();
  const permissions = usePermissions();

  // Because the AppBar doesn't rerender when the url changes by default...
  // This can lead to the dropdown not updating when clicking on a link. The following hook solves that problem.
  useEffect(() => {
    const [, newBasepath] =
      String(window.location.pathname).match(/^(\/[A-z]+)\/?(.*)/) || [];
    setSearch("");
    if (newBasepath === "/customers") {
      setSearchEntity(1);
    } else if (newBasepath === "/salesorders") {
      setSearchEntity(2);
    } else if (newBasepath === "/invoices") {
      setSearchEntity(3);
    }
  }, [location.pathname]);

  function updateURL() {
    let url = "";
    let params = {};
    if (searchEntity === 1) {
      url = "/customers/";
      if (search) params.name = search || "";
      // Commenting this out because the customer they are searching for might not be their own and the name filter should narrow it down enough
      // If a user is a salesrep and can see all customers, only show them their own customers on initial load
      // if (permissions.canViewAllCustomers && !permissions.isAdmin) {
      //   if (authState.user.user_type === "PARTNER") {
      //     params.partner = authState.user.netsuite_id;
      //   } else {
      //     params.salesrep = authState.user.netsuite_id;
      //   }
      // }
    } else if (searchEntity === 2) {
      url = "/salesorders/";
      // Commenting this out because if they are searching sales orders, they are probably searching for one before today
      // if (permissions.canViewAllSalesOrders) {
      //   params.startdate = today;
      //   params.enddate = today;
      // }
      if (/^SO[0-9]+$/i.test(search)) {
        if (search) params.numbertext = search || "";
      } else {
        if (search) params.poastext = search || "";
      }
    } else if (searchEntity === 3) {
      url = "/invoices/";
      // Commenting this out because if they are searching invoices, they are probably searching for one before today
      // if (permissions.canViewAllInvoices) {
      //   params.startdate = today;
      //   params.enddate = today;
      // }
      if (/^INV[0-9]+$/i.test(search)) {
        if (search) params.numbertext = search || "";
      } else {
        if (search) params.poastext = search || "";
      }
    }
    const urlParams = new URLSearchParams(params).toString();

    navigate({ pathname: url, search: urlParams });
  }

  // If a user is a salesrep and can see all customers, only show them their own customers on initial load
  const getCustomersURL = () => {
    let params = {};
    if (permissions.canViewAllCustomers && !permissions.isAdmin) {
      if (authState.user.user_type === "PARTNER") {
        params.partner = authState.user.netsuite_id;
      } else {
        params.salesrep = authState.user.netsuite_id;
      }
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/customers${urlParams}`;
  };

  // If a user can view all sales orders or all invoices, put date parameters in the url on initial load so it doesn't take as long
  const getSalesOrdersURL = () => {
    let params = {};
    if (permissions.canViewAllSalesOrders) {
      params.startdate = today;
      params.enddate = today;
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/salesorders${urlParams}`;
  };

  const getInvoicesURL = () => {
    let params = {};
    if (permissions.canViewAllInvoices) {
      params.startdate = today;
      params.enddate = today;
    }
    const urlParams = `/?${new URLSearchParams(params).toString()}`;

    return `/invoices${urlParams}`;
  };

  const handleSearchChange = (e) => {
    const newValue = e.target?.value || "";
    setSearch(newValue);
  };

  const handleEntityChange = (e) => {
    setSearchEntity(e.target.value);
  };

  // Trigger search on enter keypress
  const handleSearchEnter = (e) => e.key === "Enter" && updateURL(e);

  const ExpandableList = ({
    text,
    link,
    icon,
    children,
    showExpandIcon = true,
    ...props
  }) => {
    const [open, setOpen] = useState(false);
    const toggleOpen = (e) => {
      setOpen((prev) => !prev);
      e.stopPropagation();
    };

    return (
      <>
        <ListItemButton className="flex items-center">
          <Box className="flex flex-grow" component={Link} to={link}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </Box>
          {showExpandIcon && (
            <Box>
              {open ? (
                <ExpandLessIcon onClick={toggleOpen} />
              ) : (
                <ExpandMoreIcon onClick={toggleOpen} />
              )}
            </Box>
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="" sx={{ bgcolor: "primary" }}>
        <Toolbar>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <InternalLink
              to="/"
              className="text-white no-underline hover:underline"
            >
              Suite Sales
            </InternalLink>
          </Typography>
          <Search>
            <StyledInputBase
              id="global_search"
              // value={search.replace(/^\?q=/g, '')}
              value={search}
              onChange={(e) => handleSearchChange(e)}
              onKeyUp={handleSearchEnter}
              placeholder="Search…"
              sx={{ width: "100%" }}
              startAdornment={
                <InputAdornment position="start">
                  <Select
                    options={entities}
                    variant="standard"
                    value={searchEntity}
                    name="Entity"
                    disableUnderline={true}
                    onChange={handleEntityChange}
                    sx={{
                      borderRight: 2,
                      borderColor: "primary.main",
                      width: 150,
                      "& .MuiSvgIcon-root": {
                        color: "white",
                      },
                    }}
                    className={`text-inherit text-white pl-2`}
                  >
                    {entities.map((entity) => (
                      <MenuItem value={entity.id} key={entity.id}>
                        {entity.name}
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={updateURL} className="text-white">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "search",
              }}
            />
          </Search>

          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              variant="temporary"
              open={drawerOpen}
              onClose={toggleDrawer}
              ModalProps={{ keepMounted: true }}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer}
              >
                <List>
                  <ListItemButton className="flex flex-grow">
                    <Box
                      className="flex flex-grow"
                      component={Link}
                      to={`/profile/${authState?.user?.id}`}
                    >
                      <ListItemText
                        primary={
                          user.companyname
                            ? `${user?.companyname}`
                            : `${user?.firstname} ${user?.lastname}`
                        }
                        className="text-center"
                      />
                    </Box>
                  </ListItemButton>

                  <Divider />

                  <ListItem button onClick={() => dispatch({ type: "LOGOUT" })}>
                    <ListItemIcon>
                      <LoginIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Logout"} />
                  </ListItem>

                  <Divider />

                  <ListItemButton className="flex flex-grow">
                    <Box className="flex flex-grow" component={Link} to="/">
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </Box>
                  </ListItemButton>
                  {permissions.canViewAnyCustomers && (
                    <ExpandableList
                      icon={<GroupIcon />}
                      text="Customers"
                      link={getCustomersURL()}
                      showExpandIcon={permissions.canCreateAnyCustomers}
                    >
                      <ListItemButton to="/customers/create" component={Link}>
                        <ListItemText primary="Create New" />
                        <AddIcon />
                      </ListItemButton>
                    </ExpandableList>
                  )}
                  {permissions.canViewAnySalesOrders && (
                    <ListItemButton className="flex flex-grow">
                      <Box
                        className="flex flex-grow"
                        component={Link}
                        to={getSalesOrdersURL()}
                      >
                        <ListItemIcon>
                          <AssignmentIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sales Orders" />
                      </Box>
                    </ListItemButton>
                  )}
                  {permissions.canViewAnyInvoices && (
                    <ListItemButton className="flex flex-grow">
                      <Box
                        className="flex flex-grow"
                        component={Link}
                        to={getInvoicesURL()}
                      >
                        <ListItemIcon>
                          <ReceiptIcon />
                        </ListItemIcon>
                        <ListItemText primary="Invoices" />
                      </Box>
                    </ListItemButton>
                  )}
                  <ListItemButton className="flex flex-grow">
                    <Box
                      className="flex flex-grow"
                      component={Link}
                      to="/reports"
                    >
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </Box>
                  </ListItemButton>

                  {permissions.canViewAllUsers && (
                    <ExpandableList
                      icon={<BadgeIcon />}
                      text="Users"
                      link="/users"
                      showExpandIcon={permissions.canCreateAllUsers}
                    >
                      <ListItemButton to="/users/create" component={Link}>
                        <ListItemText primary="Create New" />
                        <AddIcon />
                      </ListItemButton>
                    </ExpandableList>
                  )}

                  {permissions.isAdmin && (
                    <ExpandableList
                      icon={<AssignmentIndIcon />}
                      text="Roles"
                      link="/roles"
                      showExpandIcon={permissions.isAdmin}
                    >
                      <ListItemButton to="/roles/create" component={Link}>
                        <ListItemText primary="Create New" />
                        <AddIcon />
                      </ListItemButton>
                    </ExpandableList>
                  )}
                </List>
              </Box>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
