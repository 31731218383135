import React, { useEffect, useState, } from 'react'
import useFetch from './useFetch'

export function getText(obj, field, withFallback = true) {
  const fallback = <em className="text-gray-300">- None -</em>
  try {
    if (typeof obj[field] === 'string' && obj[field]) return obj[field]
    else if (typeof obj[field] === 'object') return obj[field][0]['text']
    return withFallback ? fallback : ''
  } catch (e) {
    return withFallback ? fallback : ''
  }
}

export function getValue(obj, field) {
  try {
    if (typeof obj[field] === 'string') {
      if (['T', 'F'].includes(obj[field])) return obj[field] === 'T'
      return obj[field]
    }
    else if (typeof obj[field] === 'boolean') return obj[field]
    else if (typeof obj[field] === 'object') return obj[field][0]['value']
  } catch (e) {
    return null
  }
}


function useCustomer(customerId) {

  const [customer, setCustomer] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [notes, setNotes] = useState([])

  const { isLoading, error, data } = useFetch({ url: `${process.env.REACT_APP_BASE_URL}/api/customers/${customerId}`, test: !customerId, options: { method: 'GET' } })

  useEffect(() => {

    if (data) {
      const { customer: { data: [customerDetails] }, contacts: { data: contactDetails }, notes: { data: noteDetails } } = data
      setCustomer(customerDetails)
      setContacts(contactDetails)
      setNotes(noteDetails)
    }
  },
    [data]
  )

  return { isLoading, error, customer, contacts, notes }
}

export default useCustomer