import { createContext } from "react";

export const AuthContext = createContext();

export const initialState = {
  isAuthenticated: !!localStorage.user,
  user: JSON.parse(localStorage.user || null),
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN": {
      localStorage.setItem("user", JSON.stringify(action.payload));
      return {
        ...state,
        isAuthenticated: true,
        user: { ...action.payload },
      };
    }
    case "LOGOUT": {
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return state;
    }
  }
};
