import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import useFetch from "../../../hooks/useFetch";
import SearchIcon from "@mui/icons-material/Search";
import InternalLink from "../../routing/InternalLink";
import FullPageGridContainer from "../../layout/FullPageGridContainer";
import Header from "../../layout/Header";
import { Tooltip } from "@mui/material";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SubmitButton from "../../forms/SubmitButton";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);
const columns = [
  {
    field: "view",
    width: 18,
    resizable: false,
    renderCell: (params) => (
      <InternalLink to={`/roles/${params?.row?.id}`}>
        <SearchIcon />
      </InternalLink>
    ),
    renderHeader: (params) => <SearchIcon className="text-gray-400" />,
  },
  { field: "id", headerName: "ID", hide: true },
  {
    renderCell,
    field: "name",
    headerName: "Name",
    description: "Role name in Suite Sales",
    minWidth: 120,
    flex: 1,
  },
];

const RoleList = (props) => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const { isLoading, error, data: json } = useFetch({ url: `${process.env.REACT_APP_BASE_URL}/api/roles/` });

  useEffect(() => {
    if (json) {
      const { result, data = [] } = json;
      if (result === "success") {
        setRoles(
          data.map(({ name, id }) => ({
            id,
            name,
          }))
        );
      }
    }
  }, [json]);

  window.document.title = "Roles | Suite Sales";

  if (error) return <ErrorMessage error={error} />;

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Header header="Roles" className="justify-between">
        <Box className="float-right">
          <SubmitButton
            endIcon={<AddIcon />}
            loadingPosition="end"
            onClick={() => navigate(`/roles/create`)}
          >
            New Role
          </SubmitButton>
        </Box>
      </Header>
      <FullPageGridContainer>
        <DataGridPro
          rows={roles}
          disableSelectionOnClick={true}
          onRowDoubleClick={(e) => navigate(`/roles/${e.id}`)}
          columns={columns}
          loading={isLoading}
          density="standard"
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </FullPageGridContainer>
    </>
  );
};

export default RoleList;
