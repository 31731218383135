import React from 'react'
import CardMedia from "@mui/material/CardMedia"

const LionEnergyLogo = ({ className, ...props }) =>
  <CardMedia
    className={`h-32 w-32 mx-auto mb-5 ${className}`}
    component="img"
    image="/img/lion-energy_logo_greyscale_410px.png"
    alt="Lion Energy Logo"
    {...props}
  />

export default LionEnergyLogo