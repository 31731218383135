import React from 'react'
import Input from "./Input"
import InputAdornment from "@mui/material/InputAdornment"
import EmailIcon from "@material-ui/icons/Email"

const EmailInput = props => {
  return (
    <Input
      placeholder="Email"
      type="email"
      InputProps={{
        endAdornment: <InputAdornment position="end">
          <EmailIcon />
        </InputAdornment>
      }}
      {...props}
    />
  )
}

export default EmailInput