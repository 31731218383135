import { useContext } from "react";
import { AuthContext } from "../services/Authentication";

const usePermissions = () => {
  const { state: authState } = useContext(AuthContext);
  const isAdmin =
    authState.user.permissions.hasOwnProperty("super_admin") || false;
  const userPermissions = authState.user.permissions;

  let permissions = {};

  permissions.isAdmin = isAdmin;
  
  // VIEWING
  // Sales Orders
  permissions.canViewAnySalesOrders =
    isAdmin ||
    userPermissions.all_sales_orders.view ||
    userPermissions.own_sales_orders.view;
  permissions.canViewAllSalesOrders =
    isAdmin || userPermissions.all_sales_orders.view;
  permissions.canViewOwnSalesOrders =
    isAdmin || userPermissions.own_sales_orders.view;
  //Invoices
  permissions.canViewAnyInvoices =
    isAdmin ||
    userPermissions.all_invoices.view ||
    userPermissions.own_invoices.view;
  permissions.canViewAllInvoices = isAdmin || userPermissions.all_invoices.view;
  permissions.canViewOwnInvoices = isAdmin || userPermissions.own_invoices.view;
  // Customers
  permissions.canViewAnyCustomers =
    isAdmin ||
    userPermissions.all_customers.view ||
    userPermissions.own_customers.view;
  permissions.canViewAllCustomers =
    isAdmin || userPermissions.all_customers.view;
    permissions.canViewOwnCustomers =
    isAdmin || userPermissions.own_customers.view;
  // Users
  permissions.canViewAnyUsers =
    isAdmin || userPermissions.all_users.view || userPermissions.own_user.view;
  permissions.canViewAllUsers = isAdmin || userPermissions.all_users.view;
  permissions.canViewOwnUser = isAdmin || userPermissions.own_user.view;
  // Inventory
  permissions.canViewAnyItems =
  isAdmin || userPermissions.all_items.view || userPermissions.lion_items.view;
  permissions.canViewAllItems = isAdmin || userPermissions.all_items.view;
  permissions.canViewLionItems = isAdmin || userPermissions.lion_items.view;
  // Commission
  permissions.canViewAnyISRCommission = isAdmin || userPermissions.own_isr_commission.view || userPermissions.all_isr_commission.view
  permissions.canViewAnyOSRCommission = isAdmin || userPermissions.own_osr_commission.view || userPermissions.all_osr_commission.view
  permissions.canViewAnyCSRCommission = isAdmin || userPermissions.own_csr_commission.view || userPermissions.all_csr_commission.view
  permissions.canViewAnyCommission = permissions.canViewAnyISRCommission || permissions.canViewAnyOSRCommission || permissions.canViewAnyCSRCommission
  permissions.canViewOwnISRCommission = isAdmin || userPermissions.own_isr_commission.view;
  permissions.canViewOwnOSRCommission = isAdmin || userPermissions.own_osr_commission.view;
  permissions.canViewOwnCSRCommission = isAdmin || userPermissions.own_csr_commission.view;
  permissions.canViewAllISRCommission = isAdmin || userPermissions.all_isr_commission.view;
  permissions.canViewAllOSRCommission = isAdmin || userPermissions.all_osr_commission.view;
  permissions.canViewAllCSRCommission = isAdmin || userPermissions.all_csr_commission.view;

  // EDITING
  // Sales Orders
  permissions.canEditAnySalesOrders =
    isAdmin ||
    userPermissions.all_sales_orders.edit ||
    userPermissions.own_sales_orders.edit;
  permissions.canEditAllSalesOrders =
    isAdmin || userPermissions.all_sales_orders.edit;
  permissions.canEditOwnSalesOrders =
    isAdmin || userPermissions.own_sales_orders.edit;
  // Invoices
  permissions.canEditAnyInvoices =
    isAdmin ||
    userPermissions.all_invoices.edit ||
    userPermissions.own_invoices.edit;
  permissions.canEditAllInvoices = isAdmin || userPermissions.all_invoices.edit;
  permissions.canEditOwnInvoices = isAdmin || userPermissions.own_invoices.edit;
  // Customers
  permissions.canEditAnyCustomers =
    isAdmin ||
    userPermissions.all_customers.edit ||
    userPermissions.own_customers.edit;
  permissions.canEditAllCustomers =
    isAdmin || userPermissions.all_customers.edit;
  permissions.canEditOwnCustomers =
    isAdmin || userPermissions.own_customers.edit;
  // Users
  permissions.canEditAnyUsers =
    isAdmin || userPermissions.all_users.edit || userPermissions.own_user.edit;
  permissions.canEditAllUsers = isAdmin || userPermissions.all_users.edit;
  permissions.canEditOwnUser = isAdmin || userPermissions.own_user.edit;

  // CREATING
  // Sales Orders
  permissions.canCreateAnySalesOrders =
    isAdmin ||
    userPermissions.all_sales_orders.create ||
    userPermissions.own_sales_orders.create;
  permissions.canCreateAllSalesOrders =
    isAdmin || userPermissions.all_sales_orders.create;
  permissions.canCreateOwnSalesOrders =
    isAdmin || userPermissions.own_sales_orders.create;
  // Invoices
  permissions.canCreateAnyInvoices =
    isAdmin ||
    userPermissions.all_invoices.create ||
    userPermissions.own_invoices.create;
  permissions.canCreateAllInvoices =
    isAdmin || userPermissions.all_invoices.create;
  permissions.canCreateOwnInvoices =
    isAdmin || userPermissions.own_invoices.create;
  // Customers
  permissions.canCreateAnyCustomers =
    isAdmin ||
    userPermissions.all_customers.create ||
    userPermissions.own_customers.create;
  permissions.canCreateAllCustomers =
    isAdmin || userPermissions.all_customers.create;
  permissions.canCreateOwnCustomers =
    isAdmin || userPermissions.own_customers.create;
  // Users
  permissions.canCreateAnyUsers =
    isAdmin ||
    userPermissions.all_users.create ||
    userPermissions.own_user.create;
  permissions.canCreateAllUsers = isAdmin || userPermissions.all_users.create;
  permissions.canCreateOwnUser = isAdmin || userPermissions.own_user.create;

  // DELETING
  // Sales Orders
  permissions.canDeleteAnySalesOrders =
    isAdmin ||
    userPermissions.all_sales_orders.delete ||
    userPermissions.own_sales_orders.delete;
  permissions.canDeleteAllSalesOrders =
    isAdmin || userPermissions.all_sales_orders.delete;
  permissions.canDeleteOwnSalesOrders =
    isAdmin || userPermissions.own_sales_orders.delete;
  // Invoices
  permissions.canDeleteAnyInvoices =
    isAdmin ||
    userPermissions.all_invoices.delete ||
    userPermissions.own_invoices.delete;
  permissions.canDeleteAllInvoices =
    isAdmin || userPermissions.all_invoices.delete;
  permissions.canDeleteOwnInvoices =
    isAdmin || userPermissions.own_invoices.delete;
  // Customers
  permissions.canDeleteAnyCustomers =
    isAdmin ||
    userPermissions.all_customers.delete ||
    userPermissions.own_customers.delete;
  permissions.canDeleteAllCustomers =
    isAdmin || userPermissions.all_customers.delete;
  permissions.canDeleteOwnCustomers =
    isAdmin || userPermissions.own_customers.delete;
  // Users
  permissions.canDeleteAnyUsers =
    isAdmin ||
    userPermissions.all_users.delete ||
    userPermissions.own_user.delete;
  permissions.canDeleteAllUsers = isAdmin || userPermissions.all_users.delete;
  permissions.canDeleteOwnUser = isAdmin || userPermissions.own_user.delete;

  return permissions;
};

export default usePermissions;
