import React, {forwardRef} from "react";
import { FormControl, TextField } from "@mui/material";

const Input = forwardRef((props, ref) => {
  return (
    <FormControl className={`mb-2 py-1 w-full ${props.className}`}>
      <TextField
        ref={ref}
        size='small'
        fullWidth
        className={`block `}
        helperText={props.children}
        label={props.placeholder}
        variant='outlined'
        {...props}
      />
    </FormControl>
  )
})

export default Input
