import React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Box from '@mui/material/Box';
import Input from "./Input"

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const DateRange = ({value, setValue, ...props}) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={{ start: 'Start Date', end: 'End Date' }}>
      <DateRangePicker
        clearable
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
        }}
        renderInput={(startProps, endProps) => (
          <>
            <Input {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <Input {...endProps} />
          </>
        )}
      />
    </LocalizationProvider>
  );
}

export default DateRange