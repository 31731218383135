import React from "react";
import { Link } from "@mui/material";
import DataCard from "../layout/DataCard";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const PAYSUITE_URL =
  "https://5518720.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=573&deploy=1&compid=5518720&h=86e7b8b1f512d1728a25&route=createPayment";

const PayInvoice = ({ entityId, recordId }) => {
  // Opens PaySuite and allows a User to put in the information to pay for an Invoice

  return (
    <DataCard header="Pay Invoice">
      {entityId ? (
        <Link
          target="_blank"
          underline="none"
          href={`${PAYSUITE_URL}&entityId=${entityId}&recordId=${recordId}`}
        >
          Pay <OpenInNewIcon fontSize="small" />
        </Link>
      ) : (
        <div>Error - No customer id provided</div>
      )}
    </DataCard>
  );
};

export default PayInvoice;
