import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../ErrorMessage";
import useFetch from "../../../hooks/useFetch";
import { getText } from "../../../hooks/useCustomer";
import InternalLink from "../../routing/InternalLink";
import SearchIcon from "@mui/icons-material/Search";
import FullPageGridContainer from "../../layout/FullPageGridContainer";
import Header from "../../layout/Header";
import { Tooltip, Box } from "@mui/material";
import SalesOrderAdvancedSearch from "./SalesOrderAdvancedSearch";
import usePermissions from "../../../hooks/usePermissions";
import AddIcon from "@mui/icons-material/Add";
import SubmitButton from "../../forms/SubmitButton"; 

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const renderCell = (params) => (
  <Tooltip title={params.value || ""}>
    <span>{params.formattedValue}</span>
  </Tooltip>
);

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const usdPrice = {
  type: "number",
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
};

const CustomFooter = (props) => {
  return (
    <Box
      sx={{ padding: "16px", display: "flex", flexDirection: "row-reverse" }}
    >
      {props.totalAmountString}
    </Box>
  );
};

const SalesOrderList = (props) => {
  const { customerId } = useParams() || {};
  const params = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const navigate = useNavigate();
  const [salesOrders, setSalesOrders] = useState([]);
  const [customer, setCustomer] = useState({});
  const [totalAmountString, setTotalAmountString] = useState("");
  const permissions = usePermissions();

  const columns = [
    {
      field: "view",
      headerName: "View",
      width: 18,
      resizable: false,
      renderCell: (params) => (
        <InternalLink to={`/salesorders/${params?.row?.id}`}>
          <SearchIcon />
        </InternalLink>
      ),
      renderHeader: () => <SearchIcon className="text-gray-400" />,
    },
    { field: "id", headerName: "ID", hide: true },
    { field: "trandate", headerName: "Date", description: "", maxWidth: 98 },
    { field: "tranid", headerName: "SO #", description: "", maxWidth: 100 },
    {
      renderCell,
      field: "otherrefnum",
      headerName: "PO #",
      description: "",
      minWidth: 160,
      flex: 1,
    },
    {
      renderCell,
      field: "customer",
      headerName: "Customer",
      description: "",
      minWidth: 190,
      flex: 1,
    },
    {
      renderCell,
      field: "shipaddressee",
      headerName: "Ship To",
      description: "",
      minWidth: 160,
      flex: 1,
    },
    { field: "status", headerName: "Status", description: "", width: 150 },
    {
      renderCell,
      field: "salesrep",
      headerName: "Inside Sales Rep",
      description: "Inside sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "costcorep",
      headerName: "Costco Sales Rep",
      description: "Costco sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      renderCell,
      field: "partner",
      headerName: "Outside Sales Rep",
      description: "Outside sales rep in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      description: "",
      width: 150,
      ...usdPrice,
    },
  ];

  const netsuiteColumns = [
    "internalid",
    { name: "trandate", sort: "DESC" },
    "otherrefnum",
    "tranid",
    "shipaddressee",
    "status",
    "entity",
    "amount",
    "salesrep",
    "partner",
    "custbody_costco_sales_rep",
  ];

  // Add ISR Supervisor
  if (permissions.canViewAllSalesOrders) {
    columns.push({
      renderCell,
      field: "isr_supervisor",
      headerName: "ISR Supervisor",
      description: "Inside sales rep's supervisor in Netsuite",
      minWidth: 120,
      flex: 1,
      hide: true,
    });
    netsuiteColumns.push("salesrep.supervisor");
  }

  // Set filters for netsuite api
  const netsuiteFilters = [];
  if (params?.salesrep)
    netsuiteFilters.push("AND", ["salesrep", "is", params.salesrep]);
  if (params?.supervisor)
    netsuiteFilters.push("AND", [
      "salesrep.supervisor",
      "is",
      params.supervisor,
    ]);
  if (params.costcorep)
    netsuiteFilters.push("AND", [
      "custbody_costco_sales_rep",
      "is",
      params.costcorep,
    ]);
  if (params?.partner)
    netsuiteFilters.push("AND", ["partner", "is", params.partner]);
  if (params?.customer)
    netsuiteFilters.push("AND", [
      "customer.altname",
      "contains",
      params.customer,
    ]);
  if (params?.shipaddressee)
    netsuiteFilters.push("AND", [
      "shipaddressee",
      "contains",
      params.shipaddressee,
    ]);
  if (params?.status)
    netsuiteFilters.push("AND", ["status", "is", `SalesOrd:${params.status}`]);
  if (params?.shipcountry)
    netsuiteFilters.push("AND", ["shipcountry", "is", params.shipcountry]);
  if (params?.shipstate)
    netsuiteFilters.push("AND", ["shipstate", "is", params.shipstate]);
  if (params?.numbertext)
    netsuiteFilters.push("AND", ["numbertext", "contains", params.numbertext]);
  if (params?.poastext)
    netsuiteFilters.push("AND", ["poastext", "contains", params.poastext]);
  if (customerId) netsuiteFilters.push("AND", ["entity", "is", customerId]);
  if (params?.startdate && params?.enddate)
    netsuiteFilters.push("AND", [
      "trandate",
      "within",
      params.startdate,
      params.enddate,
    ]);

  // Set the url
  let url = `${process.env.REACT_APP_BASE_URL}/api/salesorders/?results=${JSON.stringify(netsuiteColumns)}`;
  if (customerId) {
    url += `&params=${JSON.stringify(
      netsuiteFilters
    )}&customerId=${customerId}`;
  } else if (netsuiteFilters) {
    url += `&params=${JSON.stringify(netsuiteFilters)}`;
  }
  const { error, isLoading, data: json } = useFetch({ url });

  useEffect(() => {
    if (json) {
      console.log(json);
      const {
        customer: customerData = {},
        salesOrders: { result, data } = {},
      } = json;

      if (result === "success") {
        setSalesOrders(
          data.map(
            ({
              internalid: [{ value: id } = {}] = [],
              entity: [{ text: customer } = {}] = [],
              status: [{ text: status } = {}] = [],
              salesrep: [{ text: salesrep } = {}] = [],
              "salesrep.supervisor": [{ text: isr_supervisor } = {}] = [],
              custbody_costco_sales_rep: [{ text: costcorep } = {}] = [],
              partner: [{ text: partner } = {}] = [],
              amount,
              otherrefnum,
              trandate,
              tranid,
              shipaddressee,
            }) => ({
              id,
              customer,
              status,
              amount,
              salesrep,
              isr_supervisor,
              costcorep,
              partner,
              otherrefnum,
              trandate,
              tranid,
              shipaddressee,
            })
          )
        );
      }

      // Sum the amount for the total in the footer
      const totalAmount = data
        .reduce((sum, sale) => {
          return sum + (parseFloat(sale["amount"]) || 0);
        }, 0)
        .toFixed(2);
      let amountString = `Total Amount: $${totalAmount}`;

      setTotalAmountString(amountString);

      if (customerData.result === "success") {
        setCustomer({
          id: getText(customerData.data, "internalid"),
          name: getText(customerData.data, "altname"),
        });
      }
    }
    return () => {};
  }, [json]);

  // Don't put customer name into header until the customer has loaded
  const header =
    customerId && !isLoading
      ? `Sales Orders for ${customer.name}`
      : "Sales Orders";

  window.document.title = `${header} | Suite Sales`;

  if (error) return <ErrorMessage error={error} />;

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer className="flex justify-between">
        <Box>
          <GridToolbarColumnsButton />
          <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
        </Box>
        {!customerId && <SalesOrderAdvancedSearch />}
      </GridToolbarContainer>
    );
  };

  return (
    <>
      <Header header={header} className="justify-between"></Header>
        {/* <Box className="float-right">
          {  (
            <SubmitButton
              endIcon={<AddIcon />}
              loadingPosition="end"
              onClick={() => navigate(`/salesorders/create`)}
            >
              Add
            </SubmitButton>
          )}
        </Box> */}
      
      
      <FullPageGridContainer>
        <DataGridPro
          rows={salesOrders}
          disableSelectionOnClick={true}
          onRowDoubleClick={(e) => navigate(`/salesorders/${e.id}`)}
          columns={columns}
          // autoHeight={true}
          loading={isLoading}
          density="standard"
          disableColumnMenu={true}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          componentsProps={{
            footer: { totalAmountString },
          }}
        />
      </FullPageGridContainer>
    </>
  );
};

export default SalesOrderList;
