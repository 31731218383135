import React, { useState, useRef, useEffect } from "react";
import {
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AlertDialog from "../../forms/AlertDialog";

const MoreMenu = ({
  id,
  canEdit,
  canDelete,
  index,
  editFormOpen,
  setShowErrorAlertDialog,
  editNote,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const confirmDelete = () => {
    if (editFormOpen) {
      setShowErrorAlertDialog(true)
    } else {
      setShowDeleteAlertDialog(true)
    }
  }

  const handleDelete = async (e) => {
      try {
        const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/customers/note/${id}`, {
          method: "DELETE",
          credentials: 'include',
          headers: { "Content-Type": "application/json" },
        });

        if (res.ok) {
          const json = await res.json();
          if (json.result === "success") {
            props.deleteNote(id);
          } else {
            if (json.error) throw json.error;
            else throw json;
          }
        } else throw res;
      } catch (e) {
        console.error(e);
        alert(`${e.name} - ${e.message}`);
      }
    setOpen(false);
  };

  const handleEdit = async (e) => {
    if (editFormOpen) {
      setShowErrorAlertDialog(true);
    } else {
      editNote(index);
      setOpen(false);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="more-button"
        aria-controls={open ? "more-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="left-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "left-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className="p-0"
                  autoFocusItem={open}
                  id="more-menu"
                  aria-labelledby="more-button"
                  onKeyDown={handleListKeyDown}
                >
                  {canEdit && <MenuItem onClick={handleEdit}>Edit</MenuItem>}
                  {canDelete && (
                    <MenuItem onClick={confirmDelete}>Delete</MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {showDeleteAlertDialog && (
        <AlertDialog
          header="Delete note?"
          body="Are you sure you want to delete this note? This action cannot be undone."
          open={showDeleteAlertDialog}
          setShowAlertDialog={setShowDeleteAlertDialog}
          agreeFunction={handleDelete}
          disagreeText="Cancel"
          agreeText="Delete"
          showAgree={true}
        />
      )}
    </>
  );
};

export default MoreMenu;
