import React from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { Link } from 'react-router-dom'

export default function ListLink({ to, startIcon, endIcon, text, children, ...props }) {
  return (
    <ListItem {...props}>
      <ListItemButton to={to} component={Link}>
        {
          startIcon &&
          <ListItemIcon>
            {startIcon}
          </ListItemIcon>
        }
        <ListItemText primary={text || children} />
        {endIcon}
      </ListItemButton>
    </ListItem>
  )
}