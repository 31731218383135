import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import ComboBox from "../../forms/ComboBox";
import useFetch from "../../../hooks/useFetch";
import EclipseSpinner from "../../EclipseSpinner";
import { FormControlLabel, Checkbox } from "@mui/material";
import Permissions from "../../forms/Permissions";

const RolesAndPermissions = ({
  profile,
  setProfile,
  readOnly,
  getInventoryPermissionLevel,
  getOwnCommissionPermissions,
  getAllCommissionPermissions,
  limitedAccess,
  metadata,
  handleSelectChange,
  ...props
}) => {
  const [userRoles, setUserRoles] = useState([]);

  // Get form data (internal sales reps and roles)
  const { isLoadingFormData, data } = useFetch({
    url: `${process.env.REACT_APP_BASE_URL}/api/users/formdata`,
  });
  useEffect(() => {
    if (data) {
      const { result, roles = [] } = data;
      if (result === "success") {
        setUserRoles(roles);
      }
    }
  }, [data]);

  // Update the permission levels any time the role changes or the default button is checked
  useEffect(() => {
    if (profile.default_role_permissions) {
      const rolePermissions =
        userRoles.find((r) => r.id === profile.role_id)?.permissions || {};

      rolePermissions.inventory_permission_level_id =
        getInventoryPermissionLevel(
          rolePermissions.all_items?.permission_level_id || 1,
          rolePermissions.lion_items?.permission_level_id || 1
        );

      rolePermissions.own_commission_permissions = getOwnCommissionPermissions(
        rolePermissions.own_isr_commission?.permission_level_id || 1,
        rolePermissions.own_osr_commission?.permission_level_id || 1,
        rolePermissions.own_csr_commission?.permission_level_id || 1
      );

      rolePermissions.all_commission_permissions = getAllCommissionPermissions(
        rolePermissions.all_isr_commission?.permission_level_id || 1,
        rolePermissions.all_osr_commission?.permission_level_id || 1,
        rolePermissions.all_csr_commission?.permission_level_id || 1
      );

      setProfile((prevProfile) => ({
        ...prevProfile,
        own_customers_permission_level:
          rolePermissions.own_customers?.permission_level_id || 1,
        all_customers_permission_level:
          rolePermissions.all_customers?.permission_level_id || 1,
        own_sales_orders_permission_level:
          rolePermissions.own_sales_orders?.permission_level_id || 1,
        all_sales_orders_permission_level:
          rolePermissions.all_sales_orders?.permission_level_id || 1,
        own_invoices_permission_level:
          rolePermissions.own_invoices?.permission_level_id || 1,
        all_invoices_permission_level:
          rolePermissions.all_invoices?.permission_level_id || 1,
        own_user_permission_level:
          rolePermissions.own_user?.permission_level_id || 1,
        all_users_permission_level:
          rolePermissions.all_users?.permission_level_id || 1,
        inventory_permission_level:
          rolePermissions.inventory_permission_level_id || 1,
        own_commission_permissions:
          rolePermissions.own_commission_permissions || [],
        all_commission_permissions:
          rolePermissions.all_commission_permissions || [],
      }));
    }
  }, [
    profile.default_role_permissions,
    profile.role_id,
    getInventoryPermissionLevel,
    getOwnCommissionPermissions,
    getAllCommissionPermissions,
    setProfile,
    userRoles,
  ]);

  const handleRoleChange = (event, value) => {
    setProfile({
      ...profile,
      role_id: value?.id || "",
      role_name: value?.name || "",
    });
  };

  if (isLoadingFormData) return <EclipseSpinner />;
  return (
    <>
      <Box>
        <ComboBox
          options={userRoles}
          value={profile.role_id}
          name="role"
          onChange={handleRoleChange}
          disabled={limitedAccess}
        >
          Role
        </ComboBox>
        <ComboBox
          options={metadata.salesreps?.data}
          value={profile.manager_id}
          name="manager"
          onChange={handleSelectChange("manager_id")}
          disabled={limitedAccess}
          disableClearable={false}
          helperText={`To function properly, this should always be the same as the ${
            profile.user_type === "PARTNER" ? "Manager" : "Supervisor"
          } field in NetSuite`}
        >
          {profile.user_type === "PARTNER" ? "Manager" : "Supervisor"}
        </ComboBox>
      </Box>

      {profile.role_name !== "Admin" && (
        <>
          <FormControlLabel
            label="Assign default permissions for this role?"
            control={
              <Checkbox
                checked={profile.default_role_permissions}
                name="defaultPermissions"
                onChange={(e) =>
                  setProfile({
                    ...profile,
                    default_role_permissions: e.target.checked,
                  })
                }
                disabled={limitedAccess}
              />
            }
          />

          <Permissions
            userOrRole={profile}
            setUserOrRole={setProfile}
            readOnly={
              limitedAccess || readOnly || profile.default_role_permissions
            }
          />
        </>
      )}
    </>
  );
};

export default RolesAndPermissions;
