import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import { AuthContext } from "../../../services/Authentication";
import Input from "../../forms/Input";
import {
  Box,
  Card,
  CardHeader,
  CardContent,

} from "@mui/material";
import useFetch from "../../../hooks/useFetch";
import EclipseSpinner from "../../EclipseSpinner";

import Header from "../../layout/Header";
import { Typography } from "@mui/material"; 
import SubmitButton from "../../forms/SubmitButton";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";

import toast from "react-hot-toast";
import AlertDialog from "../../forms/AlertDialog";
import usePermissions from "../../../hooks/usePermissions";

import Permissions from "../../forms/Permissions";
import { useCallback } from "react";
import { commissionTypes } from "../../constants";

const RoleForm = ({ createNewRole, ...props }) => {
  // Default id of 0 is used when user is creating a new role rather than editing one
  const { id = 0 } = useParams();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const { dispatch } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errors, setErrors] = useState({});
  const [showErrorAlertDialog, setShowErrorAlertDialog] = useState(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [showDeleteAlertDialog, setShowDeleteAlertDialog] = useState(false);

  // blankRoleState used for creating a new role
  const blankRoleState = useRef({
    name: "",
    own_customers_permission_level: 1,
    all_customers_permission_level: 1,
    own_sales_orders_permission_level: 1,
    all_sales_orders_permission_level: 1,
    own_invoices_permission_level: 1,
    all_invoices_permission_level: 1,
    own_user_permission_level: 1,
    all_users_permission_level: 1,
    inventory_permission_level: 1,
    own_commission_permissions: [],
    all_commission_permissions: []
  });
  const [role, setRole] = useState(blankRoleState.current);

  // Get role (id is 0 if creating a new role)
  const { isLoading, error, data } = useFetch({
    url: `${process.env.REACT_APP_BASE_URL}/api/roles/${id}`,
    test: !id,
  });

  // set role's inventory permission level
  // (all items and lion items permissions are stored separately in the database in case we add more functionality later,
  // but right now inventory is read-only, so they show up in the same dropdown on the page)
  // This is a callback function so that it can be a useEffect dependency
  const getInventoryPermissionLevel = useCallback(
    (allItemsPermissionLevel, lionItemsPermissionLevel) => {
      let inventoryPermissionLevel = 1;
      if (allItemsPermissionLevel >= 2) {
        inventoryPermissionLevel = 3;
      } else if (lionItemsPermissionLevel >= 2) {
        inventoryPermissionLevel = 2;
      }
      return inventoryPermissionLevel;
    },
    []
  );

  // set role's commission permissions for own transactions
  // (The multiple select input requires an array)
  // This is a callback function so that it can be a useEffect dependency
  const getOwnCommissionPermissions = useCallback(
    (ownISRPermissionLevel, ownOSRPermissionLevel, ownCSRPermissionLevel) => {
      let ownCommissionPermissions = [];
      if (ownISRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[0]);
      }
      if (ownOSRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[1]);
      }
      if (ownCSRPermissionLevel >= 2) {
        ownCommissionPermissions.push(commissionTypes[2]);
      }
      return ownCommissionPermissions;
    },
    []
  );

  // set role's commission permissions for all transactions
  // (The multiple select input requires an array)
  // This is a callback function so that it can be a useEffect dependency
  const getAllCommissionPermissions = useCallback(
    ( allISRPermissionLevel, allOSRPermissionLevel, allCSRPermissionLevel) => {
      let allCommissionPermissions = [];
      if (allISRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[0]);
      }
      if (allOSRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[1]);
      }
      if (allCSRPermissionLevel >= 2) {
        allCommissionPermissions.push(commissionTypes[2]);
      }
      return allCommissionPermissions;
    },
    []
  );

  // This sets the fields to blank if the user goes from editing a role straight to creating a new role
  useEffect(() => {
    if (id === 0) {
      setRole(blankRoleState.current);
    }
  }, [id]);

  // If editing a role, set the fields to the role's information
  useEffect(() => {
    if (data) {
      const { guard_name, created_at, updated_at, ...dataFields } = data;

      const inventoryPermissionLevel = getInventoryPermissionLevel(
        data.permissions.all_items?.permission_level_id || 1,
        data.permissions.lion_items?.permission_level_id || 1
      );

      const ownCommissionPermissions = getOwnCommissionPermissions(
        data.permissions.own_isr_commission?.permission_level_id || 1,
        data.permissions.own_osr_commission?.permission_level_id || 1,
        data.permissions.own_csr_commission?.permission_level_id || 1
      )

      const allCommissionPermissions = getAllCommissionPermissions(
        data.permissions.all_isr_commission?.permission_level_id || 1,
        data.permissions.all_osr_commission?.permission_level_id || 1,
        data.permissions.all_csr_commission?.permission_level_id || 1
      )

      setRole({
        ...dataFields,
        own_customers_permission_level:
          data.permissions.own_customers?.permission_level_id || 1,
        all_customers_permission_level:
          data.permissions.all_customers?.permission_level_id || 1,
        own_sales_orders_permission_level:
          data.permissions.own_sales_orders?.permission_level_id || 1,
        all_sales_orders_permission_level:
          data.permissions.all_sales_orders?.permission_level_id || 1,
        own_invoices_permission_level:
          data.permissions.own_invoices?.permission_level_id || 1,
        all_invoices_permission_level:
          data.permissions.all_invoices?.permission_level_id || 1,
        own_user_permission_level:
          data.permissions.own_user?.permission_level_id || 1,
        all_users_permission_level:
          data.permissions.all_users?.permission_level_id || 1,
        inventory_permission_level: inventoryPermissionLevel || 1,
        own_commission_permissions: ownCommissionPermissions || [],
        all_commission_permissions: allCommissionPermissions || []
      });
    }
  }, [data, getInventoryPermissionLevel, getOwnCommissionPermissions, getAllCommissionPermissions]);

  const isAdminRole = role.id === 1;

  // Get metadata
  const safeJSONparse = (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return undefined;
    }
  };

  const metadata = safeJSONparse(localStorage.getItem("metadata"));
  if (!metadata) {
    dispatch({ type: "LOGOUT" });
  }

  // Check if a role already exists with provided name
  const checkUniqueName = async (name) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/roles/name/${name}`, {
        credentials: 'include',
      });
      if (res.ok) {
        const json = await res.json();
        if (json.result === "success") {
          return json.data.unique_name;
        } else {
          if (json.error) throw json.error;
          else throw json;
        }
      } else throw res;
    } catch (e) {
      console.error(e);
    }
  };

  // Check that the name is not blank and is unique
  const validateName = async (name) => {
    let nameError = "";
    if (!name) {
      nameError = "This is a required field";
    } else if (createNewRole) {
      try {
        const uniqueName = await checkUniqueName(name);
        if (!uniqueName) {
          nameError = "A role already exists with this name";
        } else {
          nameError = "";
        }
      } catch (error) {
        console.error(error);
      }
    }
    return nameError;
  };

  const handleOnChangeName = async (e) => {
    const name = e.target.value;
    setRole({ ...role, name: name });
    try {
      const nameError = await validateName(name);
      setErrors({...errors, name: nameError})
    } catch (error) {
      console.error(error);
    }
  };

  const handleSave = async (e) => {
    try {
      const roleCopy = { ...role };

      // FORM VALIDATION
      const requiredFields = [...document.querySelectorAll("[required]")].map(
        (el) => el.getAttribute("name")
      );
      let formErrors = {};

      // Check that required fields are not empty
      for (let field of requiredFields) {
        if (!roleCopy[field]) {
          formErrors[field] = "This is a required field";
        }
      }
      // Check that the name is valid
      const nameError = await validateName(roleCopy.name);
      if (nameError) {
        formErrors.name = nameError;
      }

      // If there are any errors, let the user know and don't save
      setErrors(formErrors);
      if (Object.keys(formErrors).length > 0) {
        const { error } = {
          error: {
            name: "You're not done yet!",
            message: "Please fix any fields with errors",
          },
        };
        throw error;
      }

      setIsSaving(true);

      // Post if creating a new role and put if editing an old one
      let method = "";
      let url = "";
      if (createNewRole) {
        method = "POST";
        url = `${process.env.REACT_APP_BASE_URL}/api/roles/`;
      } else {
        method = "PUT";
        url = `${process.env.REACT_APP_BASE_URL}/api/roles/${id}`;
      }
      const res = await fetch(url, {
        method: method,
        credentials: 'include',
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(roleCopy),
      });

      if (res.ok) {
        if (createNewRole) {
          setIsSaving(false);
          toast.success("Role created");
          navigate("/roles");
        } else {
          setIsSaving(false);
          toast.success("Role updated");
          navigate("/roles");
        }
      } else if (res.status === 400) {
        let json;
        try {
          json = await res.json();
        } catch (e) {
          throw res;
        }
        setIsSaving(false);
        if (json.error) throw json.error;
      } else {
        setIsSaving(false);
        throw res;
      }
    } catch (e) {
      console.error(e);
      setShowErrorAlertDialog(true);
      setIsSaving(false);
    }
  };

  const confirmDelete = () => {
    setShowDeleteConfirmDialog(true);
  };

  const handleDelete = async (e) => {
    setIsDeleting(true);
    try {
      const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/roles/${id}`, { 
        method: "DELETE",
        credentials: 'include',
      });
      if (res.status === 409) {
        setIsDeleting(false)
        setShowDeleteAlertDialog(true)
      } else if (res.ok) {
        toast.success("Role deleted");
        navigate("/roles");
      } else throw res;
    } catch (e) {
      setIsDeleting(false);
      console.error(e);
    }
  };

  // Header for page title
  const header = createNewRole ? "New Role" : `${role.name}`;

  window.document.title = isLoading ? "Suite Sales" : `${header} | Suite Sales`;

  if (!permissions.isAdmin)
    return (
      <MainContainer>You are not authorized to view this page</MainContainer>
    );
  if (error) return <MainContainer>{JSON.stringify(error)}</MainContainer>;
  if (isLoading) return <EclipseSpinner />;
  return (
    <>
      <Header className="justify-between">
        <Typography variant="h6">
          <b>{header}</b>
        </Typography>
        <Box>
          <SubmitButton
            disabled={isSaving}
            className="mr-2 bg-gray-500 hover:bg-gray-600 flex-1"
            endIcon={<CancelIcon />}
            onClick={() => navigate(-1)}
            loadingPosition="end"
          >
            Cancel
          </SubmitButton>
          {/* Prevent users from deleting default roles */}
          {(!isAdminRole && !createNewRole) && (
            <SubmitButton
              className="mr-2"
              loadingPosition="end"
              endIcon={<DeleteIcon />}
              color="error"
              loading={isDeleting}
              onClick={confirmDelete}
            >
              Delete
            </SubmitButton>
          )}
          <SubmitButton
            loadingPosition="end"
            endIcon={<SaveIcon />}
            onClick={handleSave}
            loading={isSaving}
          >
            Save
          </SubmitButton>
        </Box>
      </Header>
      <MainContainer>
        <Box>
          {/* firstname, lastname, email, password, netsuite_id, default: [class, category, terms, price_level]  */}
          <Card className="mb-3">
            <CardHeader title="Role Info" />
            <CardContent>
              <Input
                required
                error={!!errors.name}
                helperText={errors.name
                  ? errors.name : ""}
                name={"name"}
                placeholder={"Name"}
                value={role.name}
                onChange={handleOnChangeName}
                //onBlur={handleOnBlurName}
                disabled={isAdminRole}
              />
            </CardContent>
          </Card>
          <Card className="mb-3">
            <CardHeader title="Permissions" />
            <CardContent>
              <Permissions
                userOrRole={role}
                setUserOrRole={setRole}
                readOnly={role.id === 1}
              />
            </CardContent>
          </Card>
        </Box>
      </MainContainer>

      {/* Alert dialogs for errors, deletion confirmation, and welcome email */}
      {showErrorAlertDialog && (
        <AlertDialog
          header="You're not done yet!"
          body="Please fix any fields with errors before saving."
          open={showErrorAlertDialog}
          setShowAlertDialog={setShowErrorAlertDialog}
          showAgree={false}
          disagreeText="Close"
        />
      )}
      {showDeleteConfirmDialog && (
        <AlertDialog
          header="Delete role?"
          body="Are you sure you want to delete this role?"
          open={showDeleteConfirmDialog}
          setShowAlertDialog={setShowDeleteConfirmDialog}
          agreeFunction={handleDelete}
          disagreeText="Cancel"
          agreeText="Delete"
          showAgree={true}
        />
      )}
      {showDeleteAlertDialog && (
        <AlertDialog
          header="Error"
          body="This role cannot be deleted because there are users assigned to it."
          open={showDeleteAlertDialog}
          setShowAlertDialog={setShowDeleteAlertDialog}
          disagreeText="Close"
          showAgree={false}
        />
      )}
    </>
  );
};

export default RoleForm;
