import React from "react";
import { Link } from "@mui/material";
import PDFIcon from '@mui/icons-material/PictureAsPdf';

const PDF_URL =
"https://5518720.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=586&deploy=1&compid=5518720&h=33a061f929ab4c4e840b";

const PDF = ({ entityId, recordId, recordType }) => {

    if (!entityId) {
        return <div>Error - No customer id provided</div>;
    }

  // Opens A NetSuite PDF
  return (
    <Link
      target="_blank"
      underline="none"
      href={`${PDF_URL}&recordType=${recordType}&recordId=${recordId}&entityId=${entityId}`}
    >
      <PDFIcon style={{ color: 'black' }} /> 
    </Link>
  );
};

export default PDF;
